import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import AgoraRTC from "agora-rtc-sdk-ng"
import { environment } from 'src/environments/environment';
import { SocketService } from 'src/app/services/socketService/socket.service';
import { SubjectService } from 'src/app/services/subject.service';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-other-user-live',
  templateUrl: './other-user-live.component.html',
  styleUrls: ['./other-user-live.component.scss']

})
export class OtherUserLiveComponent implements OnInit {
  showEmojiPicker = false;
  chatList: any = [];
  OtherUserDetail: any;
  imageUrl: string;
  date: any;
  comment: any = '';
  count: number = 0
  isDonationDisable = 0;
  time: any;
  min: any;
  localStream: any;
  public remoteStream: any[] = [];
  userinfo: any;
  constructor(
    private common: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private socket: SocketService,
    private subject: SubjectService
  ) {
    this.imageUrl = environment.imageUrl;
    this.userinfo = JSON.parse(localStorage.getItem('Bottom_up_user') || '{}');
    this.activatedRoute.params.subscribe((res: any) => {

      this.OtherUserDetail = JSON.parse(res.data);
      this.isDonationDisable = parseInt(this.OtherUserDetail.donation_status);
    });

    this.subject.liveComment.subscribe((param) => {
      this.chatList = param;
    });
    this.subject.addComment.subscribe((param) => {
      this.getNewMessage();
    });
  }

  ngOnInit(): void {
    this.startBasicCall();
    this.joinChannel();
    this.socket.getLiveUsersCount.subscribe((count: number) => {
      this.count = count;
    });
  }

  joinChannel() {
    let data1 = {
      type: 'WhenOtherUserJoinChannel',
      user_id: this.userinfo.id,
      liveUserID: this.OtherUserDetail.UserID,
      status: 1,
      serviceType: 'Commenting'
    }
    this.socket.sendMessage(data1);
    this.getNewMessage();
  }
  async startBasicCall() {
    var that = this;
    let rtc: any = {
      // For the local audio and video tracks.
      localAudioTrack: null,
      localVideoTrack: null,
      client: null
    };

    let options = {
      // Pass your app ID here.
      appId: environment.agora.appId,
      // Set the channel name.
      channel: this.OtherUserDetail.ChatRoomName,
      // Set the user role in the channel.
      role: "audience",
      // Use a temp token
      token: this.OtherUserDetail.AgoraToken,
      // Uid
      uid: this.OtherUserDetail.SessionID
    };
    let clientRoleOptions = {
      // Set latency level to low latency
      level: 1
    }
    rtc.client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
    rtc.client.setClientRole(options.role, clientRoleOptions);
    rtc.client.on("user-published", handleUserPublished);
    rtc.client.on("user-joined", handleUserJoined);
    rtc.client.on("user-left", handleUserLeft);
    options.uid = await rtc.client.join(options.appId, options.channel, options.token || null);
    async function subscribe(user: any, mediaType: any) {
      const uid = user.uid;
      // subscribe to a remote user
      await rtc.client.subscribe(user, mediaType);
      if (mediaType === 'video') {
        const player = $(`
          <div id="player-wrapper">
            <div id="player-${uid}" class="player" style='height:100vh'></div>
          </div>
        `);
        $("#agora_local").append(player);
        rtc.localVideoTrack = user.videoTrack.play(`player-${uid}`);
      }
      if (mediaType === 'audio') {
        rtc.localAudioTrack = user.audioTrack.play();
      }
    }
    var remoteUsers: any = [];
    // Handle user published
    function handleUserPublished(user: any, mediaType: any) {
      const id = user.uid;
      remoteUsers[id] = user;
      subscribe(user, mediaType);
    }

    // Handle user joined
    function handleUserJoined(user: any, mediaType: any) {
      const id = user.uid;
      remoteUsers[id] = user;
      subscribe(user, mediaType);
    }

    // Handle user left
    function handleUserLeft(user: any) {
      const id = user.uid;
      delete remoteUsers[id];
      $(`#player-wrapper`).remove();
      if (user.uid == that.OtherUserDetail.UserID) {
        leave();
      }

      async function leave() {
        for (var trackName in rtc.localVideoTrack) {
          var track = rtc.localVideoTrack[trackName];
          if (track) {
            track.stop();
            track.close();
            rtc.localVideoTrack[trackName] = undefined;
          }
        }
        // remove remote users and player views
        remoteUsers = [];
        // leave the channel
        await rtc.client.leave();
        console.log("Client successfully left channel.");
        that.common.success(`Live streaming ended`);
        that.router.navigate(['/home']);
      }
    }
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }
  addEmoji(event: any) {
    if (this.comment !== undefined || this.comment !== '') {
      this.comment = `${this.comment}${event.emoji.native}`;
    }
    else {
      this.comment = event.emoji.native;

    }
    this.showEmojiPicker = false;
  }

  onFocus() {
    this.showEmojiPicker = false;
  }

  onBlur() {
    console.log('onblur')
  }

  getNewMessage() {
    let data = {
      'liveUserID': this.OtherUserDetail.UserID,
      'type': 'commentList',
      'serviceType': 'Commenting'
    }
    this.socket.sendMessage(data);
  }

  addComments() {
    const message = this.comment.trim();
    if (message.length) {
      let data = {
        serviceType: 'Commenting',
        type: 'add_comment',
        user_id: this.userinfo.id,
        liveUserID: this.OtherUserDetail.UserID,
        comment: this.comment.trim()
      }
      this.socket.sendMessage(data);
      this.comment = '';
      setTimeout(() => {
        this.getNewMessage();
      }, 1000)

    }
  }

  async muteAudio() {
    $("#mute-audio").text("Unmute Audio");
  }
  async unmuteAudio() {
  }

  getOtherUser() {
    var id = this.OtherUserDetail.UserID
    this.router.navigate(['/other_user_profile', id])
  }

  shareUrl() {
    navigator.clipboard.writeText(window.location.href)
    this.common.success('Share link copied!');
  }

  checkSinceLive(startTime: any) {
    var startTimessss = moment(new Date(startTime + ' UTC'));
    var now = new Date().getTime();
    var duration = startTimessss.milliseconds() + 1000 * (startTimessss.seconds() + 60 * (startTimessss.minutes() + 60 * startTimessss.hours()));
    if (isNaN(duration)) {
      return "";
    }
    if (duration < now) {
      var milisec_diff = now - duration;
    } else {
      var milisec_diff = duration - now;
    }
    var date_diff = new Date(milisec_diff);
    return (date_diff.getHours() >= 1 ? date_diff.getHours() + " Hours " : '') + (date_diff.getMinutes() >= 1 ? date_diff.getMinutes() + " Minutes " : '') + (date_diff.getSeconds() + " Seconds");
  }
  onKeyDown(event: any) {
    if (event.keyCode === 222 && !event.shiftKey) {
      this.comment = this.comment + "'";
    }
    if (event.keyCode === 222 && event.shiftKey) {
      this.comment = this.comment + '\"';
    }
    console.log(this.comment);
  }
}
