<body>
  <section class="login-block">
    <!-- login start -->
    <!-- left side start -->
    <div class="left-side-bg">
      <img src="assets/images/bottoms-up-text.svg">
    </div> <!-- left side close -->
    <!-- right side start -->
    <div class="right-side-block welcome my-5">
      <div class="welcome-block">
        <h1>Welcome!!</h1>
        <p>We are excited you are part of Bottom Up. We would like to welcome you to our team and extremely happy to have
          you as one of us.</p>
        <img src="assets/images/login-vector.png">
        <a class="btn account-but my-3" routerLink="/login" role="button">Already have an account?</a>
        <div class="or-block">OR</div>
        <a class="btn user-but my-3" routerLink="sign_up" role="button">New User</a>
      </div>
    </div> <!-- right side close -->
  </section>