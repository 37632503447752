<app-header></app-header>
<section class="other-live-chat">
    <div class="container-fluid p-0">
        <div class="row no-gutters">
            <div class="col-md-8">
                <div class="live-user-video position-relative h-100">
                    <div class="video-group" id="agora_local">     
                    </div>
                    <!---top--->
                    <div class="live-top position-absolute d-flex">
                        <a class="sharebtn" (click)=shareUrl()><img src="assets/images/share-icon.svg" alt="image" /></a>
                        <!-- </div> -->
                        <span class="live-status text-white ml-2 f-14">Live</span>
                        <a class="seen ml-2 text-white f-14"><img class="mr-1" src="assets/images/seen-icon.svg" alt="icon" />{{count}}</a>
                    </div>
                    <!---top--->
                    <!---donation--->
                    <div class="donation-user w-100 position-absolute d-flex justify-content-between p-3">
                        <a (click)="getOtherUser()" class="d-user d-flex">
                            <img *ngIf="OtherUserDetail?.profile_image !=''" src="{{imageUrl}}{{OtherUserDetail?.profile_image}}" alt="image" />
                            <img *ngIf="OtherUserDetail?.profile_image ==''" src="assets/images/dummy_user.png" alt="image">
                            <div class="d-name align-self-center ml-3">
                                <p class="mb-0 dark-grey-cl">{{OtherUserDetail?.name}}</p>
                                <small class="light-grey-cl">LIVE since {{checkSinceLive(OtherUserDetail?.DateTimeString)}}</small>
                            </div>
                        </a>
                        <div class="d-user-btn align-self-center">
                            <ng-container *ngIf="isDonationDisable == 1">
                                <a routerLink="/donate" class="small-btn primary-blue-bg d-inline-block btn text-white f-14">
                                    <img class="mr-2" src="assets/images/donate-pointer.svg" alt="icon" />Donate
                                </a>
                            </ng-container>
                        </div>
                    </div>
                    <!---donation--->
                </div>
            </div>
            <div class="col-md-4">
                <div class="live-chat-sec">
                    <!---head--->
                    <div class="chat-header d-user d-flex p-3 light-grey-bg mb-2">
                        <!-- <img src="assets/images/d-user.svg" alt="image">   -->
                        <img *ngIf="OtherUserDetail?.profile_image !=''" src="{{imageUrl}}{{OtherUserDetail?.profile_image}}" alt="image" />
                        <img *ngIf="OtherUserDetail?.profile_image ==''" src="assets/images/dummy_user.png" alt="image">
                        <div class="d-name align-self-center ml-3">
                            <p class="mb-0 dark-grey-cl">{{OtherUserDetail.name}}</p>
                            <!-- <small class="light-grey-cl">LIVE since {{min}} </small> -->
                        </div>
                    </div>
                    <!---head--->
                    <!---chat-body--->
                    <small class="px-3 primary-blue-cl">Live Chat</small>
                    <div class="chat-body">
                        <div *ngFor="let chat of chatList" class="chat-user-box d-flex p-2">
                            <img class="align-self-start" *ngIf="chat.profile_image != ''" src="{{imageUrl}}{{chat.profile_image}}" alt="icon" />
                            <img class="align-self-start" *ngIf="chat.profile_image === ''" src="assets/images/dummy_user.png" alt="icon" />
                            <div class="chat-user-info ml-3">
                                <h5 class="mb-2 dark-grey-cl f-16">{{chat.name}}</h5>
                                <p class="mb-0 light-grey-cl f-14">{{chat.comment}}</p>
                            </div>
                        </div>
                    </div>
                    <!---chat-body--->
                    <div class="msg-box p-3">
                        <div class="msg-input position-relative">
                            <form #data="ngForm">
                                <textarea type="text" name="comment" (keydown)="onKeyDown($event)" [(ngModel)]="comment" (keyup.enter)="addComments()"   class="search-input form-control" placeholder="Type Comment..."></textarea>
                            </form>
                            <a (click)="toggleEmojiPicker()"><img class="smile-icon position-absolute" src="assets/images/smile-icon.svg" alt="icon" /></a>
                            <a (click)="addComments()"><img class="sent-btn position-absolute" src="assets/images/send-btn-icon.svg" alt="icon" /></a>
                            <emoji-mart class="emoji-mart" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)" title="Pick your emoji…"></emoji-mart>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!---Main section--->
<!---share link-->
<div class="modal fade" id="shareUrl">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title light-grey-cl">share Details</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <!-- <div class="modal-body my-3">
                <div class="d-flex justify-content-start">
                  </div>
            </div> -->
        </div>
    </div>
</div>
<!--suggested-user-modal-->
<div class="modal fade" id="suggested">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title light-grey-cl">Recommend Friends</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body my-3">
                <div class="sugg-user-box d-flex justify-content-between mb-3">
                    <div class="d-flex">
                        <div class="sugg-user-img position-relative mr-3 align-self-center">
                            <img src="assets/images/sugg-img.svg" alt="image">
                            <div class="online-state position-absolute green-bg"></div>
                        </div>
                        <div class="sugg-info align-self-center">
                            <h5 class="mb-0">Rebecca_Smith</h5>
                            <ul class="list-inline mb-0">
                                <li class="list-inline-item red-cl f-14">
                                    <p class="online-state red-bg d-inline-block mb-0 mr-2"></p>Live
                                </li>
                                <li class="list-inline-item light-grey-cl ml-2 f-14">234 Connected</li>
                            </ul>
                        </div>
                    </div>
                    <a href="" class="align-self-center"><img src="assets/images/cross-sm-icon.svg" alt="icon" /></a>
                </div>
                <div class="sugg-user-box d-flex justify-content-between mb-3">
                    <div class="d-flex">
                        <div class="sugg-user-img position-relative mr-3 align-self-center">
                            <img src="assets/images/sugg-img.svg" alt="image">
                            <div class="online-state position-absolute green-bg"></div>
                        </div>
                        <div class="sugg-info align-self-center">
                            <h5 class="mb-0">Rebecca_Smith</h5>
                            <ul class="list-inline mb-0">
                                <li class="list-inline-item red-cl f-14">
                                    <p class="online-state red-bg d-inline-block mb-0 mr-2"></p>Live
                                </li>
                                <li class="list-inline-item light-grey-cl ml-2 f-14">234 Connected</li>
                            </ul>
                        </div>
                    </div>
                    <a href="" class="align-self-center"><img src="assets/images/cross-sm-icon.svg" alt="icon" /></a>
                </div>
                <div class="sugg-user-box d-flex justify-content-between mb-3">
                    <div class="d-flex">
                        <div class="sugg-user-img position-relative mr-3 align-self-center">
                            <img src="assets/images/sugg-img.svg" alt="image">
                            <div class="online-state position-absolute green-bg"></div>
                        </div>
                        <div class="sugg-info align-self-center">
                            <h5 class="mb-0">Rebecca_Smith</h5>
                            <ul class="list-inline mb-0">
                                <li class="list-inline-item red-cl f-14">
                                    <p class="online-state red-bg d-inline-block mb-0 mr-2"></p>Live
                                </li>
                                <li class="list-inline-item light-grey-cl ml-2 f-14">234 Connected</li>
                            </ul>
                        </div>
                    </div>
                    <a href="" class="align-self-center"><img src="assets/images/cross-sm-icon.svg" alt="icon" /></a>
                </div>
            </div>
        </div>
    </div>
</div>
