import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { SocketService } from 'src/app/services/socketService/socket.service';
@Component({
  selector: 'app-block-users',
  templateUrl: './block-users.component.html',
  styleUrls: ['./block-users.component.scss']
})
export class BlockUsersComponent implements OnInit {
  blockuserList: any;
  imageUrl: string;
  userdetails: any;
  userinfo:any;
  constructor(
    private service: ApiService, 
    private common: CommonService, 
    private router: Router,
    private socket: SocketService
    ) { }

  ngOnInit(): void {
    if (localStorage.getItem('Bottom_up_user')) {
      this.userinfo = JSON.parse(localStorage.getItem('Bottom_up_user') || '{}');
    }
    this.imageUrl = this.service.imageUrl
    this.getBlockusers();
  }
  getBlockusers() {
    this.service.getBlockUsersList('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.blockuserList = res.data
        this.common.hideSpinner()
      }
    })
  }

  unblockUser(details: any) {
    var fd = new FormData();
    fd.append("friendId", details.id)
    this.service.unblockUser(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.success(res.message);
        let data = {
          serviceType: 'NotifyUserWhenBlock', 
          userID:this.userinfo.id, 
          recieverID:details.id
        }
        this.socket.sendMessage(data);
        this.common.hideSpinner();
        this.getBlockusers();
      }
      else {
        this.common.error(res.message);
        this.common.hideSpinner()
      }
    })

  }
  onSelect(data: any) {
    this.userdetails = data

  }
}
