<header class="header-sec">
  <div class="top-header primary-blue-bg">
    <nav class="navbar navbar-dark navbar-expand-lg">
      <a class="navbar-logo">
        <img src="assets/images/header/logo.svg" alt="logo" />
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav custom-nav ml-auto">
          <li class="nav-item custom-link" (click)="redirect('home')">
            <a class="nav-link"><img src="assets/images/header/home.svg" alt="header-icon" />
              <span>Home</span> </a>
          </li>
          <li class="nav-item custom-link" (click)="redirect('search')">
            <a class="nav-link"><img src="assets/images/header/search.svg" alt="header-icon" /><span> Search</span></a>
          </li>
          <li class="nav-item custom-link" (click)="redirect('addFriend')">
            <a class="nav-link"><img src="assets/images/header/add-friend.svg" alt="header-icon" /><span>Add</span> </a>
          </li>
          <li class="nav-item custom-link" (click)="redirect('chat')">
            <a class="nav-link"><img src="assets/images/header/message.svg" alt="header-icon" /><span>
                Message</span></a>
          </li>
          <li class="nav-item dropdown notify-hd-drop position-relative">
            <a class="nav-link dropdown-toggle" (click)="getnotifiaction()" id="navbarDropdown" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img
                src="assets/images/header/notify.svg" alt="header-icon" /> <span> Notifications</span></a>
            <span class="notify-count position-absolute" *ngIf="hasNotification"></span>
            <div class="dropdown-menu notify-drop-menu" aria-labelledby="navbarDropdown">
              <h5 class="mb-0">Notification</h5>
              <hr class="m-0">
              <div class="notification-listing-block">
                <div *ngFor="let data of notificationlist" class="notify-blocks">
                  <a class="media p-3">
                    <img *ngIf="data.notification_type ==='10'" src="assets/images/notify-call-icon.png" alt="image" />
                    <img *ngIf="data.notification_type ==='13'" src="assets/images/notify-callend-icon.png"
                      alt="image" />

                    <img
                      *ngIf="data.profile_image != '' && data.notification_type !='10' && data.notification_type !='13'"
                      src="{{imgUrl}}{{data.profile_image}}" alt="image" />

                    <img
                      *ngIf="data.profile_image === '' && data.notification_type !='10' && data.notification_type !='13'"
                      src="assets/images/dummy_user.png" alt="icon" />
                    <div class="media-body ml-2">
                      <h6 class="mt-0 p-0 f-16 primary-blue-cl">{{data.name | titlecase}}</h6>
                      <p *ngIf="data.notification_type !='10' && data.notification_type !='13'" class="mb-0 f-14">
                        {{data.message}}</p>
                      <p *ngIf="data.notification_type ==='10' && data.notification_type !='13'" class="mb-0 f-14">
                        Missed a call</p>
                      <p *ngIf="data.notification_type ==='13' && data.notification_type !='10'" class="mb-0 f-14">end
                        the call</p>

                    </div>
                    <small class="text-right light-grey-cl f-12">{{ convertTime( data.created_date) | date: 'medium'
                      }}</small>
                  </a>
                </div>
              </div>

            </div>
          </li>
          <li class="nav-item dropdown profile-hd-drop d-flex align-items-center" data-dismiss="modal">
            <img *ngIf="profilData?.profile_image !=''" class="mx-2" src="{{ImageUrl}}{{profilData?.profile_image}}"
              alt="profile-photo" />
            <img *ngIf="profilData?.profile_image ==''" class="mx-2" src="assets/images/dummy_user.png" alt="image">

            <span class="status my-3"></span>
            <a class="nav-link dropdown-toggle dark-grey-cl" href="#" id="navbarDropdown" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{profilData?.name}}
            </a>
            <div class="dropdown-menu profile-drop-menu" aria-labelledby="navbarDropdown">
              <div class="text-center p-3">
                <img *ngIf="profilData?.profile_image !=''" class="mb-2" src="{{ImageUrl}}{{profilData?.profile_image}}"
                  alt="image" />
                <img *ngIf="profilData?.profile_image ==''" class="mx-2" src="assets/images/dummy_user.png" alt="image">
                <p class="f-12 mb-0">{{profilData?.name}}
                </p>
                <p class="f-12 mb-0">{{profilData?.city}} | {{profilData?.state}}</p>
              </div>
              <hr class="m-0">
              <ul class="list-unstyled profile-nav">
                <li class="my-2 d-flex align-item-center"><img class="mr-2" src="assets/images/header/profile.svg"
                    alt="icon" /><a class="light-grey-cl" (click)="redirect('myProfile')">Profile</a></li>
                <li class="mb-2"><img class="mr-2" src="assets/images/header/rate-us.svg" alt="icon" /><a
                    class="light-grey-cl" data-toggle="modal" data-target="#rating">Rate Us</a></li>
                <li (click)="redirect('privacy')" class="mb-2"><img class="mr-2"
                    src="assets/images/header/privacy-policy.svg" alt="icon" /><a class="light-grey-cl">Privacy
                    Policy</a></li>
                <li (click)="redirect('contact_us')" class="mb-2"><img class="mr-2"
                    src="assets/images/header/contact-us.svg" alt="icon" /><a class="light-grey-cl">Contact Us</a></li>
                <li (click)="redirect('blockusers')" class="mb-2"><img class="mr-2"
                    src="assets/images/header/contact-us.svg" alt="icon" /><a class="light-grey-cl">Block Users</a></li>
              </ul>
              <hr class="m-0">
              <a class="more-profile light-grey-bg dark-grey-cl mx-1 py-1 px-2 text-center d-flex justify-content-between"
                data-toggle="modal" data-dismiss="modal" data-target="#logoutt">Logout<img
                  src="assets/images/header/logout.svg" alt="icon" /></a>
              <a class="more-profile  mx-1 py-1 px-2 text-center d-flex justify-content-between"
                (click)="deleteAccout()">Delete Account</a>

            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>

<div class="modal fade" id="rating" aria-modal="true">
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content">
      <div class="modal-body calls-end text-center m-3 mb-0 pb-1">
        <div class="logo-beer d-inline-block mb-3">
          <img class="w-100" src="assets/images/big-beer-mug.svg" alt="icons" />
        </div>
        <h5 class="modal-title light-grey-cl">Rate Us</h5>
      </div>
      <div *ngIf="ratingData" class="modal-body add-bank-details my-0">
        <form #rating="ngForm" (ngSubmit)="ratingForm(rating)" class="add-bank-form">
          <div class="form-group floating mb-0">
            <input type="text" class="form-control floating" id="usr" placeholder="Tittle here" name="title" ngModel
              #title="ngModel" [(ngModel)]="ratingData.review_title" value="" required />
          </div>
          <div class="form-group floating mb-0">

            <star-rating [value]="starRating" [totalstars]="5" checkedcolor="yellow" uncheckedcolor="#e6e6e6"
              size="24px" (rate)="onRate($event)"></star-rating>
          </div>
          <div class="form-group floating mb-0">
            <textarea class="form-control floating" id="usr" placeholder="Comment here" name="comment" ngModel
              #comment="ngModel" [(ngModel)]="ratingData.feedback" value="" required></textarea>
          </div>
          <div class="set-level-btn d-flex justify-content-between mt-3">
            <a href="#" class="btn light-grey-bg f-14 small-btn" data-dismiss="modal">Cancel</a>
            <button *ngIf="!ratingStatus" type="submit" [disabled]="!rating.valid"
              class="btn primary-blue-bg text-white f-14 small-btn d-block">Submit Feedback</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="logoutt" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content">
      <div class="modal-body calls-end text-center m-3">
        <div class="logo-beer d-inline-block mb-3">
          <img class="w-100" src="assets/images/logout.png" alt="icons" />
        </div>
        <h4 class="mb-4">Do you really want to Logout?</h4>
        <div class="set-level-btn d-flex justify-content-between">
          <a class="btn light-grey-bg f-14 small-btn" data-dismiss="modal">Cancel</a>
          <a (click)="gotoWelcome()" class="btn primary-blue-bg text-white f-14 small-btn d-block">yes</a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="calling">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body invite-sent-msg text-center m-3">
        <h4>Unblock User</h4>
        <p>Are you sure want to unblock <b>@?</b></p>
        <div class="btn-sec">
          <button type="button" class="small-btn light-grey-bg dark-grey-cl mr-2" data-dismiss="modal">Cancel</button>
          <button type="button" class="small-btn primary-blue-bg text-white" data-dismiss="modal"
            aria-label="Close">Unblock</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->