<section class="login-block">
	<div class="left-side-bg">
		<img src="assets/images/bottoms-up-text.svg">
	</div>
	<!-- right side start -->
	<div class="right-side-block logins my-3">
		<div class="welcome-block">
			<h1>Welcome Back </h1>
			<p>To keep connected with us please login with your personal information by email address and password.</p>
			<form class="register-block" #loginForm="ngForm">

				<div class="form-group floating mb-0">
					<ng-container *ngIf="flags.loginByEmail">
						<input type="text" class="form-control floating" placeholder="Email" [(ngModel)]="body.email"
							name="email" #email="ngModel" autocomplete="no-fill" required />
						<label for="usr">Email</label>
						<div class="alert alert-danger email-input"
							*ngIf="(email.invalid && (email.dirty || email.touched))">
							Email is required.*
						</div>
					</ng-container>
					<ng-container *ngIf="!flags.loginByEmail">
						<div class="form-group floating mb-0">
							<div class="loginmail_input" *ngIf="countryCodeList && countryCodeList.length>0">
								<ngx-select-dropdown (change)="selectionChanged($event)" [(ngModel)]="body.countryCode"
									name="countryCode" ngModel #countryCode="ngModel" [config]="config"
									[options]="countryCodeList">
								</ngx-select-dropdown>

							</div>
							<input type="text" class="form-control floating" [(ngModel)]="body.phone" appNumberOnly
								#phone="ngModel" name="phone" ngModel placeholder="98******95" maxlength="15"
								required />
							<label for="usr">Phone</label>
							<div class="alert alert-danger"
								*ngIf="(phone.invalid && (phone.dirty || phone.touched) || countryCode.invalid && (countryCode.dirty || countryCode.touched))">
								Country code & Phone is required.*
							</div>
						</div>
					</ng-container>
				</div>
				<div class="form-group floating mb-0 position-relative">
					<input type="password" class="form-control floating" autocomplete="off" required id="pass_log_id"
						[type]="hide1?'password':'text'" #password="ngModel" placeholder="Password"
						[(ngModel)]="body.password" name="password" ngModel>
					<div class="eye-icon">
						<span toggle="#password-field" (click)="toggle('hide1')"
							class="fa fa-fw fa-eye field_icon toggle-password"></span>
					</div>
					<label for="usr">Password</label>

					<div *ngIf="submitted && loginForm.controls.password.errors" class="alert alert-danger">
						<div *ngIf="loginForm.controls.password.errors.required">
							Password is required
						</div>
					</div>
				</div>

				<div class="form-group form-check mb-0">
					<input type="checkbox" class="form-check-input" [(ngModel)]="remember" name="remember" />
					<label class="form-check-label d-flex" for="exampleCheck1">Remember Me <span
							class="ml-auto forgot-password"><a routerLink="/forgot_password">Forgot Your
								Password?</a></span></label>
				</div>
				<div class="col-lg-12 text-center">
					<button (click)="Login(loginForm)" [disabled]="!loginForm.valid" class="btn account-but my-3"
						role="button">Login</button>
				</div>
				<br>
			</form>
			<div class="or-block mb-3">Login With</div>
			<!-- social link start -->
			<div class="flex-c-m social-link mb-3">
				<a (click)="signInWithFB()" class="login100-social-item bg1">
					<img src="assets/images/facebook.svg">
				</a>
				<a (click)="SignInWithTwitter()" class="login100-social-item bg2">
					<img src="assets/images/twitter.svg">
				</a>
				<a (click)="signInWithGoogle()" class="login100-social-item bg3">
					<img src="assets/images/google.svg">
				</a>
				<!-- <a  class="login100-social-item bg4">
					<twitch-login
					(callback)="out($event)"
					>
					<img src="assets/images/twitch.svg">

					</twitch-login>
				</a> -->
			</div> <!-- social link close -->
			<p>Don't have an account? <span><a class="primary-blue-cl" routerLink="/sign_up">Sign Up</a></span></p>
		</div>
	</div>
	<!-- right side close -->
</section>
<!-- Optional JavaScript -->
<!-- jQuery first, then Popper.js, then Bootstrap JS -->