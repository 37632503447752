<!---Main section--->
<section class="own-live-chat">
    <div class="container-fluid p-0">
        <div class="row no-gutters">
            <div class="col-md-8">
                <div class="live-own-video position-relative" id="agora_local">
                    <!---top--->
                    <div class="live-top position-absolute">
                        <!-- <a href="" class="mr-2"><img width="25px" src="assets/images/shareicn.png"></a> -->
                        <span class="live-status py-2 px-3 text-white f-14">Live</span>
                        <a class="seen ml-2 py-2 px-2 text-white f-14" data-toggle="modal" data-target="#suggeted"><img
                                class="mr-1" src="assets/images/seen-icon.svg" alt="icon" />{{otherUserConnectedCount }}</a>
                           

                    </div>
                    <!---top--->
                    <!--controls-->
                    <div class="volume d-inline-block" *ngIf="showRange">
                        <div class="form-group mb-0">
                            <input type="range" class="form-control-range" id="formControlRange"
                                (change)="rangeSelect($event)">
                        </div>
                    </div>
                    <div class="camera-controls position-absolute text-center">
                        <div class="live-camera camera-options mt-2 d-flex primary-blue-bg justify-content-between">
                            <a class="i1" (click)="enableDisableCamera()">
                                <img *ngIf="isVideoEnabled" src="assets/images/video-camera.png" alt="icon" height="30"
                                    width="30" />
                                <img *ngIf="!isVideoEnabled" src="assets/images/no-video-camera.png" alt="icon"
                                    height="30" width="30" />
                            </a>
                            <a class="i2" (click)="showVolumeRange()">
                                <img class="volume-img" src="assets/images/volume.svg" alt="icon" />
                            </a>
                            <a class="i3" (click)="muteUnmuteAudio()">
                                <img *ngIf="isAudioEnabled" src="assets/images/mic.png" alt="icon" />
                                <img *ngIf="!isAudioEnabled" src="assets/images/off-mike.png" alt="icon" />
                            </a>
                            <a class="i4 red-bg" (click)="leave()"><img src="assets/images/drop-phone-icon.svg"
                                    alt="icon" /></a>
                        </div>
                    </div>
                    <!--controls-->
                </div>
            </div>
            <div class="col-md-4">
                <div class="live-chat-sec">
                    <!---head--->
                    <h4 class="p-3 primary-blue-bg text-white">Live Chat
                        <span class="d-block live-time">LIVE since {{checkSinceLive()}}</span>
                    </h4>

                    <!---head--->
                    <!---chat-body--->
                    <div class="own-chat-body chat-body">
                        <ng-container *ngIf="commentList?.length>0; else other_element">
                            <div class="chat-user-box d-flex p-2" *ngFor="let comment of commentList;">
                                <img class="align-self-start" height="38" width="38" src="./assets/images/avatar.svg"
                                    alt="image" />
                                <div class="chat-user-info ml-3">
                                    <h5 class="mb-2 dark-grey-cl f-16">{{comment.name}}</h5>
                                    <p class="mb-0 light-grey-cl f-14">{{comment.comment}}</p>
                                </div>
                                <span class="light-grey-cl f-12">{{convertTime(comment.created_date) | date:'hh:mm
                                    a'}}</span>
                            </div>
                        </ng-container>
                        <ng-template #other_element>
                            <p class="nodatafound">No comment found</p>
                        </ng-template>
                    </div>
                    <!---chat-body--->
                    <div class="msg-box p-3">
                        <div class="msg-input position-relative">
                            <input type="text" [(ngModel)]="comment" (keydown)="onKeyDown($event)"
                                class="search-input form-control" (keyup.enter)="addComments()"
                                placeholder="Type Comment...">
                            <a (click)="toggleEmojiPicker()"><img class="smile-icon position-absolute"
                                    src="assets/images/smile-icon.svg" alt="icon" /></a>
                            <a (click)="addComments()"><img class="sent-btn position-absolute"
                                    src="assets/images/send-btn-icon.svg" alt="icon" /></a>
                            <emoji-mart class="emoji-mart" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)"
                                title="Pick your emoji…"></emoji-mart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!---Main section--->

<!---Call-End-Modal--->
<div class="modal fade" id="call-end">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body calls-end text-center m-3">
                <h4 class="text-uppercase">LIVE VIDEO ENDED</h4>
                <p><span>{{otherUserConnectedCount}}</span> Connected</p>
                <a type="button" class="small-btn primary-blue-bg text-white btn f-14"
                    (click)="endLiveStatus()">Done</a>
            </div>
        </div>
    </div>
</div>