<!-- <div id="find-friend">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title light-grey-cl"><b>Find Friend</b></h5>
        <button type="button" (click)="cancel()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body friend-listing-block">
        <input class="border-0 searchinput" type="text" [formControl]="searchForm" autocomplete="off" name="searchText"
          placeholder="Search">

        <div *ngFor="let users of getallUsers" class="user-online d-flex justify-content-between mb-4">
          <div class="name-sec d-flex">
            <img *ngIf="users.profile_image != ''" src="{{imageUrl}}{{users.profile_image}}" alt="image">
            <img *ngIf="users.profile_image == ''" src="assets/images/dummy_user.png" alt="image">
            <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">{{users.name}}</h5>
          </div>
          <div class="online-btns align-self-center">
            <a *ngIf="!users.req_status" (click)="Addtofriend(users)"
              class="small-btn fds primary-blue-bg p-2 text-white f-14">Add To Friends</a>
            <a *ngIf="users.req_status =='1'" (click)="requestCancel(users)"
              class="small-btn fds primary-blue-bg p-2 text-white f-14">Cancel Request
            </a>
          </div>

          <div *ngIf="users.req_status =='2'" class="online-btns align-self-center">
            <button (click)="join(users,users.id)"
              class="btn common-btn green-bg p-2 text-white f-14 mr-2">Call</button>
            <button *ngIf="users.id != userInfo?.id" (click)="gotouserChat(users)" data-dismiss="modal"
              class="btn common-btn primary-blue-bg p-2 text-white f-14">Message</button>
          </div>
        </div>
        <div *ngIf="getallUsers && getallUsers.length ==0">No Data</div>
      </div>
    </div>
  </div>
</div> -->

<!---heading--->
<section class="p-sec container">
    <button type="button" (click)="cancel()" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
  <div class="px-3 common-card-box d-flex justify-content-between align-items-center">
    <input class="border-0 searchinput" type="text" [(ngModel)]="searchall" autocomplete="off" name="searchall"
      placeholder="Search">
    <a class="primary-blue-bg big-btn text-white" (click)="searchuser(searchall)">Search Friend</a>
  </div>
</section>
<!---heading--->
<!---suggestion--->
<section *ngIf="mutualData && mutualData.length>0" class="container mb-5">
  <div class="py-4 px-3  common-card-box suggestion-sec w-100">
    <h5 class="common-hd light-grey-cl mb-4 text-uppercase">People you may know</h5>
    <div class="suggested-list">
      <div class="sugg-box text-center p-4 position-relative mr-3 " *ngFor="let data of mutualData">
        <img *ngIf="data.profile_image != ''" class="mb-3" src="{{imageUrl}}{{data.profile_image}}" alt="icon" />
        <img *ngIf="data.profile_image === ''" class="mb-3" src="assets/images/dummy_user.png" alt="icon" />
        <a (click)="remove(data)">
          <img class="close-cross position-absolute" src="assets/images/close-cross.svg" alt="icon" />
        </a>
        <p class="mb-0 dark-grey-cl">{{data.name}}</p>
        <p class="f-12 light-grey-cl">{{mutualCount}} Mutual Friends</p>
        <a *ngIf="!data.status" (click)="addFriend(data)" class="small-btn fds primary-blue-bg p-2 text-white f-14">Add
          To Friends</a>
        <a *ngIf="data.status=='1'" (click)="requestCancel(data)"
          class="small-btn fds primary-blue-bg p-2 text-white f-14">Cancel Request</a>

        <div *ngIf="mutualData && mutualData.length === 0">No Data</div>
      </div>
    </div>
  </div>
</section>
<!---suggestion--->
<!---extra-section--->
<section class="container">
  <div class="row">
    <!-- <div class="col-md-8">
      <div class="online-friends common-card-box py-4">
        <h5 class="primary-blue-cl px-3">Your Online Friends</h5>
        <hr>
        <div class="common-search position-relative mx-3">
          <form>
            <div class="form-group mb-0">
              <input type="email" class="search-input form-control" [(ngModel)]="search" autocomplete="off"
                name="search" placeholder="Search...">
            </div>
          </form>
          <img class="position-absolute" src="assets/images/search-icon.svg" (click)="searchFriendList(search)"
            alt="icon" />
        </div>
        <div class="user-list-scroll px-3 mt-3">
          <div *ngFor="let list of OnlineFriendList" class="user-online d-flex justify-content-between mb-3">
            <div class="name-sec d-flex">
              <div class="position-relative">
                <img *ngIf="list.profile_image !=''" src="{{imageUrl}}{{list.profile_image}}" alt="image">
                <img *ngIf="list.profile_image ==''" src="assets/images/dummy_user.png" alt="image">
                <div class="status-green align-self-center position-absolute"></div>
              </div>
              <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">{{list.name}}</h5>
            </div>
            <div class="online-btns align-self-center">
              <button (click)="join(list,list.UserID)" class="small-btn green-bg text-white f-14 mr-2">Call</button>
              <button routerLink="/chat" class="small-btn primary-blue-bg text-white f-14">Message</button>
            </div>
          </div>
          <div *ngIf="OnlineFriendList && OnlineFriendList.length==0">No Data</div>
        </div>
      </div>
    </div> -->
    <div class="col-md-12">
      <!--group-info-->
      <div class="create-group common-card-box py-4 mb-4">
        <div class="d-flex justify-content-between px-3">
          <h5 class="dark-grey-cl d-flex">{{userInfo?.name}}<span class="status-green align-self-center ml-2"></span>
          </h5>
          <div class="lock-status green-bg px-2 py-1 f-14 text-white">
            <img class="white-lock mr-2" src="assets/images/here-lock.svg" alt="icon" /><span>Here</span>
          </div>
        </div>
        <hr>
        <!--------- list of group invitation ------>
        <div class="activity-user-box" *ngIf="common?.allGroupList && common?.allGroupList.length>0 ">
          <div class="d-flex align-item-center justify-content-between">
            <h5 class="primary-blue-cl mb-2 p-3">Group Members</h5>
          </div>
          <div *ngFor="let list of common?.allGroupList; let i = index;" class="group-info position-relative d-flex px-3">
            <div class="user-img-wrapper">
              <img *ngIf="list.profile_image != '' && list.profile_image != null" class="img-fluid"
                src="{{imageUrl}}{{list.profile_image}}" alt="image">
              <img *ngIf="list.profile_image == '' || list.profile_image == null" class="img-fluid" src="assets/images/dummy_user.png"
                alt="image">
            </div>
            <div class="mb-0 align-self-center ml-2 dark-grey-cl d-flex align-item-center justify-content-between">
              <span *ngFor="let member of list?.members">{{member?.name + ', '}}
              </span>
              <input class="form-control groupnameinput" [(ngModel)]="groupname" type="text" placeholder="Enter Group Name" #groupName
              (keyup.enter)="updateGroup(list.groupDetails.is_lock,list.groupDetails.group_id)"
               (click)="onGroupClick(list.groupDetails)" name="groupname"
               [value]="list.groupDetails?.group_name"
               [readonly]="!checkIfAdmin(list.groupDetails) || (list.groupDetails?.group_name != '')"/>
            </div>
          </div>
        </div>


        <div class="nodatafound" *ngIf="common?.allGroupList?.groups && common?.allGroupList?.groups.length==0">No Data</div>
      

        <div *ngIf="groups && groups.length>0">
          <hr class="mb-0">
          <h5 class="primary-blue-cl mb-2 p-3">Group Members</h5>
          <div *ngFor="let data of groups">
            <div class="group-name-edit input-group mb-3 mx-3" id="group-check">
              <form *ngIf="data.admin.id === userInfo?.id && isEdit ">
                <div class="form-group mb-0 d-flex ">
                  <input type="email" class="form-control mr-2" [(ngModel)]="groupname" name="groupname"
                    id="exampleInputEmail1" placeholder="Enter Group Name">
                  <button class="btn primary-blue-bg check-btn" type="submit"
                    (click)="updateGroup(data.groupDetails.is_lock,data.groupDetails.group_id)"><img
                      src="assets/images/group-name-check.svg" alt="icon" /></button>
                </div>
              </form>
            </div>
            <div *ngIf="data.admin.id === userInfo?.id" class="group-name-view mb-3 px-3" id="group-edit">
              <div class=" light-grey-bg d-flex p-2 justify-content-between">
                <p class="primary-blue-cl mb-0">{{data.groupDetails?.group_name}}</p>
                <a (click)="editgroup(data.groupDetails?.group_name)"><img src="assets/images/edit-icon.svg"
                    alt="icons" /></a>
              </div>
            </div>

            <div class="group-name px-3">

              <div *ngFor="let  list of data.members" class="group-members d-flex mb-3">

                <img *ngIf="list.profile_image != ''" src="{{imageUrl}}{{list.profile_image}}" alt="image">
                <img *ngIf="list.profile_image == ''" src="assets/images/dummy_user.png" alt="image">
                <!-- <img src="assets/images/live-user-img.svg" alt="image">               -->
                <h6 class="mb-0 align-self-center ml-3 dark-grey-cl">{{list.name}}<br>
                  <!-- <span
                    class="f-12">{{list.email}}</span> -->
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--group-info-->
      <!--recent-activities-->
      <div class="recent-activity-sec common-card-box">
        <h5 class="primary-blue-cl d-flex px-3 pt-3 mb-1">Recent Activities</h5>
        <hr class="mb-0">
        <!---activity-user-->
        <div class="activity-user-box px-3 pt-3 mb-3">
          <div *ngFor="let data of recentActivites" class="activity-user-info d-flex mb-3">
            <div class="activity-user">
              <p *ngIf="data.group_name !=''" class="mb-0 dark-grey-cl">{{data.group_name}}</p>
              <p *ngIf="data.group_name ==''" class="mb-0 dark-grey-cl"><span class="status-dot"
                  *ngFor="let data1 of data.members">{{data1.name}}</span>
              </p>

              <p class="mb-0 light-grey-cl f-12">You Sent a Room Invite | {{data.group_date | date:'shortTime'}}</p>
            </div>
            <div class="activity-btns ml-auto align-self-center">
              <button (click)="join(data,data.members.member_id)" class="circle-btn green-bg mr-1"><img
                  src="assets/images/call-circle-icon.svg" alt="icons" /></button>
              <button routerLink="/chat" class="circle-btn light-grey-bg"><img src="assets/images/chat-circle-icon.svg">
              </button>
            </div>
          </div>
          <div *ngIf="recentActivites && recentActivites.length==0">No Data</div>
        </div>
      </div>
      <!--recent-activities-->
    </div>
  </div>
</section>
<!---extra-section--->

<!-- find friend modal -->
<div class="modal fade" id="find-friend" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title light-grey-cl"><b>Find Friend</b></h5>
        <button type="button" (click)="cancel()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body friend-listing-block">
        <div *ngFor="let users of getallUsers" class="user-online d-flex justify-content-between mb-4">
          <div class="name-sec d-flex">
            <img *ngIf="users.profile_image != ''" src="{{imageUrl}}{{users.profile_image}}" alt="image">
            <img *ngIf="users.profile_image == ''" src="assets/images/dummy_user.png" alt="image">
            <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">{{users.name}}</h5>
          </div>
          <div class="online-btns align-self-center">
            <a *ngIf="!users.req_status" (click)="Addtofriend(users)"
              class="small-btn fds primary-blue-bg p-2 text-white f-14">Add To Friends</a>
            <a *ngIf="users.req_status =='1'" (click)="requestCancel(users)"
              class="small-btn fds primary-blue-bg p-2 text-white f-14">Cancel Request
            </a>
          </div>

          <div *ngIf="users.req_status =='2'" class="online-btns align-self-center">
            <button (click)="join(users,users.id)"
              class="btn common-btn green-bg p-2 text-white f-14 mr-2">Call</button>
            <button *ngIf="users.id != userInfo?.id" (click)="gotouserChat(users)" data-dismiss="modal"
              class="btn common-btn primary-blue-bg p-2 text-white f-14">Message</button>
          </div>
        </div>
        <div *ngIf="getallUsers && getallUsers.length ==0">No Data</div>
      </div>
    </div>
  </div>
</div>