import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api-service/api.service";
import { CommonService } from "src/app/services/common/common.service";
import Swal from "sweetalert2";
import { SocketService } from "../../services/socketService/socket.service";
import { SubjectService } from "../../services/subject.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SetAlcoholLevelDialog } from "../../_helper/dialogs/dialog-references";
import { GroupCallStatusService } from "src/app/services/group-call-status.service";
declare var $: any;
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  isGroupCallPageRunning: boolean = false;
  userinfo: any;
  isPrivate: boolean = true;
  liveUserCount: number = 0;
  imageUrl: string;
  mediaStream: any;
  isLive: boolean = false;
  isAudioEnabled: boolean = false;
  constructor(
    private router: Router,
    public common: CommonService,
    private service: ApiService,
    private socket: SocketService,
    public subject: SubjectService,
    private modalService: NgbModal,
    private changeDetectorRef: ChangeDetectorRef,
    private groupCallStatusService: GroupCallStatusService
  ) {
    if (localStorage.getItem("Bottom_up_user")) {
      this.userinfo = JSON.parse(
        localStorage.getItem("Bottom_up_user") || "{}"
      );
      this.socket.isUserPrivate.next(parseInt(this.userinfo.is_lock,0) ? true: false);
    }
    this.socket.liveUsersCount.subscribe((count: number) => {
      this.getFriendList();
    });
    this.checkUser();
    this.socket.isInviteUser.next(false);
    this.socket.isPublicCalljoins.next(true);
    this.socket.isCallEnded.next(true);
    this.socket.getUserActivityStatus.subscribe((isPrivate: boolean) => {
      if (isPrivate) {
        this.isPrivate = true;
      } else {
        this.isPrivate = false;
      }
    });
  }

  ngOnInit(): void {
    this.groupCallStatusService.getGroupCallStatus().subscribe((res) => {
      this.isGroupCallPageRunning = res;
    });
    localStorage.removeItem("rtcToken");
    this.getFriendList();
    this.goToProfileUrl();
  }

  ngAfterViewInit() {
    this.accessCamera();
    this.checkUser();
    this.socket.getPublicMike.subscribe((value: any)=> {
      this.isAudioEnabled = value
      var vid: any = document.getElementById("videoElement");
      if (this.isAudioEnabled) {
        vid.muted = true;
      } else {
        vid.muted = false;
      }
    });
    this.changeDetectorRef.detectChanges();
  }

  checkUser(){
    if (localStorage.getItem("Bottom_up_user")) {
      this.userinfo = JSON.parse(
        localStorage.getItem("Bottom_up_user") || "{}"
      );
      let data = {
        token: this.userinfo.token,
        userID: this.userinfo.id,
      };

      this.socket.openWebSocket(data);
      setTimeout(() => {
        this.comeOnline();
        this.getLiveCount();
      }, 1000);
    }
  }
  getLiveCount() {
    let data = {
      user_id: this.userinfo.id,
      serviceType: "MyLiveCount",
    };
    this.socket.sendMessage(data);
  }

  accessCamera() {
    var that = this;
    let video: any = document.querySelector("#videoElement");
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((stream) => {
          video.srcObject = stream;
          that.mediaStream = stream;
        })
        .catch(function (error) {
          // that.askForCameraPermission();
          that.common.error("Please provide camera and microphone permission");
        });
    } else {
      console.log("User media not supported");
    }
  }

  askForCameraPermission() {
    Swal.fire({
      text: "Please provide camera permission",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28A0B5",
      cancelButtonColor: "#d33",
      confirmButtonText: "Allow",
    }).then((result) => {
      if (result.isConfirmed) {
        this.accessCamera();
      }
    });
  }

  getNewMessage() {
    let data = {
      user_id: this.userinfo.id,
      serviceType: "MyLiveCount",
    };
    this.socket.sendMessage(data);
  }

  lockstatus() {
    this.comeOnline();
    var fd = new FormData();
    this.isPrivate = !this.isPrivate;
    fd.append("status", this.isPrivate ? "1" : "0");
    this.service.lockStatus(fd).subscribe((res: any) => {
      if (res["success"] == 1) {
        this.common.hideSpinner();
        this.socket.setUserPrivate(this.isPrivate);
        localStorage.setItem("Bottom_up_user", JSON.stringify(res.data));
        this.common.success(res.message);
      }
    });
  }

  goLive() {
    if (localStorage.getItem("Bottom_up_user")) {
      this.userinfo = JSON.parse(
        localStorage.getItem("Bottom_up_user") || "{}"
      );
    }
    var fd = new FormData();
    fd.append("channelID", this.userinfo.ChatRoomName);
    this.service.getRtcToken(fd).subscribe((res: any) => {
      if (res["success"] == 1) {
        localStorage.setItem("rtcToken", res.token);
        this.common.hideSpinner();
        this.router.navigate(["/live"]);
      } else {
        this.common.hideSpinner();
        this.common.error(res.message);
      }
    });
  }

  checkLiveUser() {
    if (this.liveUserCount > 0) {
      this.router.navigate(["/live_users"]);
    }
  }

  getFriendList() {
    this.liveUserCount = 0;
    this.service.getLiveUser("").subscribe((res: any) => {
      
      if (res["success"] === 1) {
        this.common.hideSpinner();
        this.liveUserCount = res["LiveFriends"].length;
      } else {
        // clearInterval(this.interval);
      }
    });
  }

  ngOnDestroy() {
    if (this.mediaStream && this.mediaStream.getVideoTracks()) {
      this.mediaStream.getVideoTracks().forEach((element: any) => {
        element.stop();
      });
    }
  }

  setAlcoholLevel() {
    this.modalService.open(SetAlcoholLevelDialog, {
      size: "sm",
      ariaLabelledBy: "modal-basic-title",
      // backdrop: 'static'
    });
  }

  comeOnline() {
    console.log('comeOnline')
    let data = {
      user_id: this.userinfo.id,
      serviceType: "Online",
    };
    this.socket.sendMessage(data);
  }

  gotolive() {
    this.isLive = true;
  }

  endLiveCall() {
    this.isLive = false;
    setTimeout(() => {
      this.accessCamera();
    }, 2000);
  }

  muteUnmuteAudio() {
    this.isAudioEnabled = !this.isAudioEnabled;
    this.socket.setUserPublicMike(this.isAudioEnabled);
    var vid: any = document.getElementById("videoElement");
    if (this.isAudioEnabled) {
      vid.muted = true;
    } else {
      vid.muted = false;
    }
  }

  goToProfileUrl(){
    if(sessionStorage.getItem('redirectuserid')){
      var redirecturl:any = sessionStorage.getItem('redirectuserid')
      window.location.href=redirecturl
      sessionStorage.removeItem('redirectuserid')
    }

    
  }
}
