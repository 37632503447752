import { Component, NgZone, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { StarRatingComponent } from "ng-starrating";
import { ApiService } from "src/app/services/api-service/api.service";
import { CommonService } from "src/app/services/common/common.service";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../../environments/environment";
import { FindFriendComponent } from "src/app/Pages/search-group/find-friend/find-friend.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddFriendPopupComponent } from "src/app/Pages/add-friend/add-friend-popup/add-friend-popup.component";
import { SearchGroupComponent } from "src/app/Pages/search-group/search-group.component";
import { ChatTogglerService } from "src/app/services/chat-toggler.service";
import { ChatComponent } from "src/app/Pages/chat/chat.component";
declare var $: any;

enum ModalConfig {
  size = "medium",
  ariaLabelledBy = "modal-basic-title",
  // backdrop = 'static'
}
enum ModalConfigLarge {
  size = "xl",
  ariaLabelledBy = "modal-basic-title",
  // backdrop = 'static'
}
@Component({
  selector: "app-headerforcalling",
  templateUrl: "./headerforcalling.component.html",
  styleUrls: ["./headerforcalling.component.scss"],
})
export class HeaderforcallingComponent implements OnInit {
  isGroupCallRunning: boolean = false;
  profilData: any;
  ImageUrl: string;
  starRating = 0;
  rating: any;
  ratingData: any;
  ringToneAudio: any;
  ratingStatus: boolean = false;
  notificationlist: any;
  currentMessage = new BehaviorSubject(null);
  hasNotification = false;
  imgUrl = environment.imageUrl;
  status: any;
  userinfo: any;
  countInterval: any;
  timer: number;
  data: any;
  token: any;
  groupId: any;
  private uid: number;

  constructor(
    private router: Router,
    private common: CommonService,
    private service: ApiService,
    private zone: NgZone,
    private modalService: NgbModal,
    private chatToggler: ChatTogglerService
  ) {}

  ngOnInit(): void {
    this.ImageUrl = this.service.imageUrl;
    this.getProfile();
    this.getFeedback();
  }

  getnotifiaction() {
    this.service.getnotificationlist("").subscribe((res: any) => {
      if (res["success"] == 1) {
        this.notificationlist = res.data;
        this.notificationlist.reverse();
       
        this.common.hideSpinner();
        this.hasNotification = false;
        this.notificationlist.forEach((element: any) => {
          this.status = element.notification_type;
        });
      } else {
        this.common.hideSpinner();
        // this.common.error(res.message);
      }
    });
  }

  gotoWelcome() {
    this.service.logout().subscribe((res: any) => {
      if (res["success"] == 1) {
        this.common.hideSpinner();
        this.common.success(res.message);
        localStorage.clear();
        sessionStorage.clear();
        $("#logoutt").modal("hide");
        this.router.navigate(["/"]);
      } else {
        this.common.hideSpinner();
        this.common.error(res.message);
      }
    });
  }

  getProfile() {
    this.service.getProfile("").subscribe((res: any) => {
      if (res["success"] == 1) {
        this.profilData = res.data;
        this.common.hideSpinner();
      } else {
        this.common.hideSpinner();
        this.common.error(res.message);
      }
    });
  }

  getFeedback() {
    this.service.getrating("").subscribe((res: any) => {
      if (res["success"] == 1) {
        this.ratingData = res.data;
        if (res.data != " ") {
          this.ratingStatus = true;
          this.starRating = JSON.parse(res.data.user_rating);
        }
        this.common.hideSpinner();
      } else {
        this.common.hideSpinner();
        this.common.error(res.message);
      }
    });
  }

  deleteAccout() {
    this.service.deleteAccount("").subscribe((res: any) => {
      if (res["success"] == 1) {
        this.common.hideSpinner();
        this.common.success(res.message);
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(["/"]);
      } else {
        this.common.hideSpinner();
        this.common.error(res.message);
      }
    });
  }

  onRate($event: {
    oldValue: number;
    newValue: number;
    starRating: StarRatingComponent;
  }) {
    this.starRating = $event.newValue;
    this.rating = this.starRating;
  }

  ratingForm(rating: any) {
    var fd = new FormData();
    fd.append("title", rating.form.value.title);
    fd.append("rating", this.rating);
    fd.append("comment", rating.form.value.comment);

    this.service.rating(fd).subscribe((res: any) => {
      if (res["success"] == 1) {
        this.common.hideSpinner();
        this.common.success(res.message);
        $("#rating").modal("hide");
      } else {
        this.common.hideSpinner();
        this.common.error(res.message);
      }
    });
  }

  redirect(route: any) {
    let confirmation = confirm(
      "This will leave the call. do you want to proceed?"
    );
    if (confirmation) {
      this.router.navigate([`/${route}`]);
    }
  }

  openModal(component: any) {
    let componentType: any;
    switch (component) {
      case "FindFriendComponent": {
        componentType = FindFriendComponent;
        break;
      }
      case "AddFriendPopupComponent": {
        componentType = AddFriendPopupComponent;
        break;
      }
      case "chat": {
        this.chatToggler.setChatVisibility(true);
        componentType = ChatComponent;
        break;
      }
    }
   let modalRef =  this.modalService.open(
      componentType,
      component === "chat" ? ModalConfigLarge : ModalConfig
    );
    modalRef.componentInstance.anyDataForm = null;
  }

  openChat(chatVisibility: boolean) {
    this.chatToggler.setChatVisibility(chatVisibility);
  }
}
