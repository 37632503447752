import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { MessagingService } from 'src/app/services/messaging.service';
declare var $: any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})

export class SignUpComponent implements OnInit {
  config: object;
  countryStatus: boolean = true;
  countryCode: any = '';
  countryCodeList: Array<any> = [];
  passwordvalid: boolean = false;
  confirmvalid: boolean = false;
  constructor(
    private messagingService: MessagingService,
    private common: CommonService,
    private service: ApiService,
    private router: Router,
    private localstorage: LocalStorageService) {
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage();
  }
  type: any
  ngOnInit(): void {
  }

  signup(form: NgForm) {
    if (form.form.value.password == '' || form.form.value.confirmpassword == '' || form.form.value.email == '') {
      return this.common.error('Please enter all details')
    }
    if (!form.form.value.checkbox)
      return this.common.error('Please agree with terms and conditions')
    var emailType = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    var phoneType = /^\d{10}$/;
    if (form.form.value.email.match(emailType) !== null) { this.type = 'Email' }
    let formdata = new FormData();
    if (this.type === 'Email') {
      formdata.append("email", form.value.email);
    }
    formdata.append("password", form.form.value.password);
    formdata.append("type", this.type);
    // formdata.append("devicetype", 'web');
    let deviceId = this.common.getRandomString(16);
    localStorage.setItem('deviceId',deviceId);
    formdata.append('devId',deviceId);
    this.service.signup(formdata).subscribe((res: any) => {
      this.common.hideSpinner();
      if (res['success'] == 1) {
        this.localstorage.set("token", (res.data.token));
        this.common.success(res.message)
        this.router.navigate(['/otp', {type:this.type}])
      }
      if (res['success'] == 4) {
        this.common.error(res.message);
        this.localstorage.set("token", (res.data.token));
        this.router.navigate(['/profile'])
      } else if (res['success'] != 1 && res['success'] != 4) {
        this.common.error(res.message);
      }
    },error =>{
      this.common.hideSpinner();
    })
  }

  gotologin() {
    this.router.navigate(['/login'])
  }

  toggle(type: any) {
    $(document).on('click', '.toggle-password', function (this: unknown) {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $("#pass_log_id");
      input.attr('type') === 'password' ? input.attr('type', 'text') : input.attr('type', 'password')
    });
  }

  checkvalidation(e: any) {
    if (e.target.value != '') {
      this.passwordvalid = true;
    }
    else {
      this.passwordvalid = false;
    }
  }

  checkvalidation1(e: any) {
    if (e.target.value != '') {
      this.confirmvalid = true;
    } else {
      this.confirmvalid = false
    }
  }

  toggle1(type: any) {
    $(document).on('click', '.toggle-password1', function (this: unknown) {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $("#pass_log");
      input.attr('type') === 'password' ? input.attr('type', 'text') : input.attr('type', 'password')
    });
  }
}
