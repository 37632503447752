<!--group-->
<div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">

    <!--common-search-->
    <div class="common-search position-relative mx-3 mb-3">
        <form>
            <div class="form-group mb-0">
                <input type="email" id="searchText" name="searchText" [(ngModel)]="searchText"
                    class="search-input form-control" placeholder="Search...">
            </div>
        </form>
        <img class="position-absolute" src="assets/images/search-icon.svg" alt="icon">
    </div>
    <!--common-search-->
    <div class="chat-listing-users">
        <!--when-no-msg-->
        <div class="no-msg text-center py-4 h-100 d-none">
            <img src="assets/images/no-msg-vector.svg" alt="vector" />
            <h5>No Messages</h5>
            <p>You have no messages yet.Use the pencil icon to start new conversation with you
                friends.</p>
            <!-- <a href="#" class="btn primary-blue-bg text-white big-btn">Add Friend</a> -->
        </div>
        <!--when-no-msg-->
        <!--when-there-is-list-->
        <div class="inbox_chat">
            <div class="chat_list" *ngFor="let data of groupList | filter:searchText">
                <div class="chat_people d-flex">
                    <div (click)="gtotogroupChat(data)">
                        <div class="chat_img">
                            <!-- <img class="w-100" src="assets/images/group-img.svg" alt="sunil"> -->
                            <img class="w-100" *ngIf="data.group_pic != ''"
                                src="{{imageUrl}}{{data.group_pic}}" alt="image" />
                            <img class="w-100" *ngIf="data.group_pic === ''"
                                src="assets/images/dummy_user.png" alt="icon" />
                        </div>
                        <div class="d-flex justify-content-between mb-2">
                            <h5 class="mb-0 dark-grey-cl">
                                {{(data?.group_name != '')?data?.group_name:data?.group_id}}</h5>
                            <!-- <span class="f-12 green-bg text-white px-2 py-1">On A Call</span> -->
                        </div>
                        <p class="dark-grey-cl">{{data.msg}}</p>
                    </div>
                </div>
                <div *ngIf="groupList && groupList.length==0"><img
                        src="assets/images/no-group-chat-vector.svg" alt="vector" />
                </div>
            </div>
        </div>
        <!--when-there-is-list-->
    </div>
</div>
<!--group-->