  <ng-template #temp1>
    <div class="modal fade" id="setlevel">
        <div class="modal-dialog modal-dialog-centered modal-sm">
          <div class="modal-content">
            <div class="modal-body calls-end text-center m-3">
              <div class="logo-beer d-inline-block mb-3">
                <img class="w-100" src="assets/images/big-beer-mug.svg" alt="icons" />
              </div>
              <h4 class="mb-4">Set Your Alcohol Level</h4>
              <div class="set-level-btn d-flex justify-content-between">
                <a href="#" class="btn light-grey-bg f-14 small-btn" data-dismiss="modal">Cancel</a>
                <a (click)="setlevel()" class="btn primary-blue-bg text-white f-14 small-btn d-block">OK</a>
              </div>
            </div>
          </div>
        </div>
      </div>
  </ng-template>