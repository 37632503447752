import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import '@firebase/messaging';
import firebase from 'firebase/app';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
import { SubjectService } from './subject.service';
import { CommonService } from 'src/app/services/common/common.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SocketService } from './socketService/socket.service';
@Injectable()
export class MessagingService {
    deviceToken: any;
    newMessage = new BehaviorSubject<any>('');
    constructor(
        public angularFireMessaging: AngularFireMessaging,
        private subject: SubjectService,
        private common: CommonService,
        private router: Router,
        private socket: SocketService,
    ) {
        try {
            firebase.initializeApp(environment.firebaseConfig);
            const messaging = firebase.messaging();
        } catch (e) {
            console.log(e)
        }
    }

    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe((token) => {
            if (token == null) {
                // this.checkPermission()
                // this.presentToast('Warning', 'top-end', 'You blocked the notifications');
                // this.requestPermission();
            } else {
                this.deviceToken = token;
                this.receiveMessage();
            }
        }, (err) => {
            console.error('Unable to get permission to notify.', err);
        });
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload: any) => {
                console.log("new message received. ", payload);
                this.newMessage.next(payload)
                // this.newGroupMessage.next(payload)
                if (payload.data != undefined) {
                    // if(!!payload.notification){
                    //     this.showNotification(payload.notification);
                    // }else{
                    //     this.showNotification(payload.data);
                    // }
                    if (payload.data.notificationTypeId == 1) {   // for friend request
                        this.common.showAlert(payload.data.title, '/myFriendList', 'Ok');
                    }
                    if (payload.data.notificationTypeId == 2) {   // for unfriend
                        this.common.EndLive(payload.data.title);
                    }
                    if (payload.data.notificationTypeId == 4) {
                        this.common.showAlert(payload.data.message, '/search', 'Join');
                    }
                    if (payload.data.notificationTypeId == 7 || payload.data.notificationTypeId == 12) { // for live stream joined user count
                        this.socket.setLiveUsersCount(payload.data.liveStatus);
                    }
                    if (payload.data.notificationTypeId == 7) {
                        this.common.showAlertForLive(payload.data);
                    }
                    if (payload.data.notificationTypeId == 12) {   //for end live
                        this.common.EndLive(payload.data.title);
                        this.router.navigate(['home']);
                    }
                    if (payload.data.notificationTypeId == '11' || payload.data.notificationTypeId == 11) {
                        this.showAlert(payload.data.caller_name + ' calling you!', payload.data);
                    }
                    if (payload.data.notificationTypeId == '13' || payload.data.notificationTypeId == 13) {
                        this.presentToast('warning', 'top-end', payload.data.message);
                    }

                    if (payload.data.notificationTypeId == "message") {
                        
                        this.common.messageToast(payload.data);
                        console.log(payload.data)

                    }
                    if (payload.data.notificationTypeId == '20') {
                        let datasort=payload.data;
                        let idsort=JSON.parse(datasort.id)
                        datasort.id=idsort;
                        this.common.messageGroupToast(datasort);
                       
                    }

                }
            }, err => {
                console.log('notification blocked')
            })
    }

    showNotification(value: any) {
        // create a new notification
        let body = {
            body: value.title,
            icon: './assets/images/big-beer-mug.png'
        }
        const notification = new Notification('Bottoms up', body);

        // close the notification after 10 seconds
        setTimeout(() => {
            notification.close();
        }, 10 * 1000);

        // navigate to a URL
        // notification.addEventListener('click', () => {
        //     window.open('https://www.javascripttutorial.net/web-apis/javascript-notification/', '_blank');
        // });
    }

    async checkPermission() {
        // check notification permission
        let granted = false;
        if (Notification.permission === 'granted') {
            granted = true;
        } else if (Notification.permission !== 'denied') {
            let permission = await Notification.requestPermission();
            granted = permission === 'granted' ? true : false;
        }

        // show notification or error
        granted ? this.receiveMessage() : this.presentToast('warning', 'top-end', 'You blocked the notifications.');
    }

    presentToast(type: any, position: any, msg: any) {
        const Toast = Swal.mixin({
            toast: true,
            position, // top-end
            showCancelButton: false,
            showCloseButton: true,
            showConfirmButton: false,
            timer: 3000
        });
        Toast.fire({
            icon: type,
            title: msg
        });
    }

    showAlert(msg: any, data?: any) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonText: 'Join',
            timer: 20000
        });
        Toast.fire({
            title: msg
        }).then((result: any) => {
            if (result.isConfirmed == true) {
                let params = {
                    id: data.id, g_id: data.ChatRoomName ? data.ChatRoomName : data.group_id, isAppToWeb: 1, isPrivate: false
                }
                this.router.navigate(['/stream'], {
                    queryParams: {
                        id: this.common.encryptData(params)
                    }
                });
            }
        })
    }
}