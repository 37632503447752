import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appCharacterOnly]'
})
export class CharacterOnlyDirective {
  @Input() isFullName: boolean = false;

  constructor(
    private _el: ElementRef
  ) { }
  @HostListener('input', ['$event']) onInputChange(event:any) {
    let regex = /[^a-zA-Z]*/g;
    if (this.isFullName) regex = /[^a-zA-Z ]*/g;
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(regex, '');
    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

}
