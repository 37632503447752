<app-header></app-header>
<!-- <app-headerforcalling></app-headerforcalling> -->
<!----Header End--->

<!---heading--->
<section class="p-sec container">
  <div class="px-3 common-card-box d-flex justify-content-between align-items-center">
    <input class="border-0 searchinput" type="text" [(ngModel)]="searchall" autocomplete="off" name="searchall"
      placeholder="Search">
    <a class="primary-blue-bg big-btn text-white" (click)="searchuser(searchall)">Search Friend</a>
  </div>
</section>
<!---heading--->
<!---suggestion--->
<section *ngIf="mutualData && mutualData.length>0" class="container mb-5">
  <div class="py-4 px-3  common-card-box suggestion-sec w-100">
    <h5 class="common-hd light-grey-cl mb-4 text-uppercase">People you may know</h5>
    <div class="suggested-list">
      <div class="sugg-box text-center p-4 position-relative mr-3 " *ngFor="let data of mutualData">
        <img *ngIf="data.profile_image != ''" class="mb-3" src="{{imageUrl}}{{data.profile_image}}" alt="icon" />
        <img *ngIf="data.profile_image === ''" class="mb-3" src="assets/images/dummy_user.png" alt="icon" />
        <a (click)="remove(data)">
          <img class="close-cross position-absolute" src="assets/images/close-cross.svg" alt="icon" />
        </a>
        <p class="mb-0 dark-grey-cl">{{data.name}}</p>
        <p class="f-12 light-grey-cl">{{mutualCount}} Mutual Friends</p>
        <a *ngIf="!data.status" (click)="addFriend(data)" class="small-btn fds primary-blue-bg p-2 text-white f-14">Add
          To Friends</a>
        <a *ngIf="data.status=='1'" (click)="requestCancel(data)"
          class="small-btn fds primary-blue-bg p-2 text-white f-14">Cancel Request</a>

        <div class="nodatafound" *ngIf="mutualData && mutualData.length === 0">No Data</div>
      </div>
    </div>
  </div>
</section>
<!---suggestion--->
<!---extra-section--->
<section class="container">
  <div class="row">
    
    <div class="col-lg-6">
      <!--group-info-->
      <div class="create-group common-card-box pt-3 pb-2 mb-4">
        <div class="d-flex justify-content-between px-3">
          <h5 class="dark-grey-cl d-flex">{{userInfo?.name}}<span class="status-green align-self-center ml-2"></span>
          </h5>
          <div class="lock-status green-bg px-2 py-1 f-14 text-white">
            <img class="white-lock mr-2" src="assets/images/here-lock.svg" alt="icon" /><span>Here</span>
          </div>
        </div>
        <hr class="mb-0">
        <!------------ list of users those are public ----------------->

        <div class="activity-user-box">
       
          <ng-container *ngIf="common?.allOnlineFriendsList.length">
            <div *ngFor="let list of common?.allOnlineFriendsList" class="group-info d-flex px-3">
              <div class="user-img-wrapper">
                <img *ngIf="list.profile_image != '' && list.profile_image != null" class="img-fluid"
                  src="{{imageUrl}}{{list.profile_image}}" alt="image">
                <img *ngIf="list.profile_image == '' || list.profile_image == null" class="img-fluid" src="assets/images/dummy_user.png"
                  alt="image">
              </div>
              <h6 class="mb-0 align-self-center ml-2 dark-grey-cl"><span>{{list?.name}}</span></h6>
              <button (click)="joincall(list, true)" class="small-btn primary-blue-bg text-white ml-auto align-self-center">Join</button>
            </div>
          </ng-container>

          <ng-container *ngIf="!common?.allOnlineFriendsList?.length">
            <!-- <div class="nodatafound">No Data</div> -->
          </ng-container>
        
          <ng-container *ngIf="groupCallList.length>0 ">
            <div *ngFor="let list of groupCallList" class="group-info d-flex px-3">
              <div class="user-img-wrapper">
                <img *ngIf="list.profile_image != '' && list.profile_image != null" class="img-fluid"
                  src="{{imageUrl}}{{list.profile_image}}" alt="image">
                <img *ngIf="list.profile_image == '' || list.profile_image == null" class="img-fluid" src="assets/images/dummy_user.png"
                  alt="image">
              </div>
              <h6 class="mb-0 align-self-center ml-2 dark-grey-cl"><span *ngFor="let member of list?.members">{{member.name}},</span></h6>
              <button (click)="joincall(list, false)" class="small-btn primary-blue-bg text-white ml-auto align-self-center">Join</button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-lg-6">

      <!--group-info-->
      <!--recent-activities-->
      <div class="recent-activity-sec common-card-box pb-2 mb-4">
        <h5 class="primary-blue-cl d-flex px-3 pt-3 mb-1">Recent Activities</h5>
        <hr class="mb-0">
        <!---activity-user-->
        <div class="activity-user-box px-3 pt-3" style="height: 259px;">
          <div *ngFor="let data of recentActivites" class="activity-user-info d-flex">
            <div class="activity-user mb-3" *ngIf="data?.members.length">
              <p *ngIf="data.group_name !=''" class="mb-0 dark-grey-cl">{{data.group_name}}</p>
              <p *ngIf="data.group_name ==''" class="mb-0 dark-grey-cl"><span class="status-dot"
                  *ngFor="let data1 of data.members">{{data1.name}}</span>
              </p>
              <p class="mb-0 light-grey-cl f-12">You Sent a Room Invite | {{convertTime(data.group_date) | date:'shortTime'}}</p>
            </div>
            <div class="activity-btns ml-auto align-self-center" *ngIf="data?.members.length">
              <button (click)="connectCall(data)" class="circle-btn green-bg mr-1"><img
                  src="assets/images/call-circle-icon.svg" alt="icons" /></button>
              <button (click)="redirectChat(data)" class="circle-btn light-grey-bg"><img src="assets/images/chat-circle-icon.svg">
              </button>
            </div>
          </div>
          <div class="nodatafound" *ngIf="!recentActivites.length">No Data</div>
        </div>
      </div>
      <!--recent-activities-->
    </div>
  </div>
</section>
<!---extra-section--->

<!-- find friend modal -->
<div class="modal fade" id="find-friend" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title light-grey-cl"><b>Find Friend</b></h5>
        <button type="button" (click)="cancel()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body friend-listing-block">
        <div *ngFor="let users of getallUsers" class="user-online d-flex justify-content-between mb-4">
          <div class="name-sec d-flex">
            <img *ngIf="users.profile_image != ''" src="{{imageUrl}}{{users.profile_image}}" alt="image">
            <img *ngIf="users.profile_image == ''" src="assets/images/dummy_user.png" alt="image">
            <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">{{users.name}}</h5>
          </div>
          <div class="online-btns align-self-center">
            <a *ngIf="!users.req_status" (click)="Addtofriend(users)"
              class="small-btn fds primary-blue-bg p-2 text-white f-14">Add To Friends</a>
            <a *ngIf="users.req_status =='1'" (click)="requestCancel(users)"
              class="small-btn fds primary-blue-bg p-2 text-white f-14">Cancel Request
            </a>
          </div>

          <div *ngIf="users.req_status =='2'" class="online-btns align-self-center">
            <button (click)="join(users,users.id)"
              class="btn common-btn green-bg p-2 text-white f-14 mr-2">Call</button>
            <button *ngIf="users.id != userInfo?.id"
            (click)="gotouserChat(users)" data-dismiss="modal" class="btn common-btn primary-blue-bg p-2 text-white f-14">Message</button>
          </div>
        </div>
        <div class="nodatafound" *ngIf="getallUsers && getallUsers.length ==0">No Data</div>
      </div>
    </div>
  </div>
</div><script type = "text/javascript" >
  function preventBack(){window.history.forward();}
   setTimeout("preventBack()", 0);
   window.onunload=function(){null};
</script>