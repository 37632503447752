<app-headerforcalling></app-headerforcalling>
<section class="main-video-screen position-relative">
  <div class="video-sec">
    <div class="person-box">
      <div class="conatiner-fluid">
        <div class="video_row"
          [ngClass]="{'double-item':remoteStream.value.length > 4, 'single-item': remoteStream.value.length  <= 4}">
          <div class="video-item" *ngFor="let callId of remoteStream | async">
            <div class="video-capt position-relative d-flex">
              <div [id]="callId?.id" class="remote" (click)="toggleRemote(callId?.id)" *ngIf="callId?.stream?.pc">
                <div class="position-absolute video-capt-controls" style="z-index: 9999;" *ngIf="callId?.toggle">
                  <div class="control-icons d-inline-block">
                    <a class="p-3 d-block" (click)="toggleVolume(callId?.id)">
                      <img class="volume-img" src="./assets/images/volume-pop.svg" alt="icon" />
                    </a>
                    <div class="volume d-inline-block w-100" *ngIf="callId?.toggleVolume">
                      <div class="form-group mb-0 w-100">
                        <input type="range" class="form-control-range cstmrange mx-auto" id="formControlRange"
                          (change)="rangeSelect($event)">
                      </div>
                    </div>
                  </div>
                  <a class="p-3 d-block" (click)="muteUnmuteRemoteUser(callId)">
                    <img class="mic-img" *ngIf="!callId?.mute" src="./assets/images/mic.svg" alt="icon" />
                    <img class="mic-img" *ngIf="callId?.mute" src="./assets/images/off-mike.png" alt="icon" />
                  </a>
                  <a class="p-3 d-block" (click)="showAlcoholLevelPopup(callId?.id)"><img class="level-img"
                      src="./assets/images/level-icon.svg" alt="icon" /></a>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div class="video-capt">
              <div [id]="localCallId" class="local"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row connecting">
      <div class="col-md-12 text-center">
        <h2 *ngIf="!remoteStream.value.length">Connecting...</h2>
      </div>
    </div>
    <div class="camera-controls position-absolute text-center">
      <div class="video-call-options mt-2 d-flex primary-blue-bg justify-content-between">
        <a (click)="enableDisableCamera()">
          <img *ngIf="isVideoEnabled" src="./assets/images/video-camera.png" alt="icon" height="30" width="30" />
          <img *ngIf="!isVideoEnabled" src="./assets/images/no-video-camera.png" alt="icon" height="30" width="30" />
        </a>
        <a (click)="muteUnmuteAudio()">
          <img *ngIf="isAudioEnabled" src="./assets/images/mic.png" alt="icon" />
          <img *ngIf="!isAudioEnabled" src="./assets/images/off-mike.png" alt="icon" />
        </a>
        <a class="red-bg" (click)="endCall()"><img src="./assets/images/drop-phone-icon.svg" alt="icon" /></a>
        <a (click)="lockstatus()">
          <img *ngIf="isPublic" src="./assets/images/unlocked.png" alt="icon" />
          <img *ngIf="!isPublic" src="./assets/images/lock.png" alt="icon" />
        </a>
        <a (click)="setAlcoholLevel()">
          <img src="./assets/images/beer-icon.png" width="40" alt="icon" />
        </a>
      </div>
    </div>
  </div>
</section>
