// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  baseURL: 'http://shakti.parastechnologies.in/houseParty/Api/',
  imageUrl: 'http://shakti.parastechnologies.in/houseParty/profileImages/',
  TwitterAuthRedirectUri: 'http://localhost:4200/',
  agora: {
    appId: 'f07033f31b7d436ab62d948320dd0a01'
  },
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
 firebaseConfig : {
  apiKey: "AIzaSyDOS7INLLEq0ISj-UlAKv3qvzaA33j1bkY",
  authDomain: "bottoms-up-ca8f0.firebaseapp.com",
  projectId: "bottoms-up-ca8f0",
  storageBucket: "bottoms-up-ca8f0.appspot.com",
  messagingSenderId: "139909969068",
  appId: "1:139909969068:web:a4c7de032ef65b28fa9b09",
  measurementId: "G-9F6C0HMGB9"
},
stripekey:'pk_test_51INmi7IW4NRxgKMrdRnpulMA9mtQHrEqor5RwEXHKp1g7KNsG8jxoakjGkYCHJHkGGE2Q2286IhO1HxUSW1DvM5500UQKSd5UA',
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
