import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SubjectService {
  public onlineStatus = new Subject<any>();
  public userOnline = new Subject<any>();
  public profileUpdate = new Subject<any>();
  public chatInfo = new Subject<any>();
  public newMsg = new Subject<any>();
  public liveComment = new Subject<any>();
  public addComment = new Subject<any>();
  constructor() { }
}
