import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';
import { LoginBody } from 'src/app/request/login-body';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { GoogleLoginProvider, FacebookLoginProvider, SocialAuthService } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import { MessagingService } from 'src/app/services/messaging.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { SubjectService } from '../../services/subject.service';
import { SocketService } from '../../services/socketService/socket.service';
declare var $: any;
// declare module 'login-with-twitter'
// import 'login-with-twitter'
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  body = new LoginBody();
  remember: boolean = false;
  submitted: boolean = false;
  type: any
  countryCode: any
  flags = {
    isSignUpDone: false,
    isVerifyOtpDone: false,
    isLogin: false,
    loginByEmail: true
  };
  hide1 = true;
  hide2 = true;
  email: any;
  countryCodeList: Array<any> = [];
  phone: any;
  password: any;
  config: {}
  user: SocialUser;
  googleDetail: any;
  secretToken: any;
  userinfo: any;
  deviceId: any;
  constructor(
    private router: Router,
    private messagingService: MessagingService,
    private localStorage: LocalStorageService,
    private common: CommonService, private service: ApiService, private route: ActivatedRoute,
    private authService: SocialAuthService,
    private socket: SocketService,
    private db: AngularFireDatabase,
    public subject: SubjectService
  ) {

    this.route.queryParams.subscribe((res: any) => {
      (res) ? this.VerifyToken(res) : '';
    })
  }

  ngOnInit() {
    this.countryCode = '';
    this.countryCodeList = this.common.getCountryCodes();
    this.countryCodeList.map((i) => {
      i.dial_code = i.dial_code
    });
    this.config = {
      displayKey: "dial_code", //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: "150px", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder: "Select", // text to be displayed when no item is selected defaults to Select,
      customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: this.countryCodeList.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
      moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: "No results found!", // text to be displayed when no items are found while searching
      searchPlaceholder: "Search", // label thats displayed in search input,
      searchOnKey: "dial_code" // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    };
    this.common.hideSpinner()
    if (localStorage.getItem("remember")) {
      let data: any = localStorage.getItem("remember");
      this.remember = true;
      this.body.email = JSON.parse(data).email,
        this.body.password = JSON.parse(data).password
    } else {
      this.body.email = '';
      this.body.password = '';
    }
    this.deviceId = this.common.getRandomString(16);
  }
  public outCb: any;
  /** Callback Data **/
  out(event: any) {
    this.outCb = event;
  }

  selectionChanged(e: any) { }

  VerifyToken(res: any) {
    let { oauth_token, oauth_verifier } = res;
    this.service.verifyTwitterTokenData(oauth_token, oauth_verifier).subscribe((response: any) => {
      console.log("data from twitter----------", response);

    })
  }

  Login(loginForm: any) {
    this.countryCode = ''
    loginForm.submitted = true

    if (this.remember) {
      localStorage.setItem("remember", JSON.stringify(loginForm.form.value));
    } else {
      localStorage.clear();
    }
    var emailType = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    var phoneType = /^\d{10}$/;

    if (this.flags.loginByEmail) {
      if (loginForm.form.value.email.match(emailType)) {
        this.type = 'Email'
      }
    } else {
      if (loginForm.form.value.phone.match(phoneType)) {
        this.type = 'Phone'
      }
    }
    var fd = new FormData();
    if (this.type === 'Email') {
      fd.append("email", loginForm.form.value.email);
      fd.append("password", loginForm.form.value.password);
      fd.append("devicetype", 'web');
      fd.append('devicetoken', this.messagingService.deviceToken);
      fd.append('devId', this.deviceId);
      localStorage.setItem('type', "email");
    }
    this.service.login(fd).subscribe((res: any) => {
      if (res["success"]) {
        this.common.hideSpinner();
        this.socket.isUserPrivate.next((parseInt(res.data.is_lock, 0)) ? true : false)
        localStorage.setItem("Bottom_up_user", JSON.stringify(res["data"]));
        this.localStorage.set("token", res['token'].token);
        this.common.success("User Login Successfully");
        if (localStorage.getItem('Bottom_up_user')) {
          this.userinfo = JSON.parse(localStorage.getItem('Bottom_up_user') || '{}');
          this.messagingService.requestPermission();
          this.messagingService.receiveMessage();
          setTimeout(() => {
            this.getNewMessage();
          }, 2000)
          this.db.list('Users').set(this.userinfo.id, { UserId: this.userinfo.id, DeviceId: this.deviceId }).then((result) => {
           })
        }
        this.subject.onlineStatus.next('online');
        this.router.navigate(["/home"]);
      } else {
        this.common.hideSpinner();
        this.common.error(res["message"]);
      }
    },
      err => {
        this.common.hideSpinner();
        this.common.error(err.error["message"]);
      }
    );
  }


  switchLoginType() {
    this.flags.loginByEmail = !this.flags.loginByEmail;
    this.countryCode = '';
    if (!this.flags.loginByEmail)
      this.body.phone = '';
    this.body.email = '';

  }
  getNewMessage() {
    let data = {
      'user_id': this.userinfo.id,
      'serviceType': 'Online'
    }
    this.socket.sendMessage(data)
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((x: any) => {
      this.googleDetail = x;
      var fd = new FormData();
      if (this.googleDetail != undefined) {
        fd.append('social_id', this.googleDetail.id);
        fd.append('devicetype', 'web');
        fd.append('email', this.googleDetail.email);
        fd.append('name', this.googleDetail.name);
        fd.append('devicetoken', this.messagingService.deviceToken);
        fd.append('devId', this.deviceId);
        console.log(this.googleDetail, "details")
        this.service.sociallogin(fd).subscribe((res: any) => {
          this.common.hideSpinner();
          if (res["success"] === 1) {
            this.getProfile(res);
          }
          if (res['success'] == 4) {
            this.common.hideSpinner()
            this.localStorage.set("token", res['data'].token);
            this.router.navigate(['/profile'])
          }
          if (res['success'] == 5) {
            this.common.error(res["message"]);
          }
        }, err => {
          this.common.hideSpinner();
        }
        );
      }

    });
  }

  logOut(): void {
    this.authService.signOut();
  }

  SignInWithTwitter() {
    // $('#twitter-button').on('click', function() {
    //   // Initialize with your OAuth.io app public key
    //   var OAuth = require('OAuth').OAuth2; 

    //   OAuth.initialize('YOUR_OAUTH_KEY');
    //   // Use popup for OAuth
    //   OAuth.popup('twitter').then((twitter:any) => {
    //     
    //     // Prompts 'welcome' message with User's email on successful login
    //     // #me() is a convenient method to retrieve user data without requiring you
    //     // to know which OAuth provider url to call
    //     twitter.me().then((data:any) => {
    //       console.log('data:', data);
    //       alert('Twitter says your email is:' + data.email + ".\nView browser 'Console Log' for more details");
    //     });
    //     // Retrieves user data from OAuth provider by using #get() and
    //     // OAuth provider url    
    //     twitter.get('/1.1/account/verify_credentials.json?include_email=true').then((data :any)=> {
    //       console.log('self data:', data);
    //     })    
    //   });
    // })
    // const app = require('simple-get')
    // const LoginWithTwitter = require('login-with-twitter')

    // const tw = new LoginWithTwitter({
    //   consumerKey: 'S2brf0KpoerMsNzlo1aN4iBLM',
    //    consumerSecret: 'keCIBzI8r68xLjqcItsJKvMdNPf5mrEG70dUglUeq8qLJIyAiO',
    //   callbackUrl: 'https://example.com/twitter/callback'
    // })
    // LoginWithTwitter.get('/twitter', (req:any, res:any) => {
    // tw.login((err:any, tokenSecret:any, url:any) => {
    //   console.log(err,res,tokenSecret,"jhbhj")
    //   if (err) {
    //     // Handle the error your way
    //   }
    // })

    // Save the OAuth token secret for use in your /twitter/callback route
    //     req.session.tokenSecret = tokenSecret

    //     // Redirect to the /twitter/callback route, with the OAuth responses as query params
    //     res.redirect(url)
    //   })
    // })
    // const twitterCallBackUrl = environment.TwitterAuthRedirectUri;

    // OAuth.initialize('YOUR_OAUTH_KEY');
    //     // Use popup for OAuth
    //     OAuth.popup('twitter').then((twitter:any) => {
    //     
    // Prompts 'welcome' message with User's email on successful login
    // #me() is a convenient method to retrieve user data without requiring you
    // to know which OAuth provider url to call
    //   twitter.me().then((data:any) => {
    //     console.log('data:', data);
    //     alert('Twitter says your email is:' + data.email + ".\nView browser 'Console Log' for more details");
    //   });
    //   // Retrieves user data from OAuth provider by using #get() and
    //   // OAuth provider url     
    //   twitter.get('/1.1/account/verify_credentials.json?include_email=true').then((data:any) => {
    //     console.log('self data:', data);
    //   })    
    // });
    this.service.getOauthTwitter('').subscribe((response: any) => {
      const oauthToken = response.token;
      this.secretToken = response.tokenSecret;
      window.location.href = 'https://api.twitter.com/oauth/authorize?oauth_token=' + oauthToken, "_blank";
    })
  }

  public signInWithFB() {
    const socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    this.authService.signIn(socialPlatformProvider).then((x: any) => {
      this.googleDetail = x;
      var fd = new FormData();
      if (this.googleDetail != undefined) {
        fd.append('social_id', this.googleDetail.id);
        fd.append('devicetoken', this.googleDetail.authToken);
        fd.append('devicetype', 'web');
        fd.append('email', this.googleDetail.email);
        fd.append('name', this.googleDetail.name);
        fd.append('devId', this.deviceId);
        setTimeout(() => {
          this.service.sociallogin(fd).subscribe((res: any) => {
            if (res["success"] === 1) {
              this.getProfile(res);
            }
            if (res['success'] == 4) {
              this.common.hideSpinner()
              this.localStorage.set("token", res['data'].token);
              console.log('second')
              this.router.navigate(['/profile'])
            }
          },
            err => {
              this.common.hideSpinner();
            }
          );
        }, 1000);
      }

    });
  }

  toggle(type: any) {
    $(document).on('click', '.toggle-password', function (this: unknown) {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $("#pass_log_id");
      input.attr('type') === 'password' ? input.attr('type', 'text') : input.attr('type', 'password')
    });
  }

  getProfile(res: any) {
    this.common.hideSpinner();
    localStorage.setItem("Bottom_up_user", JSON.stringify(res["userData"]));
    this.localStorage.set("token", res['data'].token);
    this.router.navigate(['/otp', { type: 'Email' }]);
    if (res['userData'].ChatRoomName != '' && res['userData'].email_verification_status == '1' && res['userData'].phone_verification_status === '1') {
      this.router.navigate(['/home']);
      this.socket.openWebSocket('');
      setTimeout(() => {
        this.getNewMessage();
      }, 2000)
    }
  }
}
