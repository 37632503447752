import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private router:Router,private common:CommonService,private service:ApiService) { }

  ngOnInit(): void {
   localStorage.removeItem("forgotPassword");

  }
  Forgot(form:NgForm){
    console.log(form.form.value,"form")
    // this.router.navigate(['/login'])
    var fd=new FormData
    fd.append("password", form.form.value.password);
    this.service.resetPassword(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.success(res.message)
        this.router.navigate(['/login/'])
        localStorage.clear();
        sessionStorage.clear();
      }
      else {
        this.common.error(res.message);

      }
    })
  }
  gotoSign(){
    this.router.navigate(['/sign_up'])
  }
}
