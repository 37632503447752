import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';


@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(private localstorage: LocalStorageService,private router:Router   
    ) {    
    }

  ngOnInit(): void {
    // this.localStorage.clear();
  }

}
