import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecimalnumberDirective } from './decimal-only/decimalnumber.directive';

import { DirectiveRoutingModule } from './directive-routing.module';
import { NumberOnlyDirective } from './number-only/number-only.directive';
import { CharacterOnlyDirective } from './character-only/character-only.directive';
// import {GooglePlaceModule} from "ngx-google-places-autocomplete";
import {FormsModule} from "@angular/forms";
// import {AgmCoreModule} from '@agm/core';


@NgModule({
  declarations: [NumberOnlyDirective, CharacterOnlyDirective,DecimalnumberDirective],
  imports: [
    CommonModule,
    FormsModule,
    // GooglePlaceModule,
    DirectiveRoutingModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBMrKj9G0-f3QPF-P1D99iBChHT-PIICwo',
    //   libraries: ['places']
    // })
  ],
  exports: [
    NumberOnlyDirective,
    CharacterOnlyDirective,
    DecimalnumberDirective
  ]
})
export class DirectiveModule { }
