import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { NgForm, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common/common.service';
import { ConfirmAccountValidator } from 'src/app/_helper/confirm-account.validator';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-add-bank-account',
  templateUrl: './add-bank-account.component.html',
  styleUrls: ['./add-bank-account.component.scss']
})
export class AddBankAccountComponent implements OnInit {
  @Input() title:any;
  @Output() bankinfo = new EventEmitter<string>();
  cardList: any;
  bankForm:any;
  constructor(
    private apiService: ApiService,
    public common:CommonService,
    public fb:FormBuilder
  ) { }

  ngOnInit(): void {
    this.bankForm = this.fb.group({
      bank_name:['',[Validators.required,this.noWhitespaceValidator.bind(this)]],
      bank_holder_name:['',[Validators.required,this.noWhitespaceValidatorName.bind(this)]],
      account_number:['',[Validators.required,Validators.minLength(10)]],
      confirm_account_number:['',[Validators.required]],
      route_no:['',[Validators.required]],
    }, { validator: ConfirmAccountValidator.MatchAccount })
    this.getBankCard();
  }
  noWhitespaceValidator(control: FormControl) {
    if(!!control.value && control.value.length>0){
      const isSpace = (control.value[0] || '').match(/\s/g);
      return isSpace ? { 'whitespace': true } : null;
    }

  }
  noWhitespaceValidatorName(control: FormControl) {
    if(!!control.value && control.value.length>0){
    const isSpace = (control.value[0] || '').match(/\s/g);
    return isSpace ? { 'whitespaceName': true } : null;
    }
  }
  getBankCard() {
    this.apiService.getbankList().subscribe((res: any) => {
      if (res['success'] == 1) {
        this.cardList = res.data['bankDtl'];
        this.common.hideSpinner();
      }
    })
  }

  openModal() {
    $('#add-bank').modal('show');
  }

  addCard(addBankcard: NgForm) {
    this.common.showSpinner();
    var fd = new FormData();
    fd.append('name', this.bankForm.value.bank_holder_name)
    fd.append('bankname', this.bankForm.value.bank_name)
    fd.append('account', this.bankForm.value.account_number)
    fd.append('route', this.bankForm.value.route_no)
    this.apiService.addbank(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        $('#add-bank').modal('hide');
        this.common.success(res.message);
        this.common.hideSpinner();
        this.bankForm.reset();
        this.getBankCard()
      }else {
        this.common.hideSpinner();
        this.common.error(res.message);

      }
    })
  }

  selectBank(value: string) {
    this.bankinfo.emit(value);
  }

  setDefaultAccount(value:any){
    this.common.showSpinner();
    var fd = new FormData();
    fd.append('bank', value.id);
    this.apiService.setPrimaryBank(fd).subscribe((res: any) => {
      this.common.hideSpinner();
      if (res['success'] == 1) {
        this.common.success(res.message);
      }else{
        this.common.success(res.message);
      }
    })
  }

  deleteBank(value:any){
    Swal.fire({
      text: "Are you sure you want to delete this bank info?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28A0B5',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result:any) => {
      if (result.isConfirmed) {
    this.common.showSpinner();
    var fd = new FormData();
    fd.append('bank', value.account_no);
    this.apiService.deleteBank(fd).subscribe((res: any) => {
      this.common.hideSpinner();
      if (res['success'] == 1) {
        this.common.success(res.message);
        this.getBankCard();
      }else{
        this.common.success(res.message);
      }
    })
  }
  })
  }
}
