<div class="payment-method">
    <div class="common-blue-hd mb-3">
        <h5 class="primary-blue-cl mb-0">{{title}}</h5>
        <hr class="mb-0">
    </div>
    <p class="mb-4">Select active card to deduct amount for coins</p>
    <div *ngFor="let card of cardList" class="bank-card p-3 d-flex mb-3 align-items-center">
        <div class="common-radio align-self-top">
            <input type="radio" id="test{{card.id}}" name="radio-group" (click)="selectBank(card)">
            <label for="test{{card.id}}"></label>
        </div>
        <div class="bank-des">
            <img src="./assets/images/credit-card.png" alt="image" />
            <div class="acc-detail ml-3 d-inline-block">
                <h5 class="mb-1">{{card.user_name}}</h5>
                <p class="mb-0 light-grey-cl">{{card.card_number.substring(0,card?.card_number.length - 4)}}XXXX</p>
                <!-- <small class="f-12 m-0 green-cl">Active</small> -->
            </div>
        </div>
        <div class="d-inline-block ml-auto">
            <img (click)="deleteCard(card.card_number)" src="./assets/images/delete-icon.svg" alt="image" />
        </div>
    </div>
    <div class="bank-card p-3 d-flex justify-content-between" (click)="openModal()">
        <div class="add-bank">
            <h5 class="mb-1">Add Debit/Credit Card</h5>
            <p class="mb-0 light-grey-cl">Add Card to add coin in your account.</p>
        </div>
        <img src="./assets/images/add-bank.svg" alt="icon" />
    </div>
    <!-- <div class="text-center" (click)="proceed()">
        <button class="primary-blue-bg text-white big-btn">Proceed</button>
    </div> -->
</div>

<!--add card modal -->
<div class="modal fade" id="add-card" aria-modal="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title light-grey-cl">Add Card</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body add-bank-details my-3">
                <form class="add-bank-form" [formGroup]="addcardForm">
                    <div class="form-group floating mb-0">
                        <input type="text" class="form-control floating" appNumberOnly minlength="14" maxlength="18"
                            formControlName="card_number">
                        <label for="usr">Card Number</label>
                        <ng-container
                            *ngIf="!addcardForm.controls.card_number.valid && addcardForm.controls.card_number.dirty"
                            class="validator-error">
                            <span *ngIf="addcardForm.controls.card_number.hasError('required')" class="error">Card
                                number is
                                required</span>
                            <!-- <span *ngIf="addcardForm.controls.card_number.hasError('whitespace')" class="error">
                                Space not allowed</span> -->
                            <span *ngIf="addcardForm.controls.card_number.hasError('pattern')" class="error">
                                Only number allowed</span>
                            <span *ngIf="addcardForm.controls.card_number.hasError('minlength')" class="error">
                                Invalid card number</span>
                        </ng-container>
                    </div>

                    <div class="form-group floating mb-0">
                        <input type="text" class="form-control floating" formControlName="name">
                        <label for="usr">Card Holder Name</label>
                        <ng-container *ngIf="!addcardForm.controls.name.valid && addcardForm.controls.name.dirty"
                            class="validator-error">
                            <span *ngIf="addcardForm.controls.name.hasError('required')" class="error">Card holder name
                                is
                                required</span>
                            <span *ngIf="addcardForm.controls.name.hasError('whitespaceName')" class="error">
                                Space not allowed</span>
                        </ng-container>
                    </div>

                    <div class="form-group mb-0 expiry-month pl-0 pt-0">
                        <!-- <input type="date" class="form-control floating" formControlName="expiry_date"> -->
                        <label class="w-100 text-secondary">Expiry Date</label>
                        <select formControlName="month" class="p-1 mr-2 text-secondary">
                            <option *ngFor="let mon of months" value="{{mon}}">{{mon}}</option>
                        </select>
                        <select formControlName="year" class="p-1 text-secondary">
                            <option *ngFor="let year of years;">{{year}}</option>
                        </select>
                    </div>
                    <!-- <div class="form-group floating mb-0">
                  <input type="text" class="form-control floating" formControlName="cvv" maxlength="3">
                  <label for="usr">3 Digit CVV</label>
              </div> -->
                    <!-- <div class="form-group form-check mb-3">
                  <input type="checkbox" class="form-check-input">
                  <label class="form-check-label d-flex" for="exampleCheck1">Remember Me </label>
              </div> -->
                    <div class="text-center mt-3">
                        <button type="submit" class="big-btn primary-blue-bg text-white" (click)="addCard()">Add
                            Card</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>