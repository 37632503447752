import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-friendrequests',
  templateUrl: './friendrequests.component.html',
  styleUrls: ['./friendrequests.component.scss']
})
export class FriendrequestsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
