<app-header></app-header>
<section class="other-profile-sec">
    <div class="container py-5">
        <div class="row">
            <!--profile-info-left--->
            <div class="col-md-7">
                <!--profile-info-->
                <div class="profile-info common-card-box p-4 mb-4">
                    <div class="row">
                        <div class="col-md-12 col-lg-4">
                            <div class="profile-img-box position-relative text-center">
                                <!-- <img src="assets/images/profile-dummy.svg" alt="image" /> -->
                                <img *ngIf="userData?.profile_image !=''" src="{{imageUrl}}{{userData?.profile_image}}"
                                    alt="image" />
                                <img *ngIf="userData?.profile_image ==''" src="assets/images/dummy_user.png"
                                    alt="image" />
                                <div class="live-badge position-absolute text-white f-12">Live</div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-8">
                            <div class="profile-info-box mb-4">
                                <div class="profile-info">
                                    <h5>{{userData?.name}}</h5>
                                    <p class="pofile-dob mb-0"><img class="mr-2 mb-2" src="assets/images/dob.svg"
                                            alt="icon" />{{userData?.dob}}</p>
                                </div>
                                <div class="profile-btns d-flex py-3">
                                    <a *ngIf="request?.is_block =='1'" routerLink="/chat"
                                        class="small-btn d-block btn primary-blue-bg text-white f-14">Message</a>

                                    <a *ngIf="unfriend " (click)="unfriendUser('1')" data-toggle="tooltip"
                                        data-placement="top" title="Unfriend"
                                        class="square-btn-sm btn red-bg ml-2 text-center unfriend-btn">
                                        <img src="assets/images/unfriend-icon.svg" alt="icon" />
                                    </a>
                                    <a *ngIf="addfriend" (click)="unfriendUser('0')"
                                        class="small-btn d-block btn primary-blue-bg text-white f-14"
                                        data-toggle="tooltip" data-placement="top" title="add Friend">Add Friend</a>
                                    <a (click)="reportUser(userData)"
                                        class="square-btn-sm btn light-grey-bg ml-2 text-center dislike-btn"
                                        data-toggle="tooltip" data-placement="top" title="Dislike user">
                                        <img src="assets/images/dislike-icon.svg" alt="icon" />
                                    </a>
                                    <a (click)="blockuser()" data-toggle="tooltip" data-placement="top"
                                        title="Block user"
                                        class="square-btn-sm btn light-grey-bg ml-2 text-center block-btn">
                                        <img src="assets/images/block-icon.svg" alt="icon" />
                                    </a>
                                    <a *ngIf="userData?.donation_status === '1'" [routerLink]="['/donate',{id:userId}]"
                                        class="square-btn-sm btn green-bg ml-2 text-center donation-btn"
                                        data-toggle="tooltip" data-placement="top" title="Donation">
                                        <img src="assets/images/donation-icon.svg" alt="icon" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="profile-des">
                                <p class="mb-0">{{userData?.bio}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--profile-info-->
                <!--videos-->
                <div *ngIf="videoData" class="profile-videos common-card-box p-4">
                    <div class="common-blue-hd mb-3 d-flex justify-content-between">
                        <h5 class="primary-blue-cl mb-0">Videos</h5>
                        <p class="mb-0">{{videoData.length}}</p>
                    </div>
                    <hr class="m-0 mb-4">
                    <!-- <div *ngIf="videoData && videoData.length>0">

                        <div *ngFor="let video of videoData" class="video-box mb-2 position-relative">
                            <video width="100%" controls>
                                <source src="{{url}}{{video.videoLink}}" type="application/x-mpegURL">
                                <source src="mov_bbb.ogg" type="video/ogg">
                              
                            </video>
                            <vm-player #player playsinline (vPlaybackReady)="onPlaybackReady()"></vm-player>
                            <div class="video-info d-flex justify-content-between">
                                <h6>Dated:{{video.date_created |date}}</h6>
                                <br>
                                <h6> <span class="light-grey-cl f-14">city | State</span></h6>
                                <p class="mb-0 green-cl">{{video.connectedPersons}} People Connected</p>
                            </div>
                        </div>
                    </div> -->
                    <div *ngIf="videoData && videoData.length>0">
                       
                        <div *ngFor="let video of videoData; let i=index;" class="video-box mb-2 position-relative">
                            <video  id='hls-example{{i}}' class="video-js  w-100 vjs-default-skin"
                                width="640px" height="267px" controls
                                data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'>

                                <source src="{{url}}{{video.videoLink}}" type="application/x-mpegURL" />
                                <!-- <source src="https://vjs.zencdn.net/v/oceans.webm" type="video/webm" /> -->
                            </video>
                            <div class="video-info d-flex justify-content-between mt-lg-2 ">
                                <h6>Dated:{{video.date_created | date: 'dd-MM-yyyy'}}</h6>
                                <br>
                                <span class="delete-block mb-3">
                                    <p class="mb-0 green-cl">{{video.connectedPersons}} People Connected</p>
                                    <!-- <img src="assets/images/delete-icon.svg" alt="image" (click)="deleteVideo(video)"> -->
                                </span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="videoData && videoData.length==0" class="video-box mb-4">
                        No Data
                    </div>

                </div>
            </div>
            <!--profile-info-left--->
            <!--profile-info-right-->
            <div class="col-md-5">
                <div class="basic-info common-card-box p-3">
                    <div class="common-blue-hd mb-3">
                        <h5 class="primary-blue-cl mb-0">Basic Info</h5>
                        <hr>
                    </div>
                    <div class="basic-info-detail mb-3" *ngIf=" privacyInfo?.email == 1 || privacyInfo?.email == 2">
                        <p class="mb-0 dark-grey-cl">Email</p>
                        <p class="mb-0 light-grey-cl">{{userData?.email}}</p>
                    </div>
                    <div class="basic-info-detail mb-3" *ngIf=" privacyInfo?.phone == 1 || privacyInfo?.phone == 2">
                        <p class="mb-0 dark-grey-cl">Mobile Number</p>
                        <p class="mb-0 light-grey-cl">{{userData?.phone}}</p>
                    </div>
                    <div class="basic-info-detail mb-3" *ngIf=" privacyInfo?.city == 1 || privacyInfo?.city == 2">
                        <p class="mb-0 dark-grey-cl">City</p>
                        <p class="mb-0 light-grey-cl">{{userData?.city}}</p>
                    </div>
                    <div class="basic-info-detail mb-3" *ngIf=" privacyInfo?.state == 1 || privacyInfo?.state == 2">
                        <p class="mb-0 dark-grey-cl">State</p>
                        <p class="mb-0 light-grey-cl">{{userData?.state}}</p>
                    </div>
                    <div class="basic-info-detail mb-3" *ngIf=" privacyInfo?.address == 1 || privacyInfo?.address == 2">
                        <p class="mb-0 dark-grey-cl">Address</p>
                        <p class="mb-0 light-grey-cl">{{userData?.address}}</p>
                    </div>
                </div>
            </div>
            <!--profile-info-right-->
        </div>
    </div>
</section>