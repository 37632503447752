import { Component, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { SubjectService } from 'src/app/services/subject.service';
import { SocketService } from 'src/app/services/socketService/socket.service';
import * as moment from 'moment';

@Component({
  selector: 'app-live-users',
  templateUrl: './live-users.component.html',
  styleUrls: ['./live-users.component.scss']
})
export class LiveUsersComponent implements OnInit {
  LiveUserList: any;
  imageUrl: string;
  min: any='';
  time: any;
  notificationlist: any;
  hasNotification = false;
  status: any;
  mySubscription: any;
  userinfo: any;
  
  constructor(
    private service: ApiService, 
    private common: CommonService, 
    private router: Router,
    private subject:SubjectService,
    private socket: SocketService,
    ) { 
      this.userinfo = JSON.parse(localStorage.getItem('Bottom_up_user') || '{}');
      this.socket.liveUsersCount.subscribe((count: number) => {
        this.getLiveUserList();
      });
    }

  ngOnInit(): void {
    this.imageUrl = this.service.imageUrl
    this.getLiveUserList();
  }

  getLiveUserList() {
    this.service.getLiveUsersList('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.LiveUserList = res.LiveFriends;
        this.common.hideSpinner();
        if (this.type == 12 && this.type != 7){
          this.LiveUserList = null
        }
      }
    })
  }

  gotolive(live: any) {
    this.router.navigate(['/other_user_live', {data:JSON.stringify(live)}]);
  }

  notIntereste(data: any) {
    var fd = new FormData();
    fd.append('userId', data.UserID)
    fd.append('sessionId', data.SessionID)
    this.service.notIntersting(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.LiveUserList = res.LiveFriends
        this.common.hideSpinner()
        this.getLiveUserList()
      }
    })
  }

  type:any
  getnotifiaction() {
    this.service.getnotificationlist('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.notificationlist = res.data;
        this.type =  res.notificationlist.notification_type;
        this.notificationlist.reverse();
        this.common.hideSpinner();
        this.hasNotification = false;
        this.notificationlist.forEach((element: any) => {
          this.status = element.notification_type
        });
      } else {
        this.common.hideSpinner();
      }
    })
  }

  checkSinceLive(startTime: any) {
    var startTimessss = moment(new Date(startTime + ' UTC'));
    var now = new Date().getTime();
    var duration = startTimessss.milliseconds() + 1000 * (startTimessss.seconds() + 60 * (startTimessss.minutes() + 60 * startTimessss.hours()));
    if (isNaN(duration)) {
      return "";
    }
    if (duration < now) {
      var milisec_diff = now - duration;
    } else {
      var milisec_diff = duration - now;
    }
    var date_diff = new Date(milisec_diff);
    return (date_diff.getHours() >= 1 ? date_diff.getHours() + " Hours " : '') + (date_diff.getMinutes() >= 1 ? date_diff.getMinutes() + " Minutes " : '') + (date_diff.getSeconds() + " Seconds");
  }

}
