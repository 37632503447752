import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { SocketService } from 'src/app/services/socketService/socket.service';

@Component({
  selector: 'app-my-friend-list',
  templateUrl: './my-friend-list.component.html',
  styleUrls: ['./my-friend-list.component.scss']
})
export class MyFriendListComponent implements OnInit {
  FriendList: any;
  imageUrl: string;
  search: any;
  mutualData: any = [];
  mutualFriend: any;
  mutualCount: any;
  userinfo: any;

  constructor(
    private common: CommonService, 
    private service: ApiService, 
    private socket: SocketService, 
    private router: Router
  ) {
    if (localStorage.getItem('Bottom_up_user')) {
      this.userinfo = JSON.parse(localStorage.getItem('Bottom_up_user') || '{}');
    }
  }

  ngOnInit(): void {
    this.imageUrl = this.service.imageUrl
    this.getFriendList();
    this.getMutualFriend();
  }

  getFriendList() {
    this.service.getFriendList('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.FriendList = res.data;
        this.common.hideSpinner()
      }
    })
  }

  unfriendFriend(data: any) {
    var data1 = new FormData
    data1.append('friend', data.id)
    data1.append('status', '1')
    this.service.unfriend(data1).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.success(res.message);
        this.common.hideSpinner();
        this.unfriedUser(data.id)
        this.getFriendList();
      }
      else {
        this.common.error(res.message);
        this.common.hideSpinner()
      }
    })
  }

  unfriedUser(id: any) {
    let data = {
      'userID': this.userinfo.id,
      'serviceType': 'NotifyUserWhenUnfriend',
      'recieverID': id
    }
    this.socket.sendMessage(data)
  }

  getMutualFriend() {
    this.service.getMutualFriendList('').subscribe((res: any) => {
      if (res['success'] === 1) {
        this.mutualData = []
        this.mutualFriend = res['data'];
        this.mutualFriend.forEach((element: any) => {
          this.mutualCount = element.friend;
          this.mutualData.push(element.users)
          this.common.hideSpinner()
        });
      }
    })
  }

  addFriend(data: any) {
    var fd = new FormData();
    fd.append('friendId', data.id);
    fd.append('request', '1')
    this.service.addRemoveFriend(fd).subscribe((res: any) => {
      if (res["success"]) {
        this.common.success(res.message)
        this.common.hideSpinner();
        this.getMutualFriend()
      }
    })
  }
  filter(search: any) {
    if (search === '' || search === undefined) { return this.getFriendList() }
    var fd = new FormData()
    fd.append('term', search)
    this.service.searchFriend(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.hideSpinner();
        this.FriendList = res.data;
      }
    })
  }

  removeFromList(data: any) {
    var fd = new FormData();
    fd.append('mutualId', data.id)
    this.service.removeMutualFriend(fd).subscribe((res: any) => {
      if (res['success'] === 1) {
        this.common.hideSpinner();
        this.getMutualFriend()
      }
      else { this.common.hideSpinner() }
    })
  }
  gotoUserProfile(data: any) {
    this.router.navigate(['/other_user_profile', data.id])
  }
}
