import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../Component/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RatingModule } from 'ng-starrating';
import { SetAlcoholLevelComponent } from './dialogs/set-alcohol-level/set-alcohol-level.component';
import { HeaderforcallingComponent } from '../Component/headerforcalling/headerforcalling.component';
import { AddBankAccountComponent } from '../Pages/add-bank-account/add-bank-account.component';
import { AddCardComponent } from '../Pages/add-card/add-card.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { GoliveComponent } from '../Pages/golive/golive.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
@NgModule({
  declarations: [
    HeaderComponent,
    SetAlcoholLevelComponent,
    HeaderforcallingComponent,
    AddBankAccountComponent,
    AddCardComponent,
    GoliveComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RatingModule,
    BsDatepickerModule.forRoot(),
    PickerModule
  ],
  entryComponents: [HeaderComponent, HeaderforcallingComponent],
  exports: [HeaderComponent, HeaderforcallingComponent, AddBankAccountComponent, AddCardComponent, GoliveComponent]
})
export class SharedModule { }
