import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
declare var $: any;
import { defineCustomElements } from '@vime/core';
import { Router } from '@angular/router';
import { SubjectService } from '../../services/subject.service';
import { DOCUMENT } from '@angular/common';
declare const videojs: any;
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  @ViewChild('videoPlayer', { static: false }) videoplayer: ElementRef;
  isPlay: boolean = false;
  profilData: any;
  imageUrl: string;
  FriendList: any;
  privacyData: any;
  countryCodeList: { name: string; dial_code: string; code: string; }[];
  notification_status: any;
  phoneComponents: { IDDCC: any; NN: any; };
  File: any;
  profileImage: any;
  src: any;
  videoData: any;
  Emailtype: boolean = true;
  phonetype: boolean = true;
  phoneNumber: any;
  username: any;
  useraddress: any;
  userdob: any;
  userbio: any;
  usercity: any;
  userState: any;
  Dobdate: string;
  new_date: string;
  minDte = new Date();
  url: any = 'https://bu-bucket-for-testing.s3.ap-south-1.amazonaws.com/'
  userToken: any;
  constructor(
    private common: CommonService,
    private service: ApiService,
    public router: Router,
    public subject: SubjectService,
    private renderer:Renderer2,
    @Inject(DOCUMENT) private document: any
  ) {
    this.countryCodeList = this.common.getCountryCodes();
    defineCustomElements();
  }
  bsInlineValue = new Date();
  minDate = new Date();
  ngOnInit(): void {
    this.minDte.setDate(this.minDte.getDate() - 22);
    this.imageUrl = this.service.imageUrl
    this.getProfile();
    this.getFriendList();
    this.getprofileprivacy();
    this.getAllsaveVideo();
    this.getAllVideo()


  }
  getFriendList() {
    this.service.getFriendList('').subscribe((res: any) => {
      
      if (res['success'] == 1) {
        this.FriendList = res.data;
      }
    })
  }
  getprofileprivacy() {
    this.service.getProfileprivacy('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.privacyData = res.data;
      }
    })
  }

  onPlaybackReady() {
    // ...
  }

  getProfile() {
    this.service.getProfile('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.profilData = res.data
        this.userdob = res.data.dob;
        this.useraddress = res.data.address
        this.username = res.data.name
        this.userState = res.data.state
        this.usercity = res.data.city
        this.userbio = res.data.bio
        this.userToken = res.data.token
        var datePart = res.data.dob.match(/\d+/g),
          year = datePart[0].substring(2), // get only two digits
          month = datePart[1], day = datePart[2];
        this.userdob = month + '/' + day + '/' + year;
        var date = new Date(res.data.dob),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2);
        day = ("0" + date.getDate()).slice(-2);
        this.Dobdate = [date.getFullYear(), mnth, day].join("-");
        // this.notification_status = this.profilData.notification_status
        if (this.profilData.notification_status == '0') {
          this.notification_status = false;
        } else {
          this.notification_status = true;
        }
        this.src = `${this.imageUrl}${this.profilData.profile_image}`
        this.phoneNumber = this.profilData.phone;
        this.phoneComponents = {
          IDDCC: this.phoneNumber.substring(0, this.phoneNumber.length - 10).trim(),
          NN: this.phoneNumber.substring(this.phoneNumber.length - 10, this.phoneNumber.length)
        };
        this.common.hideSpinner();
      }
      else {
        // this.common.hideSpinner();
        // this.common.error(res.message);

        
      }
    })
  }


  onchangeStatus(event: any, type: any) {
    var fd = new FormData
    fd.append('status', event.target.value)
    this.service.changeStatus(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.success(res.message);
        this.common.hideSpinner()
      }
      else {
        // this.common.hideSpinner();
        // this.common.error(res.message);

      }
    })
  }

  onchangePrivacy(event: any, type: any) {
    var data = new FormData();
    if (type === 'dob') { data.append('dob', event.target.value) }
    if (type === 'email') { data.append('email', event.target.value) }
    if (type === 'phone') { data.append('phone', event.target.value) }
    if (type === 'state') { data.append('state', event.target.value) }
    if (type === 'city') { data.append('city', event.target.value) }
    if (type === 'address') { data.append('address', event.target.value) }
    this.service.updateUserPrivacy(data).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.success(res.message);
        this.common.hideSpinner()
      }
    })
  }

  getTimeSlots(time: any) {
    // console.log(time, "time")
    //   var date = new Date(time),
    //   mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //   day = ("0" + date.getDate()).slice(-2);
    // return  this.new_date =[date.getFullYear(), mnth, day].join("-");
    // formData.append("dob",[date.getFullYear(), mnth, day].join("-"));
  }

  getAllsaveVideo() {
    this.service.getallvideo('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.hideSpinner()
        this.videoData = res.data
      }
      this.getAllVideo()
    });
  }
  getAllVideo() {

    let script = this.renderer.createElement('script');
     script.src = "https://vjs.zencdn.net/7.2.3/video.js";

     
     this.renderer.appendChild(document.body,script);

    setTimeout(() => {
      for (var i = 0; i < this.videoData.length; i++) {
        var player = videojs('hls-example' + i);
        console.log(player)
        // player.play();
      }
     }, 2000);
  }


  onCancelModal() {
    this.getProfile()
  }

  EditProfile(data: NgForm) {
    let formData = new FormData();
    formData.append('name', data.form.value.name);
    formData.append('email', this.profilData.email);
    var date = new Date(data.form.value.dob),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    formData.append("dob", [date.getFullYear(), mnth, day].join("-"));
    formData.append('state', data.form.value.state);
    formData.append('city', data.form.value.city);
    formData.append('address', data.form.value.address);
    formData.append('bio', data.form.value.bio);
    formData.append('state', data.form.value.state);
    formData.append('phone', this.phoneNumber);
    formData.append('profile_image', this.File ? this.File : this.profilData.profile_image);
    this.service.updateProfile(formData).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.success(res.message);
        this.common.hideSpinner();
        this.subject.profileUpdate.next('profile');
        $('#editProfile').modal('hide');
        this.getProfile();
      } else {
        this.common.error(res.message);
        this.common.hideSpinner();

      }
    })
  }

  deleteVideo(video: any) {
    var fd = new FormData();
    fd.append('video', video.id)
    this.service.deleteSaveVideo(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.hideSpinner()
        this.common.success(res.message);
        this.getAllsaveVideo();
      } else {
        this.common.error(res.message);
        this.common.hideSpinner();
      }
    })
  }

  async profilePic(e: any) {
    const file = e.target.files[0];
    if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg') {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.src = event.target.result;
      };
      reader.readAsDataURL(e.target.files[0]);
      this.File = file;
    } else {
      this.common.error('Selected file is not image.');
    }
  }

  gotoUserProfile(data: any) {
    this.router.navigate(['/other_user_profile', data.id])
  }

  notificationToggle(event: any) {
    var fd = new FormData();
    this.common.showSpinner()
    if (this.notification_status === true) {
      fd.append('status', '1');
    } else {
      fd.append('status', '0');
    }
    fd.append('token', this.userToken)
    this.service.updateNotificationStatus(fd).subscribe((res: any) => {
      this.common.hideSpinner()
      if (res['success'] == 1) {
        this.common.success(res.message);
        this.getProfile();
      } else {
        this.common.error(res.message);
      }
    })
  }
}