<section class="login-block">
  <!-- login start -->
  <!-- left side start -->
  <div class="left-side-bg">
    <img src="assets/images/bottoms-up-text.svg">
  </div>
  <!-- right side start -->
  <div class="right-side-block forgot my-3">
    <div class="welcome-block">
      <h1>Forgot Password </h1>
      <p>Enter the email address associated with your account.</p>
      <!-- form start -->
      <form class="register-block" #forgotpassword="ngForm" (ngSubmit)="forgotpassword.valid && Forgot(forgotpassword)">
        <div class="form-group floating mb-0">
          <input type="text" class="form-control floating" id="usr" name="email" ngModel #email="ngModel" required />
          <label for="usr">Email</label>
        </div>
        <div class="col-lg-12 text-center">
        <button class="btn account-but my-3" type="submit" href="javascript:;" role="button">Submit</button>
      </div>
      </form>
      <!-- form close-->
      <p>Don't have an account? <span><a class="primary-blue-cl" routerLink="/login">Sign In</a></span></p>
    </div>
  </div><!-- right side close -->
</section>