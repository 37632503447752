import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { Router } from '@angular/router';
import { SocketService } from 'src/app/services/socketService/socket.service';
declare var $: any;
@Component({
  selector: 'app-groupchat',
  templateUrl: './groupchat.component.html',
  styleUrls: ['./groupchat.component.scss']
})
export class GroupchatComponent implements OnInit {
  searchText: string = '';
  groupList: any = [];
  userinfo: any;
  currentGroupData: any;
  groupname: any;
  groupData: any;
  list: any = [];
  imageUrl: string;
  constructor(
    private service: ApiService,
    private common: CommonService,
    private socket: SocketService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getAllistGroup()
  }

  getAllistGroup() {
    this.service.getallGroup('').subscribe((res: any) => {
      if (res['success'] === 1) {
        this.groupList = res['data'];
      }
    })
  }

  gtotogroupChat(data: any) {
    this.userinfo = JSON.parse(localStorage.getItem('Bottom_up_user') || '{}');
    this.currentGroupData = data;
    this.groupname = this.currentGroupData.group_name
    var fd = new FormData();
    fd.append('groupId', data.group_id)
    let data1 = {
      'token': this.userinfo.token,
      'groupID': data.group_id,
      'userID': this.userinfo.id,
      'chatType': 'GroupChat'
    }
    this.socket.openWebSocket(data1);
    this.service.getchatofParticularGroup(fd).subscribe((res: any) => {
      if (res['success'] === 1) {
        this.groupData = res['data']
        this.common.hideSpinner()
      } else {
        this.groupData = []
        this.common.hideSpinner()
      }
    })
  }

  sendNotification() {
    if (this.list && this.list.length == 0) {
      return this.common.error('Please select atleast one member')
    }
    if (this.list && this.list.length > 8)
      return this.common.error('only 8 Friends  can join call at a time')
    var fd = new FormData();
    fd.append('user_id', this.list)
    this.service.sendcallNotifi(fd).subscribe((res: any) => {
      if (res['success'] === 1) {
        this.common.success(res.message)
        if (localStorage.getItem('groupId')) {
          // this.groupId = localStorage.getItem('groupId')
        }
        var fd = new FormData();
        fd.append('channelID', this.currentGroupData.group_id)
        this.service.getRtcToken(fd).subscribe((res: any) => {
          if (res['success'] == 1) {
            this.common.success(res.message)
            localStorage.setItem("rtcToken", res.token);
            $('#make-group-call').modal('hide')
            this.router.navigate(['/group_call', this.currentGroupData.group_id, res.token]);
          }
        })
      }

      else { this.common.error(res.message) }
    })
  }
}
