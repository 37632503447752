import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-set-alcohol-level',
  templateUrl: './set-alcohol-level.component.html',
  styleUrls: ['./set-alcohol-level.component.scss']
})
export class SetAlcoholLevelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  setlevel(){
    
  }
}
