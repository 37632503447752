import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './Pages/login/login.component';
import { SignUpComponent } from './Pages/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './Pages/forgot-password/forgot-password.component';
import { WelcomeComponent } from './Pages/welcome/welcome.component';
import { OtpComponent } from './Pages/otp/otp.component';
import { ProfileComponent } from './Pages/profile/profile.component';
import { SearchGroupComponent } from './Pages/search-group/search-group.component';
import { AddFriendComponent } from './Pages/add-friend/add-friend.component';
import { ChatComponent } from './Pages/chat/chat.component';
import { StartUpHomeComponent } from './Pages/start-up-home/start-up-home.component';
import { ResetPasswordComponent } from './Pages/reset-password/reset-password.component';
import { LiveUsersComponent } from './Pages/live-users/live-users.component';
import { OtherUserLiveComponent } from './Pages/other-user-live/other-user-live.component';
import { DonateComponent } from './Pages/donate/donate.component';
import { OtherUserProfileComponent } from './Pages/other-user-profile/other-user-profile.component';
import { MyProfileComponent } from './Pages/my-profile/my-profile.component';
import { MyFriendListComponent } from './Pages/my-friend-list/my-friend-list.component';
import { VideoCallComponent } from './Pages/video-call/video-call.component';
import { ContactListComponent } from './Pages/contact-list/contact-list.component';
import { NewChatComponent } from './Pages/new-chat/new-chat.component';
import { AuthenticationGuard } from './guard/authentication.guard';
import { ReportComponent } from './Pages/report/report.component';
import { BlockUsersComponent } from './Pages/block-users/block-users.component';
import { GroupCallComponent } from './Pages/group-call/group-call.component';
import { HomeComponent } from './Pages/home/home.component';
import { DisableBackGuard } from './guard/disable-back.guard';
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: WelcomeComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'sign_up',
    component: SignUpComponent,
  },
  {
    path: 'otp',
    component: OtpComponent,
  },
  {
    path: 'reset_password',
    component: ResetPasswordComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
  },
  {
    path: 'forgot_password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'home',
    // component: HomeComponent,
    loadChildren: () => import('./Pages/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'start_up_home',
    component: StartUpHomeComponent,
  },
  {
    path: 'search',
    component: SearchGroupComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'addFriend',
    component: AddFriendComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'chat',
    component: ChatComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'chat/:id',
    component: ChatComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'new_chat',
    component: NewChatComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'live_users',
    component: LiveUsersComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'other_user_live',
    component: OtherUserLiveComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'other_user_profile/:id',
    component: OtherUserProfileComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'donate',
    loadChildren: () => import('./Pages/donate/donate.module').then(m => m.DonateModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'myProfile',
    component: MyProfileComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'myFriendList',
    component: MyFriendListComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'donate_trans',
    loadChildren: () => import('./Pages/donate-trans/donate-trans.module').then(m => m.DonateTransModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'wallet',
    loadChildren: () => import('./Pages/my-wallet/my-wallet.module').then(m => m.MyWalletModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'contact_us',
    loadChildren: () => import('./Pages/contact-us/contact-us.module').then(m => m.ContactUsModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'privacy',
    loadChildren: () => import('./Pages/privacy/privacy.module').then(m => m.PrivacyModule)
    // canActivate:[AuthenticationGuard]
  },
  {
    path: 'terms_condition',
    loadChildren: () => import('./Pages/terms-condition/terms-condition.module').then(m => m.TermsConditionModule)
  },
  {
    path: 'video_call',
    component: VideoCallComponent,
    // canActivate: [AuthenticationGuard]
  },
  {
    path: 'contact_list',
    component: ContactListComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'report/:id',
    component: ReportComponent,
  },
  {
    path: 'blockusers',
    component: BlockUsersComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'stream',
    component: GroupCallComponent,
    canActivate: [AuthenticationGuard],
    canDeactivate: [DisableBackGuard],
  },
  {
    path: 'join_group_call',
    loadChildren: () => import('./Pages/group-call/join-group-call/join-group-call.module').then(m => m.JoinGroupCallModule),
    canActivate: [AuthenticationGuard]
  },

  {
    path: 'joincall',
    loadChildren: () => import('./Pages/video-call/join-call/join-call.module').then(m => m.JoinCallModule)
  },
  {
    path: 'call',
    loadChildren: () => import('./Pages/video-call/join-one-call/join-one-call.module').then(m => m.JoinOneCallModule)
  },
  {
    path: 'withdraw',
    loadChildren: () => import('./Pages/withdraw/withdraw.module').then(m => m.WithdrawModule)
  },
  {
    path: '**',
    component: HomeComponent
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
