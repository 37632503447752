<section class="login-block">
  <!-- login start -->
  <!-- left side start -->
  <div class="left-side-bg">
    <img src="assets/images/bottoms-up-text.svg">
  </div> <!-- left side close -->
  <!-- right side start -->
  <div class="right-side-block sign-up my-3">
    <div class="welcome-block">
      <h1>Sign Up</h1>
      <p>Connect with friends and the world around you on Bottoms Up.</p>
      <!-- form start -->
      <form class="register-block" #signUp="ngForm" (ngSubmit)="signup(signUp)">
        <div class="form-group floating mb-0">
          <!-- <div class="loginmail_input" *ngIf="countryCodeList && countryCodeList.length>0">
            <ngx-select-dropdown (change)="selectionChanged($event)" name="countryCode" ngModel #countryCode="ngModel"
              [config]="config" [options]="countryCodeList">
            </ngx-select-dropdown>
            
            </div> -->
          <!-- <select name="countryCode" ngModel #countryCode="ngModel" >
            <option value="">Select Country Code</option>
            <option *ngFor="let item of countryCodeList; let i = index" value="{{item.dial_code}}">{{item.dial_code}}</option>
          </select> -->
          <input type="text" class="form-control floating" id="usr" name="email" ngModel #email="ngModel" required />
          <label for="usr">Email</label>
        </div>
        <div *ngIf="signUp.submitted && email.invalid ">
          <div class="alert alert-danger" *ngIf="email.errors?.required">
            Email is required
          </div>
        </div>
        <div class="form-group floating mb-0 position-relative">
          <input type="password" class="form-control floating" id="pass_log_id"  [ngClass]="{'valid_top': passwordvalid}"   name="password" ngModel #password="ngModel" (keyup)="checkvalidation($event)"    pattern="((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})"
          required/>
          <div class="eye-icon">
            <span toggle="#password-field" (click)="toggle('hide1')"
              class="fa fa-fw fa-eye field_icon toggle-password"></span>
          </div>
          <label for="pass_log_id">Password</label>
        </div>
        <div *ngIf="signUp.submitted && password.invalid ">
          <div class="alert alert-danger" *ngIf="password.errors?.required">
            Password is required
          </div>
          <!-- <div class="alert alert-danger custom_alert" *ngIf="password.errors?.pattern">
            Password must contain a capital letter, a sepcial character and a digit. Password length must  8 characters.
        </div> -->
        </div>
        <div class="form-group floating mb-0 position-relative">
          <input type="password" id="pass_log" class="form-control floating" (keyup)="checkvalidation1($event)" [ngClass]="{'valid_top': confirmvalid}" name="confirmpassword" ngModel
            pattern="{{ password.value }}" #confirmpassword="ngModel" required>
          <div class="eye-icon-change">
            <span toggle="#password-field" (click)="toggle1('hide1')"
              class="fa fa-fw fa-eye field_icon toggle-password1"></span>
          </div>
          <label for="usr">Confirm Password</label>
        </div>
        <div *ngIf=" confirmpassword.invalid && (signUp.submitted || confirmpassword.touched)"
          class="alert alert-danger">
          <div *ngIf="confirmpassword.errors?.required"> Confirm password is required. </div>
          <div *ngIf="confirmpassword.errors?.pattern"> Password & Confirm Password does not match.</div>
        </div>
        <div class="form-group form-check mb-0">
          <input type="checkbox" class="form-check-input" name="checkbox" ngModel #checkbox="ngModel"
            id="exampleCheck1">
          <label class="form-check-label" for="exampleCheck1">By signing up you're agreeing to our <span><a
                href="javascript:;" routerLink="/privacy">Privacy Policy</a></span> &
            <span><a href="javascript:;" routerLink="/terms_condition">Terms and Conditions.</a></span></label>
        </div>
        <div class="connect-block mt-3">
          <img src="assets/images/error-message.svg">
          Do not Over Drink, It's casual drinking apps to connect
          friends and followers. Follow local laws.”
        </div>
        <div class="text-center col-lg-12">
          <button [disabled]="!signUp.valid" class="btn account-but my-3" type="submit" role="button">Sign Up</button></div>
      </form>
      <!-- form close-->
      <!-- sign up and sign in start -->
      <p>Already have an account? <span><a class="primary-blue-cl" (click)="gotologin()">Sign In</a></span></p>
      <!-- sign up and sign in close -->
    </div>
  </div> <!-- right side close -->
</section>