import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api-service/api.service";
import { CommonService } from "src/app/services/common/common.service";
import { FacebookService, UIParams, UIResponse } from "ngx-facebook";
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  SocialAuthService,
} from "angularx-social-login";
import { Router } from "@angular/router";
import { FormBuilder } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { SocketService } from '../../../services/socketService/socket.service';
declare var $: any;
@Component({
  selector: "app-add-friend-popup",
  templateUrl: "./add-friend-popup.component.html",
  styleUrls: ["./add-friend-popup.component.scss"],
})
export class AddFriendPopupComponent implements OnInit {
  imageUrl: string;
  friendList: any = [];
  list: any = [];
  searchall: any;
  profileLink: any;
  groupId: any;
  userinfo: any;
  searchForm: any;
  profileData: any;
  constructor(
    private service: ApiService,
    private router: Router,
    private common: CommonService,
    private socket: SocketService,
    private fbb: FacebookService,
    private authService: SocialAuthService,
    public fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      keywords: [""],
    });
    this.imageUrl = this.service.imageUrl;
    this.getFriendList();
    this.getProfile();
    this.userinfo = JSON.parse(localStorage.getItem("Bottom_up_user") || "{}");
    this.searchForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe((data: any) => {
        this.searchFriendList(data);
      });
  }

  getFriendList() {
    this.service.getFriendList("").subscribe((res: any) => {
      this.common.hideSpinner();
      if (res["success"] === 1) {
        this.friendList = res["data"];
      }
    });
  }

  getProfile() {
    this.service.getProfile("").subscribe((res: any) => {
      this.common.hideSpinner();
      if (res["success"] === 1) {
        this.profileLink = res.profile_url;
        this.profileData = res.data
      }
    });
  }

  invitefriend(list: any) {
    var fd = new FormData();
    fd.append("friendId", list.id);
    fd.append("request", "1");
    this.service.addRemoveFriend(fd).subscribe((res: any) => {
      if (res["success"]) {
        this.common.success(res.message);
        this.common.hideSpinner();
      }
    });
  }

  JoinCall(e: any, user: any, i: any) {
    if (e.target.checked == false) {
      const index = this.list.indexOf(user.id.toString());
      if (index > -1) this.list.splice(index, 1);
    }
    if (e.target.checked == true) {
      if (this.list.indexOf(user.id.toString()) < 0) this.list.push(user.id);
    }
  }

  searchFriendList(text: any) {
    var fd = new FormData();
    if (text.keywords == "" || text.keywords == undefined) {
      this.getFriendList();
    }
    fd.append("term", text.keywords);
    this.service.SearchFriendfromList(fd).subscribe((res: any) => {
      if (res["success"] === 1) {
        this.friendList = res["data"];
        this.common.hideSpinner();
      } else {
        this.common.hideSpinner();
      }
    });
  }

  sendNotification() {
    $("#make-group-call").modal("hide");
   this.socket.inviteGroupCall('', this.list, true);
  }
  copyToClipBoard(){
    
    const el = document.createElement('div');
    var shareProfileUrl = 'https://bottom.harishparas.com/other_user_profile/'+this.profileData.id
    el.innerHTML = shareProfileUrl;
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const sel:any = getSelection();
    sel.setBaseAndExtent(el, 0, el, el.childNodes.length);
    document.execCommand('copy');
    document.body.removeChild(el);
    this.common.success('profile link copied');



  }

  sharefacebookUrl() {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(res => console.log("xxxxx", res));
    var url = 'https';
    this.fbb.api('/me/friends')
      .then((res: any) => {
        console.log('Got the users friends', res);
      })
  }

  sendInvite() {
    if (this.common.isVideocallInprogress == true) {
      return this.common.error("You are already on another call");
    }
    $("#invite-sent").modal("show");
  }
}
