import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { ApiService } from 'src/app/services/api-service/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SubjectService } from 'src/app/services/subject.service';
import { SocketService } from 'src/app/services/socketService/socket.service';
import { NgxAgoraService, AgoraClient, Stream, ClientEvent, StreamEvent } from 'ngx-agora';
import { SetAlcoholLevelDialog } from 'src/app/_helper/dialogs/dialog-references';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.scss']
})
export class VideoCallComponent implements OnInit {
  recieverId: any;
  uniqueId: number;
  localCallId = 'agora_local';
  private client: AgoraClient;
  private localStream: Stream;
  public remoteStream: any[] = [];
  rtcToken: any;
  isAudioEnabled: boolean = true;
  isVideoEnabled: boolean = true;
  isPublic: boolean = false;
  userinfo: any;
  timoutTimer:number = 20;
  recieverName:any;
  constructor(
    private common: CommonService,
    private service: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public subject: SubjectService,
    public socket: SocketService,
    private agoraService: NgxAgoraService,
    private modalService: NgbModal,
  ) {
    this.activatedRoute.params.subscribe((res) => {
      
      this.recieverId = res.recieverId;
      this.recieverName = res.name;
      console.log(this.localStream);
      this.initCall();
      this.userinfo = JSON.parse(localStorage.getItem('Bottom_up_user') || '{}');
      this.isPublic = (this.userinfo.is_lock === '0') ? false : true;
      console.log(this.client.getConnectionState());
    })
  }

  ngOnInit(): void {
    this.showAlert()
    // const interval = setInterval(()=>{
    //   if(this.timoutTimer > 0){
    //     this.timoutTimer = this.timoutTimer-1;
    //     console.log(this.timoutTimer);
    //   }else{
    //     clearInterval(interval);
    //     this.leave();
    //   }
    // },1000)
  }

  initCall() {
    let that = this;
    this.client = this.agoraService.createClient({
      mode: "rtc",
      codec: "h264",
    });
      that.assignClientHandlers();
      that.getToken();
  }

  getToken() {
    let data1 = {
      "user_id": this.recieverId,
      "type": 'single'
    }
    this.service.sendnotificationReq(data1).subscribe((res: any) => {
      
      this.common.hideSpinner();
      if(res['success'] == 1){
        this.uniqueId = Math.floor(Math.random() * 100);
        // Join a channel
        var fd = new FormData();
        fd.append('channelID', this.userinfo.ChatRoomName);
        this.service.getRtcToken(fd).subscribe((res: any) => {
          if (res['success'] == 1) {
            this.rtcToken = res.token;
            // Added in this step to initialize the local A/V stream
            this.localStream = this.agoraService.createStream({ streamID: this.uniqueId, audio: true, video: true, screen: false });
            // The user has granted access to the camera and mic.
            this.localStream.on(StreamEvent.MediaAccessAllowed, () => {
             
            });
            // The user has denied access to the camera and mic.
            this.localStream.on(StreamEvent.MediaAccessDenied, () => {
              console.log('accessDenied');
            });
            this.initLocalStream(() => this.join(uid => this.publish(), error => console.error(error)));
          }
        })
      }else{
        this.common.messageToast(res['message']);
        let data = JSON.parse(sessionStorage.getItem('videoCallData') || '{}');
        this.joinVideoCall(data);
      }
    })
    
  }

  private assignClientHandlers(): void {
    this.client.on(ClientEvent.LocalStreamPublished, evt => {
      console.log('Publish local stream successfully');
    });

    this.client.on(ClientEvent.Error, error => {
      console.log('Got error msg:', error.reason);
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        this.client.renewChannelKey(
          '',
          () => console.log('Renewed the channel key successfully.'),
          renewError => console.error('Renew channel key failed: ', renewError)
        );
      }
    });
    this.client.on(ClientEvent.RemoteStreamAdded, evt => {
      console.log('remote stream added line 110')
      const stream = evt.stream as Stream;
      this.client.subscribe(stream, { audio: true, video: true }, err => {
        console.log('Subscribe stream failed', err);
      });
      this.common.hideSpinner();
      this.common.isVideocallInprogress = true;
    });

    this.client.on(ClientEvent.RemoteStreamSubscribed, evt => {
      console.log('remote stream subscribe line 118');
      const stream = evt.stream as Stream;
      const id = this.getRemoteId(stream);
      if (!this.remoteStream.length) {
        this.remoteStream.push({ id: id, stream: stream });
        setTimeout(() => stream.play(id), 1000);
      }
      this.common.hideSpinner();
    });

    this.client.on(ClientEvent.RemoteStreamRemoved, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteStream = [];
        console.log(`Remote stream is removed ${stream.getId()}`);
        if (this.remoteStream.length < 1) {
          this.leave();
        }
      }
    });

    this.client.on(ClientEvent.PeerLeave, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteStream = this.remoteStream.filter(call => call.id !== `${this.getRemoteId(stream)}`);
        console.log(`${evt.uid} left from this channel`);
        if (this.remoteStream.length < 2) {
          this.leave();
        }
      }
    });
  }

  private getRemoteId(stream: Stream): string {
    return `agora_remote-${stream.getId()}`;
  }

  private initLocalStream(onSuccess?: () => any): void {
    this.localStream.init(() => {
      // The user has granted access to the camera and mic.
      this.localStream.play(this.localCallId);
      if (onSuccess) {
        onSuccess();
      }
    }, err => console.error('getUserMedia failed', err)
    );
  }

  /**
   * Attempts to connect to an online chat room where users can host and receive A/V streams.
   */
  join(onSuccess?: (uid: number | string) => void, onFailure?: (error: Error) => void): void {
    let data = {
      rtcToken:this.rtcToken,
      ChatRoomName: this.userinfo.ChatRoomName,
      uniqueId: this.uniqueId
    }
    sessionStorage.setItem('videoCallData',JSON.stringify(data));
    this.client.join(this.rtcToken, this.userinfo.ChatRoomName, this.uniqueId, onSuccess, onFailure);
  }

  /**
   * Attempts to upload the created local A/V stream to a joined chat room.
   */
  publish(): void {
    this.client.publish(this.localStream, err => console.log('Publish local stream error: ' + err));
  }

  async leave() {
    this.localStream.stop(); // stop the stream
    this.localStream.close();
    this.remoteStream = [];
    var fd = new FormData();
    fd.append('calltype', 'single');
    fd.append('receiverId', this.recieverId);
    this.service.endCall(fd).subscribe((res: any) => {
      sessionStorage.removeItem('videoCallData');
      this.common.hideSpinner();
    });
    this.common.isVideocallInprogress = false;
    this.router.navigate(['/home']);
  }

  muteUnmuteAudio() {
    this.isAudioEnabled = !this.isAudioEnabled;
    console.log('muteUnmuteAudio', this.isAudioEnabled)
    if (this.localStream.isAudioOn()) {
      this.localStream.muteAudio();
    } else {
      this.localStream.unmuteAudio();
    }
  }

  enableDisableCamera() {
    this.isVideoEnabled = !this.isVideoEnabled;
    console.log('enableDisableVideo', this.isVideoEnabled)
    if (this.localStream.isVideoOn()) {
      this.localStream.disableVideo();
    } else {
      this.localStream.enableVideo();
    }
  }

  lockstatus() {
    var fd = new FormData()
    this.isPublic = !this.isPublic;
    fd.append('status', this.isPublic ? '1' : '0')
    this.service.lockStatus(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.hideSpinner();
        localStorage.setItem("Bottom_up_user", JSON.stringify(res.data));
        this.common.success(res.message);
      } else {
        this.common.hideSpinner();
        this.common.error(res.message);
      }
    })
  }

  setAlcoholLevel() {
    this.modalService.open(SetAlcoholLevelDialog, {
      size: 'sm',
      ariaLabelledBy: 'modal-basic-title',
      // backdrop: 'static' 
    });
  }

  showRemoteControlButtons: boolean = false;
  isRemoteAudioEnabled: boolean = true;
  remoteControlStream:any;
  showRange:boolean = false;
  
  showVolumeRange(){
    this.showRange = !this.showRange;
  }

  showRemoteVideoControls(callId: any) {
    console.log(callId);
    this.showRemoteControlButtons = !this.showRemoteControlButtons;
    this.remoteControlStream = callId;
  }

  muteUnmuteRemote(){
    this.isRemoteAudioEnabled = !this.isRemoteAudioEnabled;
    console.log('muteUnmuteAudio', this.isRemoteAudioEnabled)
    if (this.remoteControlStream.isAudioOn()) {
      this.remoteControlStream.muteAudio();
    } else {
      this.remoteControlStream.unmuteAudio();
    }
  }

  rangeSelect(event:any){
    console.log(event.target.value);
    this.localStream.adjustAudioMixingVolume(event.target.value);
  }

  showAlert() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showCancelButton: false,
      showCloseButton: false,
      showConfirmButton: false,
      timer: 20000
    });
    Toast.fire({
      title: `Calling `+ this.recieverName
    }).then((result: any) => {
      console.log(result);
      if (result.isConfirmed == true) {
        
      }
    })
  }

  joinVideoCall(data:any){
    console.log(data);
    this.rtcToken = data.rtcToken;
    this.uniqueId = Math.floor(Math.random() * 100);
    // Join a channel
    this.localStream = this.agoraService.createStream({ streamID: this.uniqueId, audio: true, video: true, screen: false });
    // The user has granted access to the camera and mic.
    this.localStream.on(StreamEvent.MediaAccessAllowed, () => {
     
    });
    // The user has denied access to the camera and mic.
    this.localStream.on(StreamEvent.MediaAccessDenied, () => {
      console.log('accessDenied');
    });
    this.initLocalStream(() => this.join(uid => this.publish(), error => console.error(error)));

  }
}