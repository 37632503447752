import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { Router } from '@angular/router';
import { SocketService } from '../../services/socketService/socket.service';
import { SubjectService } from '../../services/subject.service';
import { environment } from 'src/environments/environment';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
declare var $: any;
@Component({
  selector: 'app-search-group',
  templateUrl: './search-group.component.html',
  styleUrls: ['./search-group.component.scss']
})
export class SearchGroupComponent implements OnInit {
  OnlineFriendList: any = [];
  imageUrl: string;
  search: any;
  connected = false;
  groupmsg: boolean = false;
  searchall: any
  getallUsers: any;
  friendList: any = [];
  mutualFriend: any;
  GroupList: any;
  mutualData: any = [];
  mutualCount: any;
  sessionId: string;
  participantId: string;
  wsUrl: string;
  showOpenvidu = false;
  sessionClosed = false;
  uid: number;
  remoteCalls: string[] = [];
  published = false;
  grouplistmember: any;
  groupListData: any = [];
  groupname: any;
  channelId: any;
  recentActivites: any = [];
  listData: any = []
  chatList: any;
  chatData: any;
  currentGroupData: any
  groupList: any = [];
  islistShow: boolean = false;
  isShow: boolean = true;
  chatHistory: any;
  room: any
  status: any;
  otherUserId: any;
  pendingGroups: any = [];
  groupMembers: any = [];
  groups: any = []
  groupCallList: any[] = [];
  list: any = []
  groupId: any;
  members: any = [];
  showjoin: boolean = false
  isEdit: boolean = false;
  IssendNotification: boolean = false;
  userInfo: any;
  constructor(
    private service: ApiService,
    private router: Router,
    public common: CommonService,
    public socket: SocketService,
    public subject: SubjectService,
  ) {
    $(window).bind('beforeunload', function () {
      return window.confirm("You'll go out of the call!");
    });
    if (localStorage.getItem('Bottom_up_user')) {
      this.userInfo = JSON.parse(localStorage.getItem('Bottom_up_user') || '{}')
    }
    let data = {
      'token': this.userInfo.token,
      'userID': this.userInfo.id,
    }
    this.socket.openWebSocket(data);
    setTimeout(() => {
      this.getNewMessage();
    }, 2000)
    this.getRecentActivites();
    this.socket.getRunningGroupCalls.pipe(debounceTime(1000),distinctUntilChanged())
    .subscribe(response => {
      this.groupCallList = response;
    })
  }

  ngOnInit(): void {
    window.onbeforeunload = function () {
      return null;  // return null to avoid pop up
    }
    this.userInfo = JSON.parse(localStorage.getItem('Bottom_up_user') || '{}')
    this.imageUrl = this.service.imageUrl;
    this.getMutualFriend();
    this.getNewMessage();
  }
  getNewMessage() {
    let data = {
      'user_id': this.userInfo.id,
      'serviceType': 'search_invitation'
    }
    this.socket.sendMessage(data)
  }

  joincall(data: any, isAppToWeb: Boolean) {
    this.common.showSpinner();
    let userinfo = JSON.parse(localStorage.getItem('Bottom_up_user') || '{}');
    let params: any = {};
    if (isAppToWeb) {
      params['serviceType'] = 'join_now';
      params['user_id'] = userinfo.id;
      params['joinUserID'] = data.id;
      this.socket.sendMessage(params);
      this.socket.isPublicCalljoins.next(true);
    } else {
      params['serviceType'] = 'join_now';
      params['user_id'] = userinfo.id;
      params['joinUserID'] = data.admin.id;
      this.socket.sendMessage(params);
    }
  }

  sendNotification(data: any) {
    this.groupId = data;
    var fd = new FormData
    fd.append('channelID', this.groupId)
    this.service.getRtcToken(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.hideSpinner();
        localStorage.setItem("rtcToken", res.token);
        this.router.navigate(['/call', this.groupId, res.token]);
      }
      else {
        this.common.hideSpinner();
      }
    })
  }

  getMutualFriend() {
    this.service.getMutualFriendList('').subscribe((res: any) => {
      if (res['success'] === 1) {
        this.mutualData = []
        this.mutualFriend = res['data'];
        this.mutualFriend.forEach((element: any) => {
          this.mutualCount = element.friend;
          this.mutualData.push(element.users)
          this.common.hideSpinner()
        });
      }
      else {
        this.common.hideSpinner()
      }
    })
  }

  addFriend(data: any) {
    var fd = new FormData;
    fd.append('friendId', data.id);
    fd.append('request', '1')
    this.service.addRemoveFriend(fd).subscribe((res: any) => {
      if (res["success"]) {
        this.common.success(res.message);
        this.common.hideSpinner();
        this.searchall = '';
        this.getMutualFriend();
      }
      else {
        this.common.hideSpinner();
      }
    })
  }

  // getGroup() {
  //   this.service.getGroupList('').subscribe((res: any) => {
  //     if (res['success'] === 1) {
  //       this.common.hideSpinner();
  //       this.GroupList = res['data'];
  //     }
  //   })
  // }

  Addtofriend(list: any) {
    var fd = new FormData;
    fd.append('friendId', list.id);
    fd.append('request', '1')
    this.service.addRemoveFriend(fd).subscribe((res: any) => {
      if (res["success"]) {
        this.common.success(res.message);
        this.searchall = '';
        this.common.hideSpinner();
        $('#find-friend').modal('hide');
      } else {
        this.common.error(res.message);
        this.common.hideSpinner()
      }
    })
  }

  searchFriendList(text: any) {
    var fd = new FormData;
    // var OnlineFriendserachList = [];
    // this.OnlineFriendList = []
    if (this.search == '' || this.search === undefined) {
      // this.getFriendList()
    }
    fd.append('term', this.search.trim().toLowerCase())
    this.service.SearchFriendfromList(fd).subscribe((res: any) => {
      if (res['success'] === 1) {
        this.common.hideSpinner()
        if (res.data.length == 0) {
          this.common.error('No data Found');
        }
        res['data'].forEach((element: any) => {
          this.common.hideSpinner()
          if (element.status === '1') {
            this.OnlineFriendList.push(element);
          }
        });
      } else {
        this.common.hideSpinner();
      }
    })
  }

  searchuser(data: any) {
    var fd = new FormData;
    fd.append('term', this.searchall.trim().toLowerCase())
    this.service.completeuserSearch(fd).subscribe((res: any) => {
      if (res['success'] === 1) {
        this.common.hideSpinner();
        this.getallUsers = res['data'];
        if (res.data.length == 0) {
          this.common.success('No user Found');
        }
        $('#find-friend').modal('show');
        this.common.hideSpinner();
      } else {
        this.common.hideSpinner();
      }
    })
  }


  gotouserChat(data: any) {
    var fd = new FormData;
    fd.append('reciever_id', data);
    this.service.getchatofParticularUser(fd).subscribe((res: any) => {
      if (res['success'] === 1) {
        this.router.navigate(['/chat', data]);
        this.islistShow = true;
        this.groupmsg = false;
        this.chatData = res;
        this.chatHistory = res['data']
        this.chatHistory.reverse();
        $('#add-new-chat').modal('hide')
        this.common.hideSpinner()
      } else {
        this.common.hideSpinner();
      }
    })
  }

  join(data: any, id: any) {
    console.log(data);
    let data1 = {
      "user_id": id,
      "type": 'single'
    }
    this.channelId = JSON.parse(localStorage.getItem('Bottom_up_user') || '{}')
    this.service.sendnotificationReq(data1).subscribe((res: any) => {
      if (res["success"]) {
        this.common.success(res.message);
        $('#find-friend').modal('hide')
        var fd = new FormData();
        fd.append('channelID', this.channelId.ChatRoomName);
        this.service.getRtcToken(fd).subscribe((res: any) => {
          if (res['success'] == 1) {
            localStorage.setItem("rtcToken", res.token);
            this.common.hideSpinner();
            this.router.navigate(['/video_call', this.channelId.ChatRoomName, res.token, id]);
          } else {
            this.common.hideSpinner();
            this.common.error(res.message)
          }
        })
      } else {
        this.common.hideSpinner();
        this.common.error(res.message)
      }
    })
  }

  leave(): void {
    // if (this.connected) {
    //   this.client.leave(() => {
    //     this.connected = false;
    //     this.published = false;
    //     this.remoteCalls = [];
    //   }, err => {
    //     console.log('Leave channel failed');
    //   }
    //   );
    // } else {
    //   // this.agoraService.AgoraRTC.Logger.warning('Local client is not connected to channel.');
    // }
  }
  remove(data: any) {
    var fd = new FormData()
    fd.append('mutualId', data.id)
    this.service.removeMutualFriend(fd).subscribe((res: any) => {
      if (res['success'] === 1) {
        this.common.hideSpinner();
        this.getMutualFriend()
      }
      else { this.common.hideSpinner() }
    })
  }

  editgroup(data: any) {
    
    this.groupname = data
    this.isEdit = true;
  }

  requestCancel(data: any) {
    
    var fd = new FormData
    fd.append('friendId', data.id)
    this.service.CancelRequest(fd).subscribe((res: any) => {
      if (res['success'] === 1) {
        this.common.success(res.message)
        this.searchall = ''
        this.common.hideSpinner();
        this.getMutualFriend();
        $('#find-friend').modal('hide')
      }
      else { this.common.hideSpinner() }
    })
  }

  updateGroup(data: any, id: any) {
    console.log(this.groupname, data, "name")
    var fd = new FormData
    fd.append('name', this.groupname)
    fd.append('group_img', '')
    fd.append('privacy', data)
    fd.append('group', id)
    this.service.updateGroup(fd).subscribe((res: any) => {
      if (res['success'] === 1) {
        this.common.success(res.message)
        this.common.hideSpinner();
        this.groupname = ''
        this.getNewMessage();
        this.isEdit = false;
      }
      else { this.common.hideSpinner() }
    })
  }

  getRecentActivites() {
    this.service.recentActivites('').subscribe((res: any) => {
      if (res['success'] === 1) {
        this.recentActivites = res['data']
        this.common.hideSpinner();
        this.getMutualFriend()
      }
      else { this.common.hideSpinner() }
    })
  }

  cancel() { this.searchall = '' }

  async connectCall(data: any) {
    let userIds: any[] = [];
    data.members.forEach((element: any) => {
      userIds.push(element.member_id)
    });
    this.socket.inviteGroupCall(data.group_id, userIds , false);
  }

  convertTime(createdDate: any) {
    let datetime = new Date(createdDate + " UTC");
    return datetime.toString();
  }
  redirectChat(data: any) {
    if (data.members.length) {
      if (data.members.length > 1) {
        this.router.navigate(['chat'], {
          queryParams: {
            id: data.group_id, tab: 2
          }
        })
      } else {
        const params = {
          user: this.common.encryptUserData(data.members),
          tab: 1
        }
        this.router.navigate(['chat'], {
          queryParams: params
        });
        // this.router.navigate(['chat'], {
        //   queryParams: {
        //     id: data.members[0].member_id, tab: 1
        //   }
        // })
      }
    }
  }
}


