import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ChatTogglerService {
  private isChatShown: BehaviorSubject<boolean>;
  constructor() {
    this.isChatShown = new BehaviorSubject<boolean>(false);
  }

  setChatVisibility(ChatVisibility: boolean): void {
    this.isChatShown.next(ChatVisibility);
  }

  getChatVisibility(): Observable<boolean> {
    return this.isChatShown.asObservable();
  }
}
