<app-header></app-header>
<section class="add-friend-content">
    <div class="container">
      <div class="row">
        <!---invite-friend-sec--->
        <div class="col-md-6">
          <div class="invite-friend-sec common-card-box">
            <div class="friend-list">
              <!--Common-Blue-Heading-->
              <div class="common-blue-hd mb-3">
                <h5 class="primary-blue-cl mb-0 p-3">Your Friend List</h5>
                <hr class="m-0">
              </div>
              <!--List Start-->
              <div class="adding-list p-3">
                <div class="d-flex justify-content-between mb-3">
                  <div class="friend-info d-flex justify-content-between">
                      <span class="mr-3"><img src="assets/images/your-frind-img.svg" alt="image" /></span>
                      <div class="friend-name align-self-center">                      
                        <h6 class="f-18 mb-1">Lee Brown</h6>
                        <p class="f-14 mb-0">+91-9878767876</p>
                      </div>
                  </div>
                  <div class="invite-btns align-self-center">
                    <label class="yellow-cl">Added</label>
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <div class="friend-info d-flex justify-content-between">
                      <span class="mr-3"><img src="assets/images/your-frind-img.svg" alt="image" /></span>
                      <div class="friend-name align-self-center">                      
                        <h6 class="f-18 mb-1">Lee Brown</h6>
                        <p class="f-14 mb-0">+91-9878767876</p>
                      </div>
                  </div>
                  <div class="invite-btns align-self-center">
                    <button href="#" class="small-btn primary-blue-bg text-white f-14">Add</button>
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <div class="friend-info d-flex justify-content-between">
                      <span class="mr-3"><img src="assets/images/your-frind-img.svg" alt="image" /></span>
                      <div class="friend-name align-self-center">                      
                        <h6 class="f-18 mb-1">Lee Brown</h6>
                        <p class="f-14 mb-0">+91-9878767876</p>
                      </div>
                  </div>
                  <div class="invite-btns align-self-center">
                    <button href="#" class="small-btn primary-blue-bg text-white f-14">Add</button>
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <div class="friend-info d-flex justify-content-between">
                      <span class="mr-3"><img src="assets/images/your-frind-img.svg" alt="image" /></span>
                      <div class="friend-name align-self-center">                      
                        <h6 class="f-18 mb-1">Lee Brown</h6>
                        <p class="f-14 mb-0">+91-9878767876</p>
                      </div>
                  </div>
                  <div class="invite-btns align-self-center">
                    <button href="#" class="small-btn primary-blue-bg text-white f-14">Add</button>
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <div class="friend-info d-flex justify-content-between">
                      <span class="mr-3"><img src="assets/images/your-frind-img.svg" alt="image" /></span>
                      <div class="friend-name align-self-center">                      
                        <h6 class="f-18 mb-1">Lee Brown</h6>
                        <p class="f-14 mb-0">+91-9878767876</p>
                      </div>
                  </div>
                  <div class="invite-btns align-self-center">
                    <button href="#" class="small-btn primary-blue-bg text-white f-14">Add</button>
                  </div>
                </div>
              </div>
              <!--List End-->
            </div>
          </div>
        </div>
        <!---invite-friend-sec--->
  
        <!---contact-list-sec--->
        <div class="col-md-6">
          <div class="contact-listing common-card-box">
              <!--Common-Blue-Heading-->
              <div class="common-blue-hd mb-3">
                <h5 class="primary-blue-cl mb-0 p-3">Contact List</h5>
                <hr class="m-0">
              </div>
              <!--List Start-->
              <div class="contact-listing-user p-3">
                <div class="d-flex justify-content-between mb-3">
                  <div class="friend-info d-flex justify-content-between">
                      <span class="mr-3"><img src="assets/images/your-frind-img.svg" alt="image" /></span>
                      <div class="friend-name align-self-center">                      
                        <h6 class="f-18 mb-1">Lee Brown</h6>
                        <p class="f-14 mb-0">+91-9878767876</p>
                      </div>
                  </div>
                  <div class="invite-btns align-self-center">
                    <label class="yellow-cl">Invite Sent</label>
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <div class="friend-info d-flex justify-content-between">
                      <span class="mr-3"><img src="assets/images/your-frind-img.svg" alt="image" /></span>
                      <div class="friend-name align-self-center">                      
                        <h6 class="f-18 mb-1">Lee Brown</h6>
                        <p class="f-14 mb-0">+91-9878767876</p>
                      </div>
                  </div>
                  <div class="invite-btns align-self-center">
                    <button href="#" class="small-btn primary-blue-bg text-white f-14">Invite</button>
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <div class="friend-info d-flex justify-content-between">
                      <span class="mr-3"><img src="assets/images/your-frind-img.svg" alt="image" /></span>
                      <div class="friend-name align-self-center">                      
                        <h6 class="f-18 mb-1">Lee Brown</h6>
                        <p class="f-14 mb-0">+91-9878767876</p>
                      </div>
                  </div>
                  <div class="invite-btns align-self-center">
                    <button href="#" class="small-btn primary-blue-bg text-white f-14">Invite</button>
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <div class="friend-info d-flex justify-content-between">
                      <span class="mr-3"><img src="assets/images/your-frind-img.svg" alt="image" /></span>
                      <div class="friend-name align-self-center">                      
                        <h6 class="f-18 mb-1">Lee Brown</h6>
                        <p class="f-14 mb-0">+91-9878767876</p>
                      </div>
                  </div>
                  <div class="invite-btns align-self-center">
                    <button href="#" class="small-btn primary-blue-bg text-white f-14">Invite</button>
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <div class="friend-info d-flex justify-content-between">
                      <span class="mr-3"><img src="assets/images/your-frind-img.svg" alt="image" /></span>
                      <div class="friend-name align-self-center">                      
                        <h6 class="f-18 mb-1">Lee Brown</h6>
                        <p class="f-14 mb-0">+91-9878767876</p>
                      </div>
                  </div>
                  <div class="invite-btns align-self-center">
                    <button href="#" class="small-btn primary-blue-bg text-white f-14">Invite</button>
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <div class="friend-info d-flex justify-content-between">
                      <span class="mr-3"><img src="assets/images/your-frind-img.svg" alt="image" /></span>
                      <div class="friend-name align-self-center">                      
                        <h6 class="f-18 mb-1">Lee Brown</h6>
                        <p class="f-14 mb-0">+91-9878767876</p>
                      </div>
                  </div>
                  <div class="invite-btns align-self-center">
                    <button href="#" class="small-btn primary-blue-bg text-white f-14">Invite</button>
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <div class="friend-info d-flex justify-content-between">
                      <span class="mr-3"><img src="assets/images/your-frind-img.svg" alt="image" /></span>
                      <div class="friend-name align-self-center">                      
                        <h6 class="f-18 mb-1">Lee Brown</h6>
                        <p class="f-14 mb-0">+91-9878767876</p>
                      </div>
                  </div>
                  <div class="invite-btns align-self-center">
                    <button href="#" class="small-btn primary-blue-bg text-white f-14">Invite</button>
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <div class="friend-info d-flex justify-content-between">
                      <span class="mr-3"><img src="assets/images/your-frind-img.svg" alt="image" /></span>
                      <div class="friend-name align-self-center">                      
                        <h6 class="f-18 mb-1">Lee Brown</h6>
                        <p class="f-14 mb-0">+91-9878767876</p>
                      </div>
                  </div>
                  <div class="invite-btns align-self-center">
                    <button href="#" class="small-btn primary-blue-bg text-white f-14">Invite</button>
                  </div>
                </div>              
              </div>
              <!--List End-->
            
          </div>
        </div>
        <!---contact-list-sec--->
      </div>
    </div>
  </section>
  <!---add-friend-section--->
  
  <!---Add-Friend-Modal--->
  <div class="modal fade" id="add-friend">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title light-grey-cl">Add Friend</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body social-btns-sec text-center my-3">
          <a href class="social-btns d-inline-block p-3 primary-blue-bg text-white mb-3"><span class="mr-2 align-self-center"><img src="assets/images/contact-list-icon.svg" alt="icon" /></span>Add From Contact List</a>
          <a href class="social-btns d-inline-block p-3 cl-facebook text-white mb-3"><span class="mr-2 align-self-center"><img src="assets/images/facebook-icon.svg" alt="icon" /></span>Add From Facebook</a>
          <a href class="social-btns d-inline-block p-3 cl-snapchat text-white mb-3"><span class="mr-2 align-self-center"><img src="assets/images/snapchat-icon.svg" alt="icon" /></span>Add From Snapchat</a>
          <a href class="social-btns d-inline-block p-3 cl-black text-white"><span class="mr-2 align-self-center"><img src="assets/images/link-icon.svg" alt="icon" /></span>Copy Profile Link</a>
        </div>
      </div>
    </div>
  </div>
  
  <!---Add-Friend-Modal--->
  <div class="modal fade" id="invite-sent">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body invite-sent-msg text-center m-3">
          <img class="mb-3" src="assets/images/invite-sent-modal-img.svg" alt="image" />
          <h4>Invite snt to 3 friends</h4>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aucto.</p>
          <button type="button" class="small-btn primary-blue-bg text-white" data-dismiss="modal" aria-label="Close">OK</button> 
        </div>
      </div>
    </div>
  </div>