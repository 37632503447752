<div class="payment-method common-card-box p-4">
    <div class="common-blue-hd mb-3">
        <h5 class="primary-blue-cl mb-0">{{title}}</h5>
        <hr class="mb-0">
    </div>
    <p class="mb-4">Select active bank to get donation Amount</p>
    <div *ngFor="let card of cardList" class="bank-card p-3 d-flex mb-3">
        <div class="common-radio align-self-top">
            <input type="radio" id="test1" name="radio-group" (click)="selectBank(card)">
            <label for="test1"></label>
        </div>
        <div class="bank-des">
            <img src="assets/images/bank-img.svg" alt="image" />
            <div class="acc-detail ml-3 d-inline-block">
                <h5 class="mb-1">{{card.bank_name}}</h5>
                <p class="mb-0 light-grey-cl">{{card.card_number}}</p>
                <small class="f-12 m-0 green-cl">Active</small><br />
                <small class="f-12 m-0 set_primary" *ngIf="card.primary_status != 1" (click)="setDefaultAccount(card)">Set default</small>
                <small class="f-12 m-0 green-cl" *ngIf="card.primary_status == 1">Primary</small>
            </div>
        </div>
        <div class="d-inline-block ml-auto">
            <img (click)="deleteBank(card)" src="./assets/images/delete-icon.svg" alt="image" />
        </div>
    </div>
    <div class="bank-card p-3 d-flex justify-content-between" (click)="openModal()">
        <div class="add-bank">
            <h5 class="mb-1">Add Another Bank</h5>
            <p class="mb-0 light-grey-cl">Add Account to get donation amount.</p>
        </div>
        <img src="./assets/images/add-bank.svg" alt="icon" />
    </div>
    <!-- <div class="text-center" (click)="proceed()">
        <button class="primary-blue-bg text-white big-btn">Proceed</button>
    </div> -->
</div>

<!--add bank modal-->
<div class="modal fade" id="add-bank" aria-modal="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title light-grey-cl">Add Bank</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body add-bank-details my-3">
                <form class="add-bank-form" [formGroup]="bankForm" (ngSubmit)="bankForm.valid && addCard(bankForm)">
                    <div class="form-group floating mb-0">
                        <input type="text" class="form-control floating" formControlName="bank_name"/>
                        <label for="usr">Bank Name</label>
                        <ng-container *ngIf="!bankForm.controls.bank_name.valid && bankForm.controls.bank_name.dirty"
                        class="validator-error">
                        <span *ngIf="bankForm.controls.bank_name.hasError('required')" class="error">Bank name is
                            required</span>
                            <span *ngIf="bankForm.controls.bank_name.hasError('whitespace')" class="error">
                                Space not allowed</span>
                    </ng-container>
                    </div>
                    <div class="form-group floating mb-0">
                        <input type="text" class="form-control floating" formControlName="bank_holder_name"/>
                        <label for="usr">Bank Holder Name</label>
                        <ng-container *ngIf="!bankForm.controls.bank_holder_name.valid && bankForm.controls.bank_holder_name.dirty"
                        class="validator-error">
                        <span *ngIf="bankForm.controls.bank_holder_name.hasError('required')" class="error">Bank holder name is
                            required</span>
                            <span *ngIf="bankForm.controls.bank_holder_name.hasError('whitespaceName')" class="error">
                                Space not allowed</span>
                        </ng-container>
                    </div>
                    <div class="form-group floating mb-0">
                        <input type="number" min="0" class="form-control floating" minlength="10" maxlength="18" formControlName="account_number"/>
                        <label for="usr">Account No.</label>
                        <ng-container *ngIf="!bankForm.controls.account_number.valid && bankForm.controls.account_number.dirty"
                        class="validator-error">
                        <span *ngIf="bankForm.controls.account_number.hasError('required')" class="error">Account number is
                            required</span>
                            <span *ngIf="bankForm.controls.account_number.hasError('minlength')" class="error">Account number should be at least 10 digit</span>
                        </ng-container>
                    </div>
                    <div class="form-group floating mb-0">
                        <input type="number" min="0" class="form-control floating" formControlName="confirm_account_number"/>
                        <label for="usr">Confirm Account No.</label>
                        <ng-container *ngIf="!bankForm.controls.confirm_account_number.valid && bankForm.controls.confirm_account_number.dirty"
                        class="validator-error">
                        <span *ngIf="bankForm.controls.confirm_account_number.hasError('required')" class="error">Account number is
                            required</span>
                            <span *ngIf="bankForm.controls.confirm_account_number.hasError('confirmAccount')" class="error">Account number did not match</span>
                        </ng-container>
                    </div>
                    <div class="form-group floating mb-0">
                        <input type="number" min="0" class="form-control floating" formControlName="route_no"/>
                        <label for="usr">Route No.</label>
                        <ng-container *ngIf="!bankForm.controls.route_no.valid && bankForm.controls.route_no.dirty"
                        class="validator-error">
                        <span *ngIf="bankForm.controls.route_no.hasError('required')" class="error">Route number is
                            required</span>
                        </ng-container>
                    </div>
                    <div class="text-center">
                        <button type="submit" class="big-btn primary-blue-bg text-white" [disabled]="!bankForm.valid" >Add Bank Account</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>