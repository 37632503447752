<app-header></app-header>
<section class="chat-panel">
    <div class="container-fluid no-gutters p-0">
	  <!--chat-->
		<div class="messaging">
      		<div class="inbox_msg">
        		<div class="inbox_people">
				  <div class="headind_srch d-flex justify-content-between pb-0">
					<div class="recent_heading">
					  <h4 class="dark-grey-cl">Message</h4>						
					</div>
					<ul class="list-inline icons mb-0">
						<li class="list-inline-item"><input class="form-control" type="checkbox" ></li>
						<li class="list-inline-item"><img class="mb-3" src="assets/images/delete-icon.svg" alt="icon" /></li>
						<li class="list-inline-item"><img class="mb-3" src="assets/images/new-msg-icon.svg" alt="icon" data-toggle="modal" data-target="#add-new-chat" /></li>
				    </ul>
				  </div>
				  
				  <ul class="nav nav-tabs chat-tabs mb-4" id="myTab" role="tablist">
					  <li class="nav-item" role="presentation">
						<a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Messages</a>
					  </li>
					  <li class="nav-item" role="presentation">
						<a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Group</a>
					  </li>
					  <li class="nav-item" role="presentation">
						<a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Friend Request</a>
					  </li>
					</ul>
					<div class="tab-content chat-tabs-content" id="myTabContent">
					<!--msg-->
					<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">		  
						  <!--common-search-->
						     <div class="common-search position-relative mx-3 mb-3">
								 <form>
									<div class="form-group mb-0">
									  <input type="email" class="search-input form-control" placeholder="Search...">
									</div>
								 </form>
								 <img class="position-absolute" src="assets/images/search-icon.svg" alt="icon">
							  </div>
						   	 <!--common-search-->
						     <div class="chat-listing-users">
								<!--when-no-msg-->
								  <div class="no-msg text-center py-4 h-100 d-none">
								  	<img src="assets/images/no-msg-vector.svg" alt="vector" />
									<h5>No Messages</h5>
									<p>There is no message chat yet.</p>
									<a href="#" class="btn primary-blue-bg text-white big-btn">Add Friend</a>
								  </div>
								<!--when-no-msg-->
						  		<!--when-there-is-list-->
								  <div class="inbox_chat">
									<div class="chat_list active_chat">
								 	 <div class="chat_people d-flex">
									<div class="chat_img position-relative">
										<img class="w-100" src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> 
									  <div class="status position-absolute"></div></div>
									<div class="chat_ib">
									  <div class="d-flex justify-content-between mb-2">							
											<h5 class="mb-0 dark-grey-cl">Sunil Rajput</h5>
											  <div class="more-option-chat">
												  <div class="dropdown">
													<a class="btn position-relative chat_date" href="#" role="button" id="more" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
													  <img class="position-absolute" src="assets/images/more-dots.svg" alt="icons">
													</a>                      
													<div class="more-drop dropdown-menu" aria-labelledby="more" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 20px, 0px);">
													  <a class="dropdown-item" href="#">Delete</a>
													</div>
												  </div>
											 </div>
									  </div>
									  <p class="dark-grey-cl">Test, which is a new approach to have all solutions 
										astrology under one roof.</p>
										<small class="f-12 light-grey-cl">23 Apr 2021</small>
									</div>
								  </div>
								</div>
									<div class="chat_list">
								  <div class="chat_people d-flex">
									<div class="chat_img"> <img class="w-100" src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
									<div class="chat_ib">
									  <div class="d-flex justify-content-between mb-2">							
											<h5 class="mb-0 dark-grey-cl">Sunil Rajput</h5>
											  <div class="more-option-chat">
												  <div class="dropdown">
													<a class="btn position-relative chat_date" href="#" role="button" id="more" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
													  <img class="position-absolute" src="assets/images/more-dots.svg" alt="icons">
													</a>                      
													<div class="more-drop dropdown-menu" aria-labelledby="more" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 20px, 0px);">
													  <a class="dropdown-item" href="#">Delete</a>
													</div>
												  </div>
											 </div>
									  </div>
									  <p class="dark-grey-cl">Test, which is a new approach to have all solutions 
										astrology under one roof.</p>
										<small class="f-12 light-grey-cl">23 Apr 2021</small>
									</div>
								  </div>
								</div>
									<div class="chat_list">
								  <div class="chat_people d-flex">
									<div class="chat_img"> <img class="w-100" src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
									<div class="chat_ib">
									  <div class="d-flex justify-content-between mb-2">							
											<h5 class="mb-0 dark-grey-cl">Sunil Rajput</h5>
											  <div class="more-option-chat">
												  <div class="dropdown">
													<a class="btn position-relative chat_date" href="#" role="button" id="more" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
													  <img class="position-absolute" src="assets/images/more-dots.svg" alt="icons">
													</a>                      
													<div class="more-drop dropdown-menu" aria-labelledby="more" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 20px, 0px);">
													  <a class="dropdown-item" href="#">Delete</a>
													</div>
												  </div>
											 </div>
									  </div>
									  <p class="dark-grey-cl">Test, which is a new approach to have all solutions 
										astrology under one roof.</p>
										<small class="f-12 light-grey-cl">23 Apr 2021</small>
									</div>
								  </div>
								</div>
									<div class="chat_list">
								  <div class="chat_people d-flex">
									<div class="chat_img"> <img class="w-100" src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
									<div class="chat_ib">
									  <div class="d-flex justify-content-between mb-2">							
											<h5 class="mb-0 dark-grey-cl">Sunil Rajput</h5>
											  <div class="more-option-chat">
												  <div class="dropdown">
													<a class="btn position-relative chat_date" href="#" role="button" id="more" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
													  <img class="position-absolute" src="assets/images/more-dots.svg" alt="icons">
													</a>                      
													<div class="more-drop dropdown-menu" aria-labelledby="more" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 20px, 0px);">
													  <a class="dropdown-item" href="#">Delete</a>
													</div>
												  </div>
											 </div>
									  </div>
									  <p class="dark-grey-cl">Test, which is a new approach to have all solutions 
										astrology under one roof.</p>
										<small class="f-12 light-grey-cl">23 Apr 2021</small>
									</div>
								  </div>
								</div>
					  			  </div>
								 <!--when-there-is-list-->
						  	 </div>							
					</div>
					<!--msg-->
					<!--group-->
					<div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">										  
							  <!--common-search-->
								 <div class="common-search position-relative mx-3 mb-3">
									 <form>
										<div class="form-group mb-0">
										  <input type="email" class="search-input form-control" placeholder="Search...">
										</div>
									 </form>
									 <img class="position-absolute" src="assets/images/search-icon.svg" alt="icon">
								  </div>
								 <!--common-search-->
								 <div class="chat-listing-users">
									<!--when-no-msg-->
									  <div class="no-msg text-center py-4 h-100 d-none">
										<img src="assets/images/no-msg-vector.svg" alt="vector" />
										<h5>No Messages</h5>
										<p>There is no message chat yet.</p>
										<a href="#" class="btn primary-blue-bg text-white big-btn">Add Friend</a>
									  </div>
									<!--when-no-msg-->
									<!--when-there-is-list-->
									  <div class="inbox_chat">
										<div class="chat_list active_chat">
										 <div class="chat_people d-flex">
										<div class="chat_img">
											<img class="w-100" src="assets/images/group-img.svg" alt="sunil"> </div>
										<div class="chat_ib">
										  <div class="d-flex justify-content-between mb-2">							
												<h5 class="mb-0 dark-grey-cl">Alchoholic</h5>
											  <small class="f-12 light-grey-cl">23 Apr 2021</small>
										  </div>
										  <p class="dark-grey-cl">Test, which is a new approach to have all solutions 
											astrology under one roof.</p>
											
										</div>
									  </div>
									</div>
										<div class="chat_list">
										 <div class="chat_people d-flex">
										<div class="chat_img">
											<img class="w-100" src="assets/images/group-img.svg" alt="sunil"> </div>
										<div class="chat_ib">
										  <div class="d-flex justify-content-between mb-2">							
												<h5 class="mb-0 dark-grey-cl">Alchoholic</h5>
											  <span class="f-12 green-bg text-white px-2 py-1">On A Call</span>
										  </div>
										  <p class="dark-grey-cl">Test, which is a new approach to have all solutions 
											astrology under one roof.</p>
											
										</div>
									  </div>
									</div>
										<div class="chat_list">
										 <div class="chat_people d-flex">
										<div class="chat_img">
											<img class="w-100" src="assets/images/group-img.svg" alt="sunil"> </div>
										<div class="chat_ib">
										  <div class="d-flex justify-content-between mb-2">							
												<h5 class="mb-0 dark-grey-cl">Alchoholic</h5>
											  <small class="f-12 light-grey-cl">23 Apr 2021</small>
										  </div>
										  <p class="dark-grey-cl">Test, which is a new approach to have all solutions 
											astrology under one roof.</p>
											
										</div>
									  </div>
									</div>
										<div class="chat_list">
										 <div class="chat_people d-flex">
										<div class="chat_img">
											<img class="w-100" src="assets/images/group-img.svg" alt="sunil"> </div>
										<div class="chat_ib">
										  <div class="d-flex justify-content-between mb-2">							
												<h5 class="mb-0 dark-grey-cl">Alchoholic</h5>
											  <small class="f-12 light-grey-cl">23 Apr 2021</small>
										  </div>
										  <p class="dark-grey-cl">Test, which is a new approach to have all solutions 
											astrology under one roof.</p>
											
										</div>
									  </div>
									</div>
									  </div>
									 <!--when-there-is-list-->
								 </div>							
					</div>
					<!--group-->
					<div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
						<div class="requests-sec p-3">
							<div class="user-online d-flex justify-content-between mb-3">
								<div class="name-sec d-flex">
									<img src="assets/images/live-user-img.svg" alt="image">
									<h5 class="mb-0 align-self-center ml-3 dark-grey-cl">Rossey Mitheni</h5>
								  </div>
								<div class="online-btns align-self-center">
								  <button href="#" class="small-btn primary-blue-bg text-white f-14">Accept</button>
								  <button href="#" class="small-btn light-grey-cl dark-grey-cl f-14">Cancel</button>
								</div>
							  </div>
						</div>
						<div class="requests-sec p-3">
							<div class="user-online d-flex justify-content-between mb-3">
								<div class="name-sec d-flex">
									<img src="assets/images/live-user-img.svg" alt="image">
									<h5 class="mb-0 align-self-center ml-3 dark-grey-cl">Rossey Mitheni</h5>
								  </div>
								<div class="online-btns align-self-center">
								  <button href="#" class="small-btn primary-blue-bg text-white f-14">Accept</button>
								  <button href="#" class="small-btn light-grey-cl dark-grey-cl f-14">Cancel</button>
								</div>
							  </div>
						</div>
						<div class="requests-sec p-3">
							<div class="user-online d-flex justify-content-between mb-3">
								<div class="name-sec d-flex">
								  <img src="assets/images/live-user-img.svg" alt="image">
								  <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">Rossey Mitheni</h5>
								</div>
								<div class="online-btns align-self-center">
								  <button href="#" class="small-btn primary-blue-bg text-white f-14">Accept</button>
								  <button href="#" class="small-btn light-grey-cl dark-grey-cl f-14">Cancel</button>
								</div>
							  </div>
						</div>
					</div>
						
					</div>	
				
				  <!--msg-->
				 
				</div>
        		<div class="mesgs">
				  <!--when there is no msg chat-->
				  <div class="no-msg-chat py-5 text-center d-none">
				  	<img src="assets/images/no-msg-vector-img.svg" alt="vector" />
					<img src="assets/images/friend-request-vector.svg" alt="vector" />
					<img src="assets/images/no-group-chat-vector.svg" alt="vector" />
				  </div>
				  <!--when there is no msg chat-->
				  <!--when there is msg chat-->
				  <div class="msg-occur">
				  	<!--chat-info-->
					  <!--group-chat-head-->
					  <div class="show-group-hd d-none">
					  	<div class="msg-info px-3 pt-2 text-white d-flex justify-content-between d-none">
							<div class="user-info d-flex">
								<span class="">
									<img src="assets/images/group-img.svg" alt="image" data-toggle="modal" data-target="#group-edit-info"/>
								</span>
								<div class="user-name pt-2 ml-3">
									<h4 class="mb-0"><a href="other-profile.html">Oscar Alexander</a></h4>
									<p class="f-12 text-white mb-0">4 Members</p>							
								</div>
							</div>
							<div class="user-info-controls py-3">
								<ul class="list-inline mb-0">
									<li class="list-inline-item"><a href="#" data-toggle="modal" data-target="#make-group-call"><img src="assets/images/video-chat-icon.svg" alt="icon" /></a></li>
									<li class="list-inline-item"><a href="#"><img src="assets/images/phone-call-icon.svg" alt="icon" /></a></li>
									<li class="list-inline-item">
									  <div class="dropdown">
										  <a href="#" class="dropdown-toggle a-drop" data-toggle="dropdown" id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false"><img src="assets/images/more-dot-icon.svg" alt="icon" /></a>
										  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
											<a class="dropdown-item d-flex d-inline-block chat-drop-menu" href="#">Notification
											  <span class="ml-4">
												  <div class="custom-control custom-switch">
													<input type="checkbox" class="custom-control-input" id="customSwitch1">
													<label class="custom-control-label" for="customSwitch1"></label>
												  </div>
											  </span>
											</a>
											<a class="dropdown-item chat-drop-menu" href="#">Delete Group</a>
										  </div>
									  </div>										
									</li>									  									
								</ul>
							</div>
						</div>
					  </div>					  
					  <!--group-chat-head-->
					  <!--chat-user-head-->
					  <div class="msg-info px-3 pt-2 text-white d-flex justify-content-between">
							<div class="user-info d-flex">
								<span class="position-relative">
									<img src="assets/images/user-pic.svg" alt="image" />
									<div class="status position-absolute"></div>
								</span>
								<div class="user-name pt-2 ml-3">
									<h4 class="mb-0"><a href="other-profile.html" class="text-white">Oscar Alexander</a></h4>
									<p class="f-12 text-white mb-0">9 m ago</p>							
								</div>
							</div>
							<div class="user-info-controls py-3">
								<ul class="list-inline mb-0">
									<li class="list-inline-item"><a href="video-call.html"><img src="assets/images/video-chat-icon.svg" alt="icon" /></a></li>
									<li class="list-inline-item"><a href="#"><img src="assets/images/phone-call-icon.svg" alt="icon" /></a></li>
									<li class="list-inline-item">
									  <div class="dropdown">
										  <a href="#" class="dropdown-toggle a-drop" data-toggle="dropdown" id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false"><img src="assets/images/more-dot-icon.svg" alt="icon" /></a>
										  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
											<a class="dropdown-item d-flex d-inline-block chat-drop-menu" href="#">Notification
											  <span class="ml-4">
												  <div class="custom-control custom-switch">
													<input type="checkbox" class="custom-control-input" id="customSwitch1">
													<label class="custom-control-label" for="customSwitch1"></label>
												  </div>
											  </span>
											</a>
											<a class="dropdown-item chat-drop-menu" href="#">Delete</a>
											<a class="dropdown-item chat-drop-menu" href="#">Unfriend</a>
											<a class="dropdown-item chat-drop-menu" href="#">Report or Block</a>
										  </div>
									  </div>										
									</li>									  									
								</ul>
							</div>
						</div>
					  <!--chat-user-head-->
					  <!--chat-info-->
					  <!--chat-->
					  <div class="msg_history">
						<div class="new_chat_box w-100 m-auto text-center pt-5">
							<img src="assets/images/new-chat.png" alt="icon" />
							<p>Say "Hello" to start chat with your Friend</p>
							<a href="#" type="button" class="small-btn primary-blue-bg text-white btn f-14">Hello</a>
						</div>
					  </div>
					  <!--chat-->
				  </div>
				  <!--when there is msg chat-->
				</div>
      		</div>      	  
    	</div>
	  <!--chat end-->
	</div>
  </section>
  <!---Main section--->
	
<!--group-edit-info-->
<div class="modal fade" id="group-edit-info" aria-modal="true" >
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title light-grey-cl">Add Member in this Video Call</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body edit-group-sec text-center my-3">
        <div class="group-img-big mb-3">
			<img src="assets/images/group-img-big.svg" alt="image" />
		</div>
		<div class="g-name position-relative mb-1">
			<input type="text" class="form-control" id="" value="Alchoholic">
			<img class="position-absolute" src="assets/images/edit-group-pencil.svg" alt="icon" />
		</div>
		<div class="g-info d-flex justify-content-between mb-3">
			<small class="light-grey-cl f-12">Created on: 29-09-20</small>
			<small class="primary-blue-cl f-12">Private</small>
		</div>
		<div class="g-count d-flex justify-content-between p-2 mb-3">
			<h5 class="dark-grey-cl mb-0">Group Members</h5> 
			<p class="yellow-cl mb-0">04</p>
		</div>
		<div class="members-sec p-2">
		  <div class="g-members d-flex justify-content-between mb-2">
              <div class="name-sec d-flex">
                <img src="assets/images/live-user-img.svg" alt="image">
                <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">Rossey Mitheni</h5>
              </div>
              <p class="primary-blue-cl f-14 align-self-center mb-0">Admin</p>
          </div>
		  <div class="g-members d-flex justify-content-between mb-2">
              <div class="name-sec d-flex">
                <img src="assets/images/live-user-img.svg" alt="image">
                <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">Rossey Mitheni</h5>
              </div>
              <p class="light-grey-cl f-14 align-self-center mb-0">member</p>
          </div>
		  <div class="g-members d-flex justify-content-between mb-2">
              <div class="name-sec d-flex">
                <img src="assets/images/live-user-img.svg" alt="image">
                <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">Rossey Mitheni</h5>
              </div>
              <p class="light-grey-cl f-14 align-self-center mb-0">member</p>
          </div>
		  <div class="g-members d-flex justify-content-between mb-2">
              <div class="name-sec d-flex">
                <img src="assets/images/live-user-img.svg" alt="image">
                <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">Rossey Mitheni</h5>
              </div>
              <p class="light-grey-cl f-14 align-self-center mb-0">member</p>
          </div>
		</div>
      </div>
    </div>
  </div>
</div>


<!--group-video-call-->
<div class="modal fade" id="make-group-call" aria-modal="true" >
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title light-grey-cl">Add Member in this Video Call</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body edit-group-sec text-center my-3">
        <div class="group-img-big mb-3">
			<img class="mb-2" src="assets/images/group-img-big.svg" alt="image" />
			<p class="f-18">Alchoholic</p>
		</div>
		<div class="g-count d-flex justify-content-between p-2 mb-3">
			<h5 class="dark-grey-cl mb-0">Group Members</h5> 
			<p class="yellow-cl mb-0">04</p>
		</div>
		<div class="members-sec p-2">
		  <div class="g-members d-flex justify-content-between mb-2">
              <div class="name-sec d-flex">
                <img src="assets/images/live-user-img.svg" alt="image">
                <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">Rossey Mitheni</h5>
              </div>
              <div class="round friend-select align-self-center">
                  <input type="checkbox" id="checkbox1">
                  <label for="checkbox1"><img src="assets/images/circle-check.svg" alt="icon"></label>
              </div>
          </div>
		  <div class="g-members d-flex justify-content-between mb-2">
              <div class="name-sec d-flex">
                <img src="assets/images/live-user-img.svg" alt="image">
                <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">Rossey Mitheni</h5>
              </div>
              <div class="round friend-select align-self-center">
                  <input type="checkbox" id="checkbox2">
                  <label for="checkbox2"><img src="assets/images/circle-check.svg" alt="icon"></label>
              </div>
          </div>
		  <div class="g-members d-flex justify-content-between mb-2">
              <div class="name-sec d-flex">
                <img src="assets/images/live-user-img.svg" alt="image">
                <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">Rossey Mitheni</h5>
              </div>
              <div class="round friend-select align-self-center">
                  <input type="checkbox" id="checkbox3">
                  <label for="checkbox3"><img src="assets/images/circle-check.svg" alt="icon"></label>
              </div>
          </div>
		  <div class="g-members d-flex justify-content-between mb-2">
              <div class="name-sec d-flex">
                <img src="assets/images/live-user-img.svg" alt="image">
                <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">Rossey Mitheni</h5>
              </div>
              <div class="round friend-select align-self-center">
                  <input type="checkbox" id="checkbox4">
                  <label for="checkbox4"><img src="assets/images/circle-check.svg" alt="icon"></label>
              </div>
          </div>
		  <div class="pt-2">
			<button class="primary-blue-bg text-white big-btn">Start Video Call</button>
		  </div>
		</div>
      </div>
    </div>
  </div>
</div>

<!--add-new-msg-call-->
<div class="modal fade" id="add-new-chat" aria-modal="true" >
	<div class="modal-dialog modal-dialog-scrollable">
	  <div class="modal-content">
		<div class="modal-header">
		  <h5 class="modal-title light-grey-cl">Add New Message</h5>
		  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
			<span aria-hidden="true">×</span>
		  </button>
		</div>
		<div class="modal-body edit-group-sec text-center">
		  <!--common-search-->
		  <div class="common-search position-relative mx-3 mb-3">
			<form>
			   <div class="form-group mb-0">
				 <input type="email" class="search-input form-control" placeholder="Search...">
			   </div>
			</form>
			<img class="position-absolute" src="assets/images/search-icon.svg" alt="icon">
		 </div>
		  <div class="members-sec p-2">
			<div class="g-members d-flex justify-content-between mb-2">
				<div class="name-sec d-flex">
				  <img src="assets/images/live-user-img.svg" alt="image">
				  <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">Rossey Mitheni</h5>
				</div>
				<p class="common-radio">
					<input type="radio" id="test1" name="radio-group">
					<label for="test1"></label>
				</p>
			</div>
			<div class="g-members d-flex justify-content-between mb-2">
				<div class="name-sec d-flex">
				  <img src="assets/images/live-user-img.svg" alt="image">
				  <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">Rossey Mitheni</h5>
				</div>
				<p class="common-radio">
					<input type="radio" id="test2" name="radio-group">
					<label for="test2"></label>
				</p>
			</div>
			<div class="g-members d-flex justify-content-between mb-2">
				<div class="name-sec d-flex">
				  <img src="assets/images/live-user-img.svg" alt="image">
				  <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">Rossey Mitheni</h5>
				</div>
				<p class="common-radio">
					<input type="radio" id="test3" name="radio-group">
					<label for="test3"></label>
				</p>
			</div>
			<div class="g-members d-flex justify-content-between mb-2">
				<div class="name-sec d-flex">
				  <img src="assets/images/live-user-img.svg" alt="image">
				  <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">Rossey Mitheni</h5>
				</div>
				<p class="common-radio">
					<input type="radio" id="test4" name="radio-group">
					<label for="test4"></label>
				</p>
			</div>
			<div class="pt-2">
			  <button class="primary-blue-bg text-white big-btn">Start Chat</button>
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </div>