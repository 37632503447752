<app-header></app-header>
<section class="add-friend-content blocked-users">
    <div class="container">
        <div class="row">
            <!---invite-friend-sec--->
            <div class="col-md-12">
                <div class="invite-friend-sec common-card-box">
                    <div class="friend-list my-2">
                        <!--Common-Blue-Heading-->
                        <div class="common-blue-hd mb-3">
                            <h5 class="primary-blue-cl mb-0 p-3">Blocked Users</h5>
                            <!-- <span
                                    *ngIf="blockuserList">({{blockuserList.length}})</span></h5> -->
                            <hr class="m-0">
                        </div>
                        <!--Common-search-->
                        <div class="common-search position-relative mx-3">
                            <form>
                                <div class="form-group mb-0">
                                    <input type="email" class="search-input form-control" placeholder="Search...">
                                </div>
                            </form>
                            <img class="position-absolute" src="assets/images/search-icon.svg" alt="icon" />
                        </div>
                        <!--List Start-->
                        <div class="friend-check-list p-3">
                            <div *ngFor="let block of blockuserList" class="d-flex justify-content-between mb-3">
                                <ng-container *ngIf="block!= null">
                                    <div class="friend-info d-flex justify-content-between">
                                        <span class="mr-3">
                                            <!-- <img src="assets/images/your-frind-img.svg" alt="image" /> -->
                                            <img *ngIf="block.profile_image != ''" class="mb-3"
                                                src="{{imageUrl}}{{block.profile_image}}" alt="icon" />
                                            <img *ngIf="block.profile_image === ''" class="mb-3"
                                                src="assets/images/dummy_user.png" alt="icon" />
                                        </span>
                                        <div class="friend-name align-self-center">
                                            <h6 class="f-18 mb-1">{{block?.name}}</h6>
                                            <p class="f-14 mb-0">{{block?.phone}}</p>
                                        </div>
                                    </div>

                                    <div class="align-self-center">
                                        <button href="#" class="small-btn primary-blue-bg text-white f-14"
                                            (click)="onSelect(block)" data-toggle="modal"
                                            data-target="#unblock">Unblock</button>
                                    </div>
                                </ng-container>
                            </div>
                            <div *ngIf="blockuserList && blockuserList.length==0">No Data</div>

                        </div>
                        <!--List End-->
                    </div>
                </div>
            </div>
            <!---invite-friend-sec--->
        </div>
    </div>
</section>
<!---Unblock-User-Modal--->
<div class="modal fade" id="unblock">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body invite-sent-msg text-center m-3">
                <h4>Unblock User</h4>
                <p>Are you sure want to unblock <b>@{{userdetails?.name}}?</b></p>


                <div class="btn-sec">
                    <button type="button" class="small-btn light-grey-bg dark-grey-cl mr-2"
                        data-dismiss="modal">Cancel</button>
                    <button type="button" (click)="unblockUser(userdetails)"
                        class="small-btn primary-blue-bg text-white" data-dismiss="modal"
                        aria-label="Close">Unblock</button>
                </div>
            </div>
        </div>
    </div>
</div>