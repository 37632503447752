import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './Pages/login/login.component';
import { SignUpComponent } from './Pages/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './Pages/forgot-password/forgot-password.component';
import { WelcomeComponent } from './Pages/welcome/welcome.component';
import { OtpComponent } from './Pages/otp/otp.component';
import { ProfileComponent } from './Pages/profile/profile.component';
import { ConnectFriendsComponent } from './Pages/connect-friends/connect-friends.component';
import { SearchGroupComponent } from './Pages/search-group/search-group.component';
import { AddFriendComponent } from './Pages/add-friend/add-friend.component';
import { ChatComponent } from './Pages/chat/chat.component';
import { StartUpHomeComponent } from './Pages/start-up-home/start-up-home.component';
import { ResetPasswordComponent } from './Pages/reset-password/reset-password.component';
import { LiveUsersComponent } from './Pages/live-users/live-users.component';
import { OtherUserLiveComponent } from './Pages/other-user-live/other-user-live.component';
import { OtherUserProfileComponent } from './Pages/other-user-profile/other-user-profile.component';
import { MyProfileComponent } from './Pages/my-profile/my-profile.component';
import { MyFriendListComponent } from './Pages/my-friend-list/my-friend-list.component';
import { VideoCallComponent } from './Pages/video-call/video-call.component';
import { ContactListComponent } from './Pages/contact-list/contact-list.component';
import { NewChatComponent } from './Pages/new-chat/new-chat.component';
import { SetLevelComponent } from './Pages/set-level/set-level.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { ToastrModule } from "ng6-toastr-notifications";
import { NgxSpinnerModule } from "ngx-spinner";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { GetInterceptorService } from "./services/interceptor/get-interceptor.service";
import { SetInterceptorService } from "./services/interceptor/set-interceptor.service";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WebcamModule } from 'ngx-webcam';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DirectiveModule } from '../../src/app/Component/directives/directive.module';
import { SocialAuthService, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { RouterModule } from '@angular/router';
import { FacebookModule } from 'ngx-facebook';
import { SelectDropDownModule } from "ngx-select-dropdown";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { AmazingTimePickerModule } from 'amazing-time-picker'; // this line you need
import { NgxStripeModule } from 'ngx-stripe';
import { NgxAgoraModule, AgoraConfig } from 'ngx-agora';
import { AuthenticationServiceService } from '../app/guard/authentication-service.service'
import { NumberOnlyDirective } from '../app/services/directives/number-only/number-only.directive';
import { CharacterOnlyDirective } from '../app/services/directives/character-only/character-only.directive';
import { TwitchLoginSdkModule } from "twitch-login-sdk";
import { ReportComponent } from './Pages/report/report.component';
import { BlockUsersComponent } from './Pages/block-users/block-users.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { environment } from 'src/environments/environment';
import { MessagingService } from './services/messaging.service';
import { GroupCallComponent } from './Pages/group-call/group-call.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { VimeModule } from '@vime/angular';
import { FilterPipe } from 'src/filter.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ClipboardModule } from 'ngx-clipboard';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { ServiceWorkerModule } from '@angular/service-worker';
import { GroupchatComponent } from './Pages/chat/groupchat/groupchat.component';
import { FriendrequestsComponent } from './Pages/chat/friendrequests/friendrequests.component';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { SharedModule } from './shared/shared.module';
import { FindFriendComponent } from './Pages/search-group/find-friend/find-friend.component';
import { AddFriendPopupComponent } from './Pages/add-friend/add-friend-popup/add-friend-popup.component';
import { DisableBackGuard } from './guard/disable-back.guard';
const agoraConfig: AgoraConfig = {
  AppID: 'f07033f31b7d436ab62d948320dd0a01'// '24aad182e05042838101928ebf1519fc',
};

// const config = new AuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider('624796833023-clhjgupm0pu6vgga7k5i5bsfp6qp6egh.apps.googleusercontent.com')
//   },
//   {
//     id: FacebookLoginProvider.PROVIDER_ID,
//     provider: new FacebookLoginProvider('561602290896109')
//   }
// ]);
// {
//   id: LinkedInLoginProvider.PROVIDER_ID,
//   provider: new LinkedInLoginProvider("78iqy5cu2e1fgr")
// }
// ]);
// }

@NgModule({
  declarations: [
    AppComponent,
    FilterPipe,
    LoginComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    WelcomeComponent,
    OtpComponent,
    ProfileComponent,
    ConnectFriendsComponent,
    SearchGroupComponent,
    AddFriendComponent,
    ChatComponent,
    StartUpHomeComponent,
    ResetPasswordComponent,
    LiveUsersComponent,
    OtherUserLiveComponent,
    OtherUserProfileComponent,
    MyProfileComponent,
    MyFriendListComponent,
    VideoCallComponent,
    CharacterOnlyDirective,
    NumberOnlyDirective,
    ContactListComponent,
    NewChatComponent,
    SetLevelComponent,
    ReportComponent,
    BlockUsersComponent,
    GroupCallComponent,
    GroupchatComponent,
    FriendrequestsComponent,
    FindFriendComponent,
    AddFriendPopupComponent
  ],
  imports: [
    BrowserModule,
    Ng2TelInputModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    SharedModule,
    ClipboardModule,
    RouterModule,
    CreditCardDirectivesModule,
    ReactiveFormsModule,
    NgbModule,
    NgOtpInputModule,
    NgxAgoraModule.forRoot(agoraConfig),
    ToastrModule.forRoot(), NgxSpinnerModule,
    BrowserAnimationsModule,
    WebcamModule,
    VimeModule,
    // IntlInputPhoneModule,
    Ng2SearchPipeModule,
    SelectDropDownModule,
    BsDatepickerModule.forRoot(),
    DirectiveModule,
    OwlDateTimeModule,
    // NgxSocialShareModule ,
    // ShareButtonsModule,
    PickerModule,
    TwitchLoginSdkModule.forRoot({
      twitchId: "zg9vghlxhevan60v6863kh552wnose",
      redirect: "https://twitch-sdk-login.stackblitz.io"
    }),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    OwlNativeDateTimeModule,
    AmazingTimePickerModule,
    SocialLoginModule,
    FacebookModule.forRoot(),
    NgxStripeModule.forRoot(environment.stripekey),
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '775303667698-kadu42db0n1dsivf68rat5hq4t32c9qi.apps.googleusercontent.com'),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('5608131702625167'),
          },
        ],
      } as SocialAuthServiceConfig
    }
    , AuthenticationServiceService, DisableBackGuard,
    { provide: HTTP_INTERCEPTORS, useClass: GetInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SetInterceptorService, multi: true },
    // providers:[ceiboShare]

    MessagingService,
    NgbActiveModal,
    // {provide :WebSocketSubject}
  ], bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
