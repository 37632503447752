<app-header></app-header>
<section class="setting-home">
    <div class="container-fluid">
      <div class="setting-box m-auto">
        <img class="mb-4" src="assets/images/report-vector.svg" alt="image" />
        <h4 class="dark-grey-cl mb-0">Report</h4>
        <p class="f-18">Lorem Ipsum is simply dummy text of the</p>    
        <!--form-->                
        <form class="register-block text-left" #reportForm="ngForm" (ngSubmit)="report(reportForm)">
          <div class="form-group floating mb-0">
            <input type="text" class="form-control floating" id="usr" name="name" ngModel  #name="ngModel"
            required/>
            <label for="usr">Name</label>
          </div>
          <div *ngIf="reportForm.submitted && name.invalid ">
            <div class="alert alert-danger" *ngIf="name.errors?.required">
              Name is required
            </div>
          </div>
          <div class="form-group floating mb-0">
            <input type="text" class="form-control floating" id="usr" name="subject" ngModel  #subject="ngModel"
            required/>
            <label for="usr">Subject</label>
          </div>
          <div *ngIf="reportForm.submitted && subject.invalid ">
            <div class="alert alert-danger" *ngIf="subject.errors?.required">
              subject is required
            </div>
          </div>
          <div class="form-group floating mb-0">
            <textarea class="form-control" id="" rows="3" placeholder="Message..."name="message" ngModel  #message="ngModel"
            required></textarea>
            <label for="usr"></label>
          </div>
          <div *ngIf="reportForm.submitted && message.invalid ">
            <div class="alert alert-danger" *ngIf="message.errors?.required">
              Message is required
            </div>
          </div>
          <div class="set-btns text-center mt-2">
            <button type="submit" class="big-btn primary-blue-bg text-white" >Submit</button>
          </div>
        </form>
        <!--form-->          
      </div>
    </div>
</section>