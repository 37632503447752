<header class="header-sec">
        <div class="top-header primary-blue-bg">
          <nav class="navbar navbar-dark navbar-expand-lg">
            <a class="navbar-logo">
              <img src="assets/images/header/logo.svg" alt="logo" />
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
      
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav custom-nav ml-auto">
                <li class="nav-item custom-link" (click)="redirect('home')">
                  <a class="nav-link"><img src="assets/images/header/home.svg" alt="header-icon" />
                    <span>Home</span> </a>
                </li>
                <li class="nav-item custom-link" (click)="openModal('FindFriendComponent')">
                  <a class="nav-link"><img src="assets/images/header/search.svg" alt="header-icon" /><span> Search</span></a>
                </li>
                <li class="nav-item custom-link" (click)="openModal('AddFriendPopupComponent')">
                  <a class="nav-link"><img src="assets/images/header/add-friend.svg" alt="header-icon" /><span>Add</span> </a>
                </li>
                <li class="nav-item custom-link" (click)="openModal('chat')">
                  <a class="nav-link"><img src="assets/images/header/message.svg" alt="header-icon" /><span>
                      Message</span></a>
                </li>
                <!-- <li class="nav-item custom-link" (click)="openChat(false)">
                  <a class="nav-link"><img src="assets/images/header/call-icon.svg" alt="header-icon" /><span>
                      Video Call</span></a>
                </li> -->
                <li class="nav-item dropdown profile-hd-drop d-flex align-items-center" data-dismiss="modal">
                    <img *ngIf="profilData?.profile_image !=''" class="mx-2" src="{{ImageUrl}}{{profilData?.profile_image}}"
                      alt="profile-photo" />
                    <img *ngIf="profilData?.profile_image ==''" class="mx-2" src="assets/images/dummy_user.png" alt="image">
        
                    <span class="status my-3"></span>
                    <a class="nav-link dropdown-toggle dark-grey-cl" id="navbarDropdown" role="button">
                      {{profilData?.name}}
                    </a>
                  </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>