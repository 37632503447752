import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  countryStatus: boolean = true;
  countryCode: any = '';
  type:any;
  config:{}
  countryCodeList: Array<any> = [];
  constructor(
    private router:Router,
    private common:CommonService,
    private service:ApiService,
    private localstorage :LocalStorageService
    ) { }

  ngOnInit(): void {

  }

  selectionChanged(e:any){}

  Forgot(form:NgForm){
    if(form.form.value.email[0] == ' '){
      return this.common.error('Space not allowed');
    }
      var emailType = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      var phoneType =/^\d{10}$/;
      if(form.form.value.email.trim().match(emailType) == null){
        return this.common.error('Please enter valid email');
      }
      if (form.form.value.email.match(emailType) != null) {
        this.type = 'Email';
      }
      var fd = new FormData();
      if (this.type === 'Email') {
        fd.append("email", form.value.email);
      }
      this.service.forgotPassword(fd).subscribe((res: any) => {
        if (res['success'] == 1) {
          this.common.hideSpinner();
          localStorage.setItem("forgotPassword",'1');
          this.common.success(res.message)
          this.localstorage.set("token", res['data'].token);
          this.router.navigate(['/otp', {type:this.type}])
        }
        else {
          this.common.hideSpinner();
          this.common.error(res.message);
  
        }
      })
    }
  
}
