import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SubjectService } from '../subject.service';
import { ChatService } from 'src/app/services/chat/chat.service';
import { ApiService } from '../api-service/api.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  webSocket: WebSocket;
  streaming: any;
  roomObj: any;
  ringtone: any;
  timer: any;
  onGoingGroupCalls = new BehaviorSubject<any[]>([]);
  isPublicCalljoins = new BehaviorSubject<boolean>(false);
  isInviteUser = new BehaviorSubject<boolean>(false);
  isCallEnded = new BehaviorSubject<boolean>(false);
  newMessage = new BehaviorSubject<any>('');
  onGetGroupMessage = new BehaviorSubject<any>('');
  userNotification: any;
  constructor(
    public common: CommonService,
    public modalService: NgbModal,
    public router: Router,
    public subject: SubjectService,
    public chatService: ChatService,
    private service: ApiService,
  ) {

  }
  public liveUsersCount: BehaviorSubject<number> = new BehaviorSubject<number>(
    this.getLocalData('liveUsersCount', false)
  );
  public isUserPrivate = new BehaviorSubject<boolean>(
    this.getLocalData('isUserPrivate', false)
  );
  public publicMike: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this.getLocalData('publicMike', false)
  );
  public runningGroupCalls: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    this.getLocalData('runningGroupCalls', false)
  );
  setLiveUsersCount(value: number): void {
    this.setLocalData('liveUsersCount', value, false);
    this.liveUsersCount.next(value);
  }
  setUserPublicMike(value: boolean): void {
    this.setLocalData('publicMike', value, true);
    this.publicMike.next(value);
  }
  setUserPrivate(value: boolean): void {
    this.setLocalData('isUserPrivate', value, true);
    this.isUserPrivate.next(value);
  }
  getLiveUsersCount: Observable<any> = this.liveUsersCount.asObservable();
  getPublicMike: Observable<any> = this.publicMike.asObservable();
  getUserActivityStatus: Observable<any> = this.isUserPrivate.asObservable();
  getRunningGroupCalls: Observable<any> = this.runningGroupCalls.asObservable();
  openWebSocket(data: any) {
    let url = 'wss://shakti.parastechnologies.in/wsbottomsup/';
    if (data.token) {
      url = url + '?token=' + data.token;
    }
    if (data.room) {
      url = url + '&room=' + data.room;
    }
    if (data.chatType) {
      url = url + '&chatType=' + data.chatType;
    }
    if (data.userID) {
      url = url + '&userID=' + data.userID;
    }
    if (data.groupID) {
      url = url + '&groupID=' + data.groupID;
    }

    this.webSocket = new WebSocket(url);
    this.webSocket.onopen = (event: any) => {

      this.webSocket.onmessage = (event: any) => {
      
        let msgdata: any = JSON.parse(event.data);
        setTimeout(() => {
          this.onGetGroupMessage.next(msgdata);
          this.newMessage.next(msgdata)
        }, 1000);
        if (msgdata.type == "Online") {
          this.setOnlineUserList(msgdata.data);
        }

        if (msgdata.type == "search_invitation") {
          this.checkGroupUniqueness(msgdata)
          if (msgdata.data.length > 0) {
            this.setOnlineUserList(msgdata.data[0]);
          }
        }
        if (msgdata.type == "join_now") {
          let params = {};
          let userinfo = JSON.parse(localStorage.getItem('Bottom_up_user') || '{}');
          if (!msgdata.userCanJoinCallStatus) {
            this.common.error("You can't make this call now because user is already on call with someone");
            this.common.hideSpinner();
          } else {
            if (this.isPublicCalljoins.value && msgdata.userCanJoinCallStatus && msgdata.data.group_admin_id != userinfo.id) {
              setTimeout(() => {
                params = {
                  id: msgdata.joinedUserId, g_id: msgdata.data.group_id, isAppToWeb: 1, isPrivate: false
                }
                this.router.navigate(['/stream'], {
                  queryParams: {
                    id: this.common.encryptData(params)
                  }
                });
              }, 2000)
            }
            if (msgdata.userCanJoinCallStatus && (msgdata.data.group_admin_id == userinfo.id && !this.isInviteUser.value)) {
              setTimeout(() => {
                params = {
                  id: msgdata.joinedUserId, g_id: msgdata.data.group_id, isAppToWeb: 1, isPrivate: false
                }
                this.router.navigate(['/stream'], {
                  queryParams: {
                    id: this.common.encryptData(params)
                  }
                });
              }, 2000)
            }
          }
        }
        if (msgdata.type == "Offline") {
          if (this.common.allOnlineFriendsList.length > 0) {
            this.setOnlineUserList(msgdata.data);
          }
        }
        if (msgdata.type == "RecentChat") {
          this.subject.chatInfo.next(msgdata.data);
        }
        if (msgdata.type == "commentList" || msgdata.type == "add_comment") {
          this.subject.liveComment.next(msgdata.data);
        }
        if (msgdata.type == "WhenOtherUserJoinChannel") {
          this.setLiveUsersCount(msgdata.count);
        }
        if (msgdata.type == "add_comment") {
          this.subject.addComment.next(msgdata.data);
        }
        if (msgdata.data != null) {
          if (msgdata.data.length > 0 && msgdata.data[0].msgId) {
            this.common.messageToast(msgdata.data[0]);
            this.subject.newMsg.next(msgdata.data[0]);
          }
        }
        if (msgdata.type == "read") {
          if (!!msgdata.data) {
            let params = msgdata.data;
            params['sender_id'] = msgdata.data.source_user_id;
            params['receiver_id'] = msgdata.data.target_user_id;
            this.subject.newMsg.next(params);
          }
        }
      }

      this.webSocket.onclose = (event: any) => {
        let userInfo = JSON.parse(localStorage.getItem('Bottom_up_user') || '{}')
        let data = {
          'token': userInfo.token,
          'userID': userInfo.id,
        }
        this.openWebSocket(data);
      }
    }
  }

  stopStreaming() {
    clearInterval(this.timer);
    this.streaming.stop();
    if (this.roomObj && this.roomObj !== null) {
      this.roomObj.disconnect();
      this.roomObj = null;
    }
    this.modalService.dismissAll();
  }

  public sendMessage(message: any) {
    return this.webSocket.send(JSON.stringify(message));
  }


  getProfile() {
    this.service.getProfile('').subscribe((res: any) => {
      localStorage.setItem("Bottom_up_user", JSON.stringify(res["data"]));
      console.log(res, "getprofile")
      if (res['success'] == 1) {
        this.userNotification = res.data.notification_status
      }
      this.common.hideSpinner()
    })
  }


  setOnlineUserList(userData: any) {
    this.getProfile()
    console.log(userData)
    if (parseInt(userData.is_lock, 0)) {
      var index = this.common.allOnlineFriendsList.findIndex((element: any) => parseInt(element.id, 0) === parseInt(userData.id, 0));
      if (index !== -1) this.common.allOnlineFriendsList.splice(index, 1);
    } else {
      if (!this.checkIfExist(userData)) {
        if (!!userData.name) {
          if (this.userNotification == "1") {
            this.subject.onlineStatus.next('online');
            this.common.showAlert(userData.name + ' is online now.', '/search');
          }
        }
        this.common.allOnlineFriendsList.push(userData);
      }

    }
  }
  checkIfExist(userData: any) {
    if (this.common.allOnlineFriendsList.length) {
      var check = this.common.allOnlineFriendsList.find((c: any) => c.id === userData.id);
      if (check) {
        return true;
      } else {
        return false
      }
    } else {
      return false
    }
  }
  checkGroupUniqueness(msgdata: any) {
    if (msgdata.groups.length > 0) {
     this.setRunningGroupCalls(msgdata.groups);
    } else {
      this.setRunningGroupCalls([]);
    }
  }
 
  setRunningGroupCalls(remoteUserData: any[]): void {
    this.setLocalData('runningGroupCalls', remoteUserData, true);
    this.runningGroupCalls.next(remoteUserData);
  }
  removeRunningGroupCalls() {
    this.clearLocalData('runningGroupCalls');
    this.runningGroupCalls.next([]);
  }
  setLocalData(key: string, data: any, json?: boolean) {
    localStorage.setItem(key, json ? JSON.stringify(data) : data);
  }
  private runningConferences: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.getLocalData('runningConferences', false)
  );
  getRunningConferences() {
    return Observable.create((observer: any) => {
      observer.next(this.runningConferences.value);
      observer.complete();
    });
  }
  setRunningConferences(remoteUserData: string): void {
    this.setLocalData('runningConferences', remoteUserData, true);
    this.runningConferences.next(remoteUserData);
  }
  removeRunningConference() {
    this.clearLocalData('runningConferences');
    this.runningConferences.next(false);
  }
  getLocalData(key: string, json?: boolean) {
    let _data: any = null;
    try {
      _data = json
        ? JSON.parse(localStorage.getItem(key) || '')
        : localStorage.getItem(key);
      return _data;
    } catch (error) {
      if (error instanceof SyntaxError) this.clearLocalData(key);
      return null;
    }
  }
  clearLocalData(key: string) {
    localStorage.removeItem(key);
  }
  // inviteUserForCall(users: any[], group_id?: string) {
  //   if (users && users.length > 8) {
  //     return this.common.error("only 8 Friends can join call at a time");
  //   }
  //   this.isInviteUser.next(true);
  //   if (group_id) {
  //     let params = {
  //       g_id: group_id, isAppToWeb: 1, isPrivate: true
  //     }
  //     this.router.navigate(['/stream'], {
  //       queryParams: {
  //         id: this.common.encryptData(params)
  //       }
  //     });
  //   } else {
  //     var fd = new FormData();
  //     if (users instanceof Array) {
  //       fd.append("user_id", users.join(","));
  //     } else {
  //       fd.append("user_id", users);
  //     }
  //     this.service.sendcallNotifi(fd).subscribe((res: any) => {
  //       this.common.hideSpinner();
  //       if (res.success) {
  //         let params = {
  //           g_id: res.groupId, isAppToWeb: 1, isPrivate: true
  //         }
  //         this.router.navigate(['/stream'], {
  //           queryParams: {
  //             id: this.common.encryptData(params)
  //           }
  //         });
  //       }
  //     });
  //   }
  // }



  // inviteUserForCallViaChat(users: any[], group_id?: any) {
  //   let isGroupCall = false;
  //   if (users instanceof Array && group_id) {
  //     isGroupCall = true;
  //     var data: any = {
  //       "user_id": users.join(","),
  //       "type": "group",
  //       "group_id": group_id
  //     }
  //   } else {
  //     var data: any = {
  //       "user_id": users,
  //       "type": "single",
  //       "group_id": ""
  //     }
  //     isGroupCall = false;
  //   }
  //   this.service.sendnotificationReq(data).subscribe((res: any) => {
  //     this.common.hideSpinner();
  //   });
  //   let params: any = {
  //     isAppToWeb: 1, isPrivate: true
  //   }
  //   group_id ? params['g_id'] = group_id : ''
  //   params['isAppToWeb'] = group_id ? 1 : 1
  //   params['isMultiple'] = group_id ? 1 : 0
  //   !group_id ? params['id'] = users : ''
  //   this.router.navigate(['/stream'], {
  //     queryParams: {
  //       id: this.common.encryptData(params)
  //     }
  //   });
  // }



  //invite users for group call
  inviteGroupCall(groupId: string, userIds: any[], isNew: boolean) {
    if (userIds && userIds.length > 8) {
      return this.common.error("only 8 Friends can join call at a time");
    }
    // check if the group is newly created or not
    this.isInviteUser.next(true);
    if (!groupId && isNew) {
      var fd = new FormData();
      if (userIds instanceof Array) {
        fd.append("user_id", userIds.join(","));
      } else {
        fd.append("user_id", userIds);
      }
      this.service.sendcallNotifi(fd).subscribe((res: any) => {
        this.common.hideSpinner();
        if (res.success) {
          let params = {
            g_id: res.groupId, id: userIds, isAppToWeb: 1, isPrivate: true
          }
          this.router.navigate(['/stream'], {
            queryParams: {
              id: this.common.encryptData(params)
            }
          });
        }
      });
    } else {
      var data: any = {
        "user_id": userIds.join(","),
        "type": "group",
        "group_id": groupId
      }
      let params = {
        g_id: groupId, id: userIds, isAppToWeb: 1, isPrivate: true
      }
      this.router.navigate(['/stream'], {
        queryParams: {
          id: this.common.encryptData(params)
        }
      });
      this.service.sendnotificationReq(data).subscribe((res: any) => {
        this.common.hideSpinner();

      });

    }
  }

  //invite single user for call
  inviteSingleUserCall(id: string) {
    var data: any = {
      "user_id": id,
      "type": "single",
      "group_id": ""
    }
    let params = {
      id: id, g_id: '', isAppToWeb: 1, isPrivate: false
    }
    this.router.navigate(['/stream'], {
      queryParams: {
        id: this.common.encryptData(params)
      }
    });
    this.service.sendnotificationReq(data).subscribe((res: any) => {
      this.common.hideSpinner();
    });
  }
}
