import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StarRatingComponent } from 'ng-starrating';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SubjectService } from '../../services/subject.service';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  profilData: any;
  ImageUrl: string;
  starRating = 0;
  rating: any
  ratingData: any;
  ringToneAudio: any;
  ratingStatus: boolean = false
  notificationlist: any;
  currentMessage = new BehaviorSubject(null);
  hasNotification = false;
  imgUrl = environment.imageUrl;
  status: any;
  userinfo: any;
  countInterval: any;
  timer: number
  data: any;
  token: any
  groupId: any;
  private uid: number;

  constructor(public router: Router,
    private common: CommonService,
    private service: ApiService,
    private zone: NgZone,
    private subject: SubjectService
  ) {
    this.subject.profileUpdate.subscribe(() => {
      this.getProfile();
    })
  }

  ngOnInit(): void {
    this.ImageUrl = this.service.imageUrl
    this.getProfile();
    this.getFeedback();
  }

  getnotifiaction() {
    this.service.getnotificationlist('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.notificationlist = res.data
        this.notificationlist.reverse();
        this.common.hideSpinner();
        this.hasNotification = false;
        this.notificationlist.forEach((element: any) => {
          this.status = element.notification_type
        })
      }
      else {
        this.common.hideSpinner();
        // this.common.error(res.message);

      }
    })
  }

  gotoWelcome() {
    this.service.logout().subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.hideSpinner();
        this.common.success(res.message);
        localStorage.clear();
        sessionStorage.clear();
        $('#logoutt').modal('hide');
        this.router.navigate(['/']);
      } else {
        this.common.hideSpinner();
        this.common.error(res.message);
      }
    })
  }

  getProfile() {
    this.service.getProfile('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.profilData = res.data;
        this.common.hideSpinner();
      } else {
        this.common.hideSpinner();
        this.common.error(res.message);
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/']);
      }
      // }
    })
  }

  getFeedback() {
    this.service.getrating('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.ratingData = res.data;
        if (res.data != ' ') {
          this.ratingStatus = true;
          this.starRating = JSON.parse(res.data.user_rating)
        }
        this.common.hideSpinner();
      } else {
        this.common.hideSpinner();
        this.common.error(res.message);
      }
    })
  }

  deleteAccout() {
    this.common.showConfirmBox('Are you sure you want to delete your account?').then((result: any) => {
      if (!!result.value) {
        this.deleteAccount();
      }
    })
  }

  deleteAccount() {
    this.service.deleteAccount('').subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.hideSpinner();
        this.common.success(res.message)
        localStorage.clear()
        sessionStorage.clear();
        this.router.navigate(['/'])
      } else {
        this.common.hideSpinner();
        this.common.error(res.message);
      }
    })
  }

  onRate($event: { oldValue: number, newValue: number, starRating: StarRatingComponent }) {
    this.starRating = $event.newValue;
    this.rating = this.starRating;
  }

  ratingForm(rating: any) {
    var fd = new FormData
    fd.append('title', rating.form.value.title)
    fd.append('rating', this.rating)
    fd.append('comment', rating.form.value.comment)

    this.service.rating(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.hideSpinner();
        this.common.success(res.message);
        $('#rating').modal('hide')
      }
      else {
        this.common.hideSpinner();
        this.common.error(res.message);
      }
    })
  }

  redirect(route: any) {
    this.router.navigate([`/${route}`]);
  }

  convertTime(createdDate: any) {
    let datetime = new Date(createdDate + " UTC");
    return datetime.toString();
  }
}
