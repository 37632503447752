import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewChecked, AfterViewInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/services/api-service/api.service";
import { CommonService } from "src/app/services/common/common.service";
import { SocketService } from "../../services/socketService/socket.service";
import { SubjectService } from "src/app/services/subject.service";
import Swal from "sweetalert2";
import { ChatTogglerService } from "src/app/services/chat-toggler.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MessagingService } from "src/app/services/messaging.service";

declare var $: any;

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit, AfterViewInit {
  isChatShown: boolean = false;
  @ViewChild('scrollMe', { static: false }) private myScrollContainer: ElementRef;
  @ViewChild('scrollSingleChat', { static: false }) private singleChatContainer: ElementRef;
  FriendRequest: any = [];
  selectedUserData: any = null;
  isSingleChatSelected = false;
  imageUrl: string;
  userinfo: any;
  comment: string;
  showEmojiPicker = false;
  Friendlist: any = [];
  searchall: any;
  islistShow: boolean = false;
  blockuser: any;
  tab: boolean = false;
  isShow: boolean = true;
  chatHistory: any;
  room: any;
  status: any;
  otherUserId: any;
  newUserId: any;
  userStatus: boolean = false;
  newUserdata: any;
  chatData: any;
  currentGroupData: any;
  groupList: any = [];
  searchText: string = "";
  groupmsg: boolean = false;
  ChatUrl: string;
  groupData: any = [];
  list: any = [];
  groupname: any;
  chatlist: any = [];
  selectedTab = 'home';
  selectedUserId: number = 0; channelId: any;
  ;
  currentSelectedGroup = null;
  @Input() anyDataForm: any = null;
  constructor(
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private service: ApiService,
    private common: CommonService,
    private socket: SocketService,
    public activeModal: NgbActiveModal,
    private messageService: MessagingService,
    private router: Router,
    public subject: SubjectService,
    private chatToggler: ChatTogglerService
  ) {
    this.userinfo = JSON.parse(
      localStorage.getItem("Bottom_up_user") || "{}"
    );
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params['tab'] && params['tab'] == '3') {
        this.selectedTab = 'contact';
      }
      if (params['tab'] && params['tab'] == '2' && params['id']) {
        this.selectedTab = 'profile';

        if (params['groupid']) {
          const user = this.common.decryptUserData(params['user']);
          let hash = "";
          let userData = {
            user: params['user'],
            sender_profile_picture: user[0].sender_profile_picture,
            members: [],
            group_id: user[0].group_id,
            group_name: ''
          }

          setTimeout(async () => {
            this.getData(hash);
            await this.getAllistGroup();
            this.groupList.forEach((res: any) => {
              if (res.group_id == params['groupid']) {
                userData.members = res.members
              }
            });
          }, 3000);
          this.gtotogroupChat(userData)
          setTimeout(() => {
            this.scrollToBottom()
          }, 1000);
        } else {
          this.common.error("Something went wrong!");
          this.router.navigate(['home']);
        }
        this.currentSelectedGroup = params['id'];
        this.getAllistGroup();
      }
      if (params['tab'] && params['tab'] == '1') {
        this.selectedTab = 'home';
        if (params['user']) {
          const user = this.common.decryptUserData(params['user']);
          let hash = "";
          let userData = {
            sender_profile_picture: user[0].sender_profile_picture,
            senderName: user[0].name,
            sender_id: user[0].member_id,
            group_id: user[0].group_id,
            hash: user[0].hash
          }

          setTimeout(() => {
            this.getData(hash);
            this.gotouserChat(user[0].member_id, userData.hash, userData)
          }, 3000);
        } else {
          this.common.error("Something went wrong!");
          this.router.navigate(['home']);
        }
      }
    })
    this.subject.chatInfo.subscribe((result) => {
      this.chatlist = result;
      this.chatlist.sort(function (a: any, b: any) {
        let bDate = new Date(b.created_on)
        let aDate = new Date(a.created_on);
        return bDate.valueOf() - aDate.valueOf();
      });
      this.scrollToBottom();
    });
    this.subject.newMsg.subscribe((result) => {

      if (!!result) {
        if (this.selectedUserData.sender_id == result.sender_id) {
          this.chatHistory.push(result);
        }
      }
    });
    this.getData("");
    setTimeout(() => {
      this.newMessageReceived();
    }, 1000);
  }

  ngOnInit() {
    this.getData('')
    this.chatdata()
    if (this.anyDataForm) {
      if (this.anyDataForm.tab && this.anyDataForm.tab == '3') {
        this.selectedTab = 'contact';
      }
      if (this.anyDataForm.tab && this.anyDataForm.tab == '2' && this.anyDataForm.id) {
        this.selectedTab = 'profile';
        this.currentSelectedGroup = this.anyDataForm.id;
        this.getAllistGroup();
      }
      if (this.anyDataForm.tab && this.anyDataForm.tab == '1') {
        this.selectedTab = 'home';
        if (this.anyDataForm.id) {
          this.selectedUserId = this.anyDataForm.id;
          setTimeout(() => {
            var hash = "";
            if (this.selectedUserId && this.userinfo.id < this.selectedUserId) {
              hash = this.userinfo.id + "-" + this.selectedUserId.toString();
            } else {
              hash = this.selectedUserId.toString() + "-" + this.userinfo.id;
            }
            this.getData(hash);
            this.gotouserChat(this.selectedUserId, hash);
          }, 3000);
        } else {
          this.common.error("Something went wrong!");
          this.router.navigate(['home']);
        }
      }
    }
  }

  newMessageReceived() {
    this.messageService.newMessage.subscribe((res: any) => {
      if (res) {
        this.updateChat(res.data?.sender_id)
        this.scrollToBottomSingleChat()
      }
    })

    this.socket.onGetGroupMessage.subscribe((res: any) => {
      if (res) {
        var fd = new FormData();
        fd.append("groupId", this.currentGroupData?.group_id);
        if (this.selectedTab == 'profile') {
          this.service.getchatofParticularGroup(fd).subscribe((res: any) => {
            this.common.hideSpinner();
            if (res["success"]) {
              this.groupmsg = true;
              this.groupData = res["data"];
              this.scrollToBottom();
            }
          });
        }
      }
    })
  }

  ngAfterViewInit() {
    this.scrollToBottom();
    this.scrollToBottomSingleChat();
  }


  scrollToBottom() {
    setTimeout(() => {
      if (this.myScrollContainer) {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      }
    }, 2000);
    this.cd.detectChanges();
  }

  scrollToBottomSingleChat() {
    setTimeout(() => {
      if (this.singleChatContainer) {
        this.singleChatContainer.nativeElement.scrollTop = this.singleChatContainer.nativeElement.scrollHeight;
      }
    }, 500);
    this.cd.detectChanges();
  }


  getData(room: any) {
    if (localStorage.getItem("Bottom_up_user")) {
      this.userinfo = JSON.parse(
        localStorage.getItem("Bottom_up_user") || "{}"
      );
      this.comment = "";
      let data = {
        token: this.userinfo.token,
        room: room,
        userID: this.userinfo.id,
        chatType: "singleChat",
      };
      this.socket.openWebSocket(data);
      setTimeout(() => {
        this.getNewMessage();
      }, 1000);
    }
  }

  gtotogroupChat(data: any) {
    this.currentGroupData = data;
    this.groupname = this.currentGroupData?.group_name;

    let data1 = {
      token: this.userinfo.token,
      groupID: data.group_id,
      userID: this.userinfo.id,
      chatType: "GroupChat",
    };
    this.socket.openWebSocket(data1);
    var fd = new FormData();
    fd.append("groupId", data.group_id);
    this.service.getchatofParticularGroup(fd).subscribe((res: any) => {
      this.common.hideSpinner();
      if (res["success"]) {
        this.groupmsg = true;
        this.groupData = res["data"];
        this.common.hideSpinner();
        this.common.success(res.message)
        this.scrollToBottom();
      } else {
        this.groupmsg = true;
        this.groupData = [];
        this.common.hideSpinner();
        this.common.error(res.message)
        this.scrollToBottom();
      }
    });
  }

  getAllistGroup() {
    this.service.getallGroup("").subscribe((res: any) => {
      this.common.hideSpinner();
      if (res["success"] === 1) {
        this.groupList = res["data"];
        this.groupList.sort((a: any, b: any) => {
          return <any>new Date(b.last_msg_date) - <any>new Date(a.last_msg_date) || <any>new Date(b.group_date) - <any>new Date(a.group_date);
        });
        if (this.currentSelectedGroup) {
          this.groupList.forEach((element: any) => {
            if (element.group_id == this.currentSelectedGroup) {
              this.gtotogroupChat(element);
            }
          })
        }
      }
    });
  }

  report(id: any) {
    this.router.navigate(["/report", id]);
  }

  editgroup(data: any) {
    this.groupname = data;
  }

  chooseUsers(e: any, user: any, i: any) {
    if (e.target.checked == false) {
      const index = this.list.indexOf(user.member_id.toString());
      if (index > -1) this.list.splice(index, 1);
    }
    if (e.target.checked == true) {
      if (this.list.indexOf(user.member_id.toString()) < 0)
        this.list.push(user.member_id);
    }
  }

  deletchat(data: any) {
    let list: any = [];
    list.push(data);
    var fd = new FormData();
    fd.append("receiverId", list.join(","));
    this.service.deleteChat(fd).subscribe((res: any) => {
      if (res["success"] === 1) {
        this.getNewMessage();
        this.chatdata()
        this.newUserdata = null;
        this.isSingleChatSelected = false;
        this.common.hideSpinner();
        this.common.success(res.message)
      } else {
        this.common.error(res.message);
      }
    });
  }
  blockedUser(userId: any) {
    var fd = new FormData();
    fd.append("friendId", userId);
    this.service.blockUser(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.success(res.message);
        let data = {
          serviceType: 'NotifyUserWhenBlock',
          userID: this.userinfo.id,
          recieverID: userId
        }
        this.gotouserChat(userId);
        let chatuserdata: any = {};
        chatuserdata = JSON.parse(sessionStorage.getItem('chatuserdata') || '{}')
        this.selectedUserData = chatuserdata;
        this.common.hideSpinner();
        this.socket.sendMessage(data);
      }
      else {
        this.common.error(res.message);
        this.common.hideSpinner()
      }
      this.ngOnInit()
    })
  }

  sendNotification() {
    this.channelId = JSON.parse(localStorage.getItem('Bottom_up_user') || '{}')
    if (this.list) {
      this.socket.inviteGroupCall(this.currentGroupData.group_id, this.list, false);
    }
    this.joinLeaveGroupCall(this.channelId?.ChatRoomName, 1)
    $("#make-group-call").modal("hide");
  }


  async joinLeaveGroupCall(groupId: any, isCompleted: any = 1) {
    let data = {
      "changestate": 1,
      "groupId": groupId,
      "iscallCompleted": isCompleted
    }
    await this.service.joinGroupCall(data).subscribe((res: any) => {
      this.common.hideSpinner();
      if (res['success'] == 1) {
      }
    })
  }

  deleteAll(chatlist: any) {
    let chatArr = this.chatlist.map((m: any) => m.receiver_id);
    var fd = new FormData();
    fd.append("receiverId", chatArr.join(","));
    this.service.deleteChat(fd).subscribe((res: any) => {
      this.common.hideSpinner();
      $("#delete").modal("hide");
      if (res["success"] === 1) {
        this.getNewMessage();
        this.newUserdata = null;
        this.chatdata();
        this.isSingleChatSelected = false;
        this.common.success(res.message)
      } else {
        this.common.error(res.message);
      }
    });
  }

  chatdata() {
    this.chatToggler.getChatVisibility().subscribe((res: boolean) => {
      this.isChatShown = res;
    });
    this.imageUrl = this.service.imageUrl;
    this.ChatUrl = "http://shakti.parastechnologies.in/houseParty/chatImages/";
    this.getFriendRequestList();
    this.Friendlist = [];
    this.getFriendList();
    this.scrollToBottom();
  }

  cancel() {
    $("#delete").modal("hide");
    let check = document.getElementById("myCheck1") as HTMLInputElement;
    check.checked = false;
  }

  gotouserChat(data: any, hash?: any, userData?: any) {

    this.selectedUserData = userData;
    if (userData != null || userData != undefined) {
      sessionStorage.setItem('chatuserdata', JSON.stringify(this.selectedUserData))
    }
    let room = userData?.hash ? userData?.hash : hash
    this.getData(room);

    var fd = new FormData();
    fd.append("reciever_id", data);
    this.service.getchatofParticularUser(fd).subscribe((res: any) => {
      if (res["success"] === 1) {
        this.chatHistory = []
        this.groupmsg = false;
        this.chatData = res;
        this.chatHistory = res["data"];
        this.chatHistory.reverse();
        this.scrollToBottomSingleChat();
        $("#add-new-chat").modal("hide");
        this.chatHistory.forEach((element: any) => {
          if (element.sender_id < element.receiver_id) {
            this.room = element.sender_id + "-" + element.receiver_id;
          } else {
            this.room = element.receiver_id + "-" + element.sender_id;
          }
        });
        if (this.chatHistory.length) {
          this.newUserdata = null;
        }
        this.isSingleChatSelected = true;
        this.common.hideSpinner();
      } else {
        this.common.hideSpinner();
      }
    });
  }

  searchFriendList(text: any) {
    var fd = new FormData();
    if (this.searchall == "" || this.searchall == undefined) {
      this.getFriendList();
    }
    fd.append("term", this.searchall);
    this.service.SearchFriendfromList(fd).subscribe((res: any) => {
      if (res["success"] === 1) {
        this.Friendlist = res["data"];
        this.common.hideSpinner();
      } else {
        this.common.hideSpinner();
      }
    });
  }

  unfriendFriend(data: any, status: any) {
    var data1 = new FormData();
    data1.append("friend", data);
    data1.append("status", status);
    this.service.unfriend(data1).subscribe((res: any) => {
      if (res["success"] == 1) {
        this.common.success(res.message);
        this.common.hideSpinner();
        this.getFriendList();
      } else {
        this.common.error(res.message);
        this.common.hideSpinner();
      }
    });
  }



  blockUser(detail: any) {

    Swal.fire({
      text: `Block ${detail.senderName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28A0B5",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result: any) => {
      if (result.isConfirmed) {
        var fd = new FormData();
        fd.append("friendId", detail.sender_id);
        this.service.blockUser(fd).subscribe((res: any) => {
          if (res["success"] == 1) {
            this.common.success(res.message);
            this.common.hideSpinner();
            this.gotouserChat(detail);
          } else {
            this.common.error(res.message);
            this.common.hideSpinner();
          }
        });
      }
    });
  }

  unblock(detail: any) {
    var fd = new FormData();
    fd.append("friendId", detail);
    this.service.unblockUser(fd).subscribe((res: any) => {
      if (res["success"] == 1) {
        this.common.success(res.message);
        this.common.hideSpinner();
        this.gotouserChat(detail);
      } else {
        this.common.error(res.message);
        this.common.hideSpinner();
      }
      let chatuserdata: any = {};
      chatuserdata = JSON.parse(sessionStorage.getItem('chatuserdata') || '{}')
      this.selectedUserData = chatuserdata;
    });
  }

  async goToCall(id: any, group_id?: string) {
    if (!id) {
      return false;
    }
    this.socket.inviteSingleUserCall(id);
    this.joinLeaveGroupCall(this.channelId?.ChatRoomName, 1)
  }
  getNewMessage() {
    let data = {
      user_id: this.userinfo.id,
      serviceType: "RecentChat",
    };
    this.socket.sendMessage(data);
  }

  readNewMessage() {
    let data = {
      userID: this.userinfo.id,
      recieverID: this.userinfo.id,
      type: "read",
      room: this.room,
    };
    this.socket.sendMessage(data);
  }

  startChat(e: any) {
    this.newUserdata = e;
    this.newUserId = e.id;
  }
  goToNewChat() {
    var hash = "";
    if (this.newUserdata) {
      if (this.userinfo.id < this.newUserId) {
        hash = this.userinfo.id + "-" + this.newUserId;
      } else {
        hash = this.newUserId + "-" + this.userinfo.id;
      }
      this.gotouserChat(this.newUserId, hash);
    }
  }

  gotoUser(data: any) {
    this.router.navigate(["/other_user_profile", data]);
  }

  addEmoji(event: any) {
    if (this.comment !== undefined || this.comment !== "") {
      this.comment = `${this.comment}${event.emoji.native}`;
    } else {
      this.comment = event.emoji.native;
    }
    this.showEmojiPicker = false;
  }

  selectall(event: any) {
    let check = document.getElementById("myCheck1") as HTMLInputElement;
    if (check.checked == true) {
      $("#delete").modal("show");
    } else {
      $("#delete").modal("hide");
    }
  }

  onFocus() {
    this.showEmojiPicker = false;
  }

  onBlur() {
  }

  getFriendRequestList() {
    this.service.getFriendrequest("").subscribe((res: any) => {
      if (res["success"] == 1) {
        (res.data || []).map((e: any) => {
          if (e && e != null) {
            this.FriendRequest.push(e);
          }
        });
        this.common.hideSpinner();
      }
    });
  }

  tabClick(type: string) {
    this.selectedTab = type;
    this.searchText = "";
    if (type == 'profile') {
      this.getAllistGroup();
    } else {
      this.isSingleChatSelected = false
    }
    setTimeout(() => {
      this.scrollToBottom();
    }, 1000);

  }

  getFriendList() {
    this.service.getFriendList("").subscribe((res: any) => {
      if (res["success"] == 1) {
        (res.data || []).map((e: any) => {
          if (e && e != null) {
            this.Friendlist.push(e);
          }
        });
        this.common.hideSpinner();
      }
    });
  }

  acceptRequest(data: any) {
    var fd = new FormData();
    fd.append("friendId", data.id);
    this.service.acceptrequest(fd).subscribe((res: any) => {
      if (res["success"] == 1) {
        this.common.success(res.message);
        this.FriendRequest = null;
        this.chatdata();
        this.common.hideSpinner();
      }
    });
  }

  rejectRequest(data: any) {
    var fd = new FormData();
    fd.append("friendId", data.id);
    this.service.rejectrequest(fd).subscribe((res: any) => {
      if (res["success"] == 1) {
        this.FriendRequest = null;
        this.common.success(res.message);
        this.getFriendRequestList();
        this.common.hideSpinner();
      }
    });
  }

  sendMsg(e: any, chat: any) {
    if (
      e.value.comment ||
      e.value.comment != null ||
      e.value.comment != undefined ||
      e.value.comment.trim()
    ) {
      chat.forEach((element: any) => {
        if (element.receiver_id === this.userinfo.id) {
          this.otherUserId = element.sender_id;
        } else {
          this.otherUserId = element.receiver_id;
        }
      });
      let data = {
        room: this.room,
        userID: this.userinfo.id,
        recieverID: this.otherUserId,
        type: "Chat",
        msg: this.comment,
        serviceType: "Chat",
        MessageType: "text",
      };
      this.socket.sendMessage(data);
      setTimeout(() => {
        this.getNewMessage();
        this.readNewMessage();
      }, 1000);
      this.comment = "";
      let room = this.userinfo.id + "|" + this.otherUserId;
      this.updateChat(this.otherUserId);
    }
  }

  sendgroupmsg(e: any, chat: any, type: any) {
    let msg;
    if (
      e.value.comment ||
      e.value.comment != null ||
      e.value.comment != undefined ||
      e.value.comment.trim()
    ) {
      if (type == "image") {
        msg = "beer_emoji.png";
      } else {
        msg = this.comment;
      }
      let data = {
        userID: this.userinfo.id,
        room: this.room,
        groupID: chat.group_id,
        type: "Chat",
        msg: msg,
        serviceType: "GroupChat",
        MessageType: type,
      };

      this.socket.sendMessage(data);
      var fd = new FormData();
      fd.append("groupId", chat.group_id);
      this.service.getchatofParticularGroup(fd).subscribe((res: any) => {
        if (res["success"] === 1) {
          this.groupmsg = true;

          this.groupData = res["data"];
          this.common.hideSpinner();
        } else {
          this.groupmsg = true;

          this.groupData = [];
          this.common.hideSpinner();
        }
      });
      this.scrollToBottom();
      this.comment = "";
    }
  }

  sendimgMsg(e: any, chat: any) {
    chat.forEach((element: any) => {
      if (element.receiver_id === this.userinfo.id) {
        this.otherUserId = element.sender_id;
      } else {
        this.otherUserId = element.receiver_id;
      }
    });

    let data = {
      room: this.room,
      userID: this.userinfo.id,
      recieverID: this.otherUserId,
      type: "Chat",
      msg: "beer_emoji.png",
      serviceType: "Chat",
      MessageType: "image",
    };

    this.socket.sendMessage(data);
    setTimeout(() => {
         this.getNewMessage();
      // }, 2000);
      // this.comment = "";
      this.readNewMessage();
    }, 1000);
    this.comment = "";
    this.updateChat(this.otherUserId);
    this.scrollToBottom()
  }

  sendHello(e: any) {
    if (this.userinfo.id < this.newUserId) {
      this.room = this.userinfo.id + "-" + this.newUserId;
    } else {
      this.room = this.newUserId + "-" + this.userinfo.id;
    }
    let data = {
      room: this.room,
      userID: this.userinfo.id,
      recieverID: this.newUserId,
      type: "Chat",
      msg: "Hello",
      serviceType: "Chat",
      MessageType: "text",
    };
    this.socket.sendMessage(data);
    this.getNewMessage();
    this.updateChat(this.newUserId);
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  convertTime(createdDate: any) {
    let datetime = new Date(createdDate + " UTC");
    return datetime.toString();
  }

  scrollToElement(): void {
    try {
      const element: any = document.getElementById("scrollme");
      element.scrollIntoView(false);
    } catch { }
  }

  onKeyDown(event: any) {
    if (event.keyCode === 222 && !event.shiftKey) {
      this.comment = this.comment + "\'";
    }
    if (event.keyCode === 222 && event.shiftKey) {
      this.comment = this.comment + '\"';
    }
  }

  getGroupDetails(id: string) {
    var fd = new FormData();
    fd.append("groupId", id);
    this.service.getchatofParticularGroup(fd).subscribe((res: any) => {
      this.common.hideSpinner();
      if (res["success"] === 1) {
        this.groupData = res.data
      }
    });
  }
  onGroupVideoCall() {
    this.socket.getRunningConferences().subscribe((response: any[]) => {
      if (!response) {
        $("#make-group-call").modal("show");
      } else {
        this.common.error('You can not make this call now as you are already on a call.');

      }
    });
  }
  updateGroup(data: any, id: any) {
    var fd = new FormData
    fd.append('name', this.groupname)
    fd.append('group_img', '')
    fd.append('privacy', data)
    fd.append('group', id)
    this.service.updateGroup(fd).subscribe((res: any) => {
      if (res['success'] === 1) {
        this.common.success(res.message)
        this.common.hideSpinner();
        this.groupname = ''
        this.getNewMessage();
      }
      else { this.common.hideSpinner() }
    })
  }
  selectUserData(): any {
    let data: any = {};
    if (this.newUserdata) {
      data['profile_image'] = this.newUserdata['profile_image'];
      data['name'] = this.newUserdata['name'];
      data['id'] = this.newUserdata['id'];
    } else {
      if (this.selectedUserData && this.userinfo) {
        if (this.selectedUserData?.sender_id == this.userinfo.id) {
          data['profile_image'] = this.selectedUserData['receiver_profile_picture'];
          data['name'] = this.selectedUserData['receiverName'];
          data['id'] = this.selectedUserData['receiver_id'];
          data['group_id'] = this.selectedUserData['group_id'];
        } else {
          data['profile_image'] = this.selectedUserData['sender_profile_picture'];
          data['name'] = this.selectedUserData['senderName'];
          data['id'] = this.selectedUserData['sender_id'];
          data['group_id'] = this.selectedUserData['group_id'];
        }
      }

    }
    return data;
  }
  updateChat(id: string) {
    var fd = new FormData();
    fd.append("reciever_id", id);
    this.service.getchatofParticularUser(fd).subscribe((res: any) => {
      if (res["success"] === 1) {
        this.chatHistory = res["data"];
        this.chatHistory.reverse();
        this.scrollToBottomSingleChat()
        this.common.hideSpinner();
      }
    });
  }
}
