<section class="add-friend-content">
        <div class="container">
          <div class="row">
            <!---invite-friend-sec--->
            <div class="col-md-12">
              <div class="invite-friend-sec common-card-box">
                <div class="new-friend text-center light-grey-bg py-4">
                  <p class="mb-2">Invite Friend</p>
                  <button class="big-btn primary-blue-bg text-white" data-toggle="modal" data-target="#add-friend">Add New
                    Friend</button>
                </div>
                <div class="friend-list my-2">
                  <!--Common-Blue-Heading-->
                  <div class="common-blue-hd mb-3">
                    <h5 class="primary-blue-cl mb-0 p-3">Your Friend List</h5>
                    <hr class="m-0">
                  </div>
                  <!--Common-search-->
                  <div class="common-search search-block-small position-relative mx-3">
                    <form [formGroup]="searchForm">
                      <div class="form-group mb-0">
                        <input type="email" class="search-input form-control px-3" formControlName="keywords"  autocomplete="off" placeholder="Search...">
                      </div>
                    </form>
                    <img class="position-absolute" src="assets/images/search-icon.svg" alt="icon" />
                  </div>
                  <!--List Start-->
                  <div class="friend-check-list p-3 shadow-none">
                    <div *ngFor="let users of friendList; let i=index"
                      class="d-flex justify-content-between mb-3 align-items-center">
                      <div class="friend-info d-flex justify-content-between">
                        <span class="mr-3"><img *ngIf="users.profile_image != ''" src="{{imageUrl}}{{users.profile_image}}"
                            alt="image" />
                          <img *ngIf="users.profile_image === ''" src="assets/images/dummy_user.png" alt="icon" /></span>
      
                        <div class="friend-name align-self-center">
                          <h6 class="f-18 mb-1">{{users.name}}</h6>
                          <p class="f-14 mb-0">{{users.phone}}</p>
                        </div>
                      </div>
                      <!-- <div class="round friend-select align-self-center"> -->
                      <input (click)="JoinCall($event,users,i)" type="checkbox" class="checkbox1 ml-auto" />
                      <label for="checkbox1"><img src="assets/images/circle-check.svg" alt="icon" /></label>
                      <!-- </div> -->
                    </div>
                    <div *ngIf="friendList && friendList.length ==0">No Data</div>
                  </div>
                  <!--List End-->
                  <div *ngIf="friendList && friendList.length >0" class="text-center py-4">
                    <a class="d-inline-block btn green-bg text-white py-2 px-3" (click)="sendInvite()">Ask To Join</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!---add-friend-section--->
      
      <!---Add-Friend-Modal--->
      <div class="modal fade" id="add-friend">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title light-grey-cl">Add Friend</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body social-btns-sec text-center my-3">
              <!-- <a routerLink="/contact_list" class="social-btns d-inline-block p-3 primary-blue-bg text-white mb-3"><span class="mr-2 align-self-center"><img src="assets/images/contact-list-icon.svg" alt="icon" /></span>Add From Contact List</a> -->
              <a (click)="sharefacebookUrl()" class="social-btns d-inline-block p-3 cl-facebook text-white mb-3"><span
                  class="mr-2 align-self-center"><img src="assets/images/facebook-icon.svg" alt="icon" /></span>Add From
                Facebook</a>
              <!-- <a href="#" class="social-btns d-inline-block p-3 cl-snapchat text-white mb-3"><span class="mr-2 align-self-center"><img src="assets/images/snapchat-icon.svg" alt="icon" /></span>Add From Snapchat</a> -->
              <a class="social-btns d-inline-block p-3 cl-black text-white" (click)="copyToClipBoard()"><span
                  class="mr-2 align-self-center"><img src="assets/images/link-icon.svg" alt="icon" />
                </span>Copy Profile
                Link</a>
            </div>
          </div>
        </div>
      </div>
      
      <!-- -Add-Friend-Modal- -->
      <div class="modal fade" id="invite-sent">
        <div class="modal-dialog modal-sm">
          <div class="modal-content">
            <div class="modal-body invite-sent-msg text-center m-3">
              <img class="mb-3" src="assets/images/invite-sent-modal-img.svg" alt="image" />
              <h4>Invite sent to {{list.length}} friends</h4>
              <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aucto.</p> -->
              <button *ngIf="list && list.length>0  && list.length<=8" type="button"
                class="small-btn primary-blue-bg text-white" data-dismiss="modal"   (click)="sendNotification()"
                aria-label="Close">OK</button>
            </div>
          </div>
        </div>
      </div>