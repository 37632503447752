import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl = environment.baseURL
  imageUrl = environment.imageUrl
  constructor(private http: HttpClient, public router: Router) { }
  public isAuthenticated(): boolean {
    return localStorage.getItem("Bottom_up_user") ? true : false;
  }
  login(body: any) {
    return this.http.post(this.baseUrl + `Home/login`, body)
  }
  sociallogin(body: any) {
    return this.http.post(this.baseUrl + `Home/socialsignUp`, body)
  }
  signup(body: any) {
    return this.http.post(this.baseUrl + 'Home/signUp', body)
  }
  OtpByEmail(body: any) {
    return this.http.post(this.baseUrl + 'Home/emailOtpVerification', body)
  }
  OtpByPhone(body: any) {
    return this.http.post(this.baseUrl + 'Home/phoneOtpVerification', body)
  }
  ForgotOtpByEmail(body: any) {
    return this.http.post(this.baseUrl + 'Home/forgotemailotpVerify', body)
  }
  forgotOtpByPhone(body: any) {
    return this.http.post(this.baseUrl + 'Home/forgotphoneotpVerify', body)
  }
  ResendOtpByEmail(body: any) {
    return this.http.post(this.baseUrl + 'Home/resendEmailOtp', body)
  }
  ResendOtpByPhone(body: any) {
    return this.http.post(this.baseUrl + 'Home/resendPhoneOtp', body)
  }
  forgotPassword(body: any) {
    return this.http.post(this.baseUrl + 'Home/forgotPassword', body)
  }
  logout() {
    return this.http.post(this.baseUrl + 'Home/logout','');
  }
  deleteAccount(body: any) {
    return this.http.post(this.baseUrl + 'Home/deleteAccount', body)
  }
  rating(body: any) {
    return this.http.post(this.baseUrl + 'Home/appFeedback', body)
  }
  getrating(body: any) {
    return this.http.post(this.baseUrl + 'Home/showuserFeedback', body)
  }
  resetPassword(body: any) {
    return this.http.post(this.baseUrl + 'Home/resetPassword', body)
  }
  completeProfile(body: any) {
    return this.http.post(this.baseUrl + 'Home/completeProfile', body)
  }
  getProfile(body: any) {
    return this.http.post(this.baseUrl + 'Home/getProfile', body)
  }
  getallvideo(body: any) {
    return this.http.get(this.baseUrl + 'Home/saveVideo', body)
  }
  getPrivacy(body: any) {
    return this.http.post(this.baseUrl + 'Home/privacyPolicy', body)
  }
  getTerms(body: any) {
    return this.http.post(this.baseUrl + 'Home/termCondition', body)
  }
  download(body: any) {
    return this.http.post(this.baseUrl + 'Home/downloadprivacyPolicy', body)

  }
  contactus(body: any) {
    return this.http.post(this.baseUrl + 'Home/contactUs', body)
  }
  report(body: any) {
    return this.http.post(this.baseUrl + 'Home/reportUser', body)
  }
  getFriendList(body: any) {
    return this.http.get(this.baseUrl + 'Home/getfriendList', body)
  }
  getLiveUser(body: any) {
    return this.http.get(this.baseUrl + 'Home/livefriendDatalist', body)
  }
  getMutualFriendList(body: any) {
    return this.http.get(this.baseUrl + 'Home/getmutualFriend', body)
  }
  removeMutualFriend(body: any) {
    return this.http.post(this.baseUrl + 'Home/removeMutual', body)
  }
  getgroupListInvi(body: any) {
    return this.http.get(this.baseUrl + 'Home/getlistgroupInvitation', body)
  }
  recentActivites(body: any) {
    return this.http.get(this.baseUrl + 'Home/alllistGroup', body)
  }
  SearchFriendfromList(body: any) {
    return this.http.post(this.baseUrl + 'Home/friendlistSearch', body)
  }
  endgroupCall(body: any) { // only user more than 2 user in the call and user is admin and when initiate group call 
    return this.http.post(this.baseUrl + 'Home/endcallGroup', body)
  }
  endCall(body: any) { //only when two users in the call and 
    return this.http.post(this.baseUrl + 'Home/endCall', body)
  }
  // endGroupCall(body: any) {
  //   return this.http.post(this.baseUrl + 'Home/endcallGroup', body)
  // }
  rejectcall(body: any) {
    return this.http.post(this.baseUrl + 'Home/getiospushToken', body)
  }
  unfriend(body: any) {
    return this.http.post(this.baseUrl + 'Home/friendUnfriendPost', body)
  }
  blockUser(body: any) {
    return this.http.post(this.baseUrl + 'Home/blockFriend', body)
  }
  unblockUser(body: any) {
    return this.http.post(this.baseUrl + 'Home/unblockFriend', body)
  }
  updateNotificationStatus(body: any) {
    return this.http.post(this.baseUrl + 'Home/notificationStatus', body)
  }
  getProfileprivacy(body: any) {
    return this.http.post(this.baseUrl + 'Home/getuserprivacyData', body)
  }
  updateUserPrivacy(body: any) {
    return this.http.post(this.baseUrl + 'Home/userprofilePrivacy', body)
  }
  updateProfile(body: any) {
    return this.http.post(this.baseUrl + 'Home/editProfile', body)
  }
  updateGroup(body: any) {
    return this.http.post(this.baseUrl + 'Home/editGroup', body)
  }
  CancelRequest(body: any) {
    return this.http.post(this.baseUrl + 'Home/cancelRequest', body)
  }
  changeStatus(body: any) {
    return this.http.post(this.baseUrl + 'Home/changeonlineStatus', body)
  }
  gettransList(body: any) {
    return this.http.get(this.baseUrl + 'Home/alldonationTransaction', body)
  }
  getEarned(body: any) {
    return this.http.get(this.baseUrl + 'Home/totalearnedDonation', body)
  }
  getDonate(body: any) {
    return this.http.get(this.baseUrl + 'Home/totalDonate', body)
  }
  getDrinkList(body: any) {
    return this.http.get(this.baseUrl + 'Home/totalDonate', body)
  }
  updateDonationStatus(body: any) {
    return this.http.post(this.baseUrl + 'Home/donationStatus', body)
  }
  getDonataionStatus(body: any) {
    return this.http.post(this.baseUrl + 'Home/getdonationStatus', body)
  }
  getCardList() {
    return this.http.get(this.baseUrl + 'Home/listingCard')
  }
  getbankList() {
    return this.http.get(this.baseUrl + 'Home/listingbankDtl')
  }
  getCoinsDeatils(body: any) {
    return this.http.post(this.baseUrl + 'Home/listingCard', body)
  }
  getCoinCount(body: any) {
    return this.http.get(this.baseUrl + 'Home/coinBalance', body)
  }
  getCoinList(body: any) {
    return this.http.get(this.baseUrl + 'Home/coindummyData', body)
  }
  searchFriend(body: any) {
    return this.http.post(this.baseUrl + 'Home/friendlistSearch', body)
  }
  bottomupUser(body: any) {
    return this.http.get(this.baseUrl + 'Home/bottomupUser', body)
  }
  addRemoveFriend(body: any) {
    return this.http.post(this.baseUrl + 'Home/sentRequest', body)
  }
  completeuserSearch(body: any) {
    return this.http.post(this.baseUrl + 'Home/completeuserSearch', body)
  }
  setAlcoholLevel(body: any) {
    return this.http.post(this.baseUrl + 'Home/storeAlcohaldata', body)
  }
  getFriendrequest(body: any) {
    return this.http.post(this.baseUrl + 'Home/getdatarequestFriend', body)
  }
  acceptrequest(body: any) {
    return this.http.post(this.baseUrl + 'Home/acceptRequest', body)
  }
  rejectrequest(body: any) {
    return this.http.post(this.baseUrl + 'Home/rejectRequest', body)
  }
  getGroupList(body: any) {
    return this.http.get(this.baseUrl + 'Home/listgroupMembers', body)
  }
  getLiveUsersList(body: any) {
    return this.http.get(this.baseUrl + 'Home/livefriendDatalist', body)
  }
  getBlockUsersList(body: any) {
    return this.http.get(this.baseUrl + 'Home/blockfriendList', body)
  }
  getRtcToken(body: any) {
    return this.http.post(this.baseUrl + 'Home/GetRtcToken', body)
  }
  setStatus(body: any) {
    return this.http.post(this.baseUrl + 'Home/startLive', body)
  }
  lockStatus(body: any) {
    return this.http.post(this.baseUrl + 'Home/changelockStatus', body)
  }
  endstatus(body: any) {
    return this.http.post(this.baseUrl + 'Home/endLive', body)
  }
  sendcallNotifi(body: any) {
    return this.http.post(this.baseUrl + 'Home/send_notification', body)
  }
  sendNotification(body: any): Promise<any> {
    let promise = new Promise((resolve, reject) => {
      let apiURL = this.baseUrl + 'Home/send_notification';
      this.http.post(apiURL, body)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          }
        );
    });
    return promise;
  }
  sendnotificationReq(body: any) {
    return this.http.post(this.baseUrl + 'Home/callNotification', body)
  }
  getOauthTwitter(body: any) {
    return this.http.post('http://shakti.parastechnologies.in/houseParty/' + 'Twitter', body)
  }

  verifyTwitterTokenData(oauthToken: any, oauthVerify: any) {
    return this.http.get(`https://api.twitter.com/oauth/access_token?oauth_token=${oauthToken}&oauth_verifier=${oauthVerify}`)
  }
  addbank(body: any) {
    return this.http.post(this.baseUrl + 'Home/addbankDtl', body)
  }
  getUserInfo(body: any) {
    return this.http.post(this.baseUrl + 'Home/getuserInfo', body)
  }
  getnotificationlist(body: any) {
    return this.http.get(this.baseUrl + 'Home/notificationListing', body)
  }
  notIntersting(body: any) {
    return this.http.post(this.baseUrl + 'Home/notInterested', body)
  }
  addNewCard(body: any) {
    return this.http.post(this.baseUrl + 'Home/addnewCard', body)
  }
  addCoin(body: any) {
    return this.http.post(this.baseUrl + 'Home/addCoin', body)
  }

  deleteCardInfo(body: any) {
    return this.http.post(this.baseUrl + 'Home/deleteCard', body)
  }

  generateStripeToken(body: any) {
    return this.http.post(this.baseUrl + 'Home/addnewCard', body)
  }

  paymentDontionStripe(body: any) {
    return this.http.post(this.baseUrl + 'Home/stripePost', body)
  }

  sendCoin(body: any) {
    return this.http.post(this.baseUrl + 'Home/sendCoin', body)
  }
  getchatofParticularUser(body: any) {
    return this.http.post(this.baseUrl + 'Home/ChatList', body)
  }
  getchatofParticularGroup(body: any) {
    return this.http.post(this.baseUrl + 'Home/getgroupChat', body)
  }
  deleteChat(body: any) {
    return this.http.post(this.baseUrl + 'Home/deletechatHistory', body)
  }
  getallGroup(body: any) {
    return this.http.post(this.baseUrl + 'Home/alllistGroup', body)
  }
  deleteSaveVideo(body: any) {
    return this.http.post(this.baseUrl + 'Home/delVideo', body)
  }
  getUserAlchole(body: any) {
    return this.http.post(this.baseUrl + 'Home/getuseralcohalLevel', body)

  }
  getallfriendsvideo(body: any) {
    return this.http.post(this.baseUrl + 'Home/getfriendlistVideo', body)
  }

  joinGroupCall(body: any) {
    return this.http.post(this.baseUrl + 'Home/joinleavegroupCall', body)
  }

  withdrawTransaction(body: any) {
    return this.http.post(this.baseUrl + 'Home/withDrawCoinTransaction', body)
  }
  withdrawCoin(body: any) {
    return this.http.post(this.baseUrl + 'Home/withDrawCoin', body)
  }

  setPrimaryBank(body: any) {
    return this.http.post(this.baseUrl + 'Home/setprimaryBank', body)
  }

  deleteBank(body: any) {
    return this.http.post(this.baseUrl + 'Home/deletebankdtl', body)
  }

  sendLiveComment(body: any) {
    return this.http.post(this.baseUrl + 'Home/commentliveVideo', body)
  }
}
