import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-start-up-home',
  templateUrl: './start-up-home.component.html',
  styleUrls: ['./start-up-home.component.scss']
})
export class StartUpHomeComponent implements OnInit {
  userinfo: any;
  isCameraEnabled:boolean = false;
  isMicrophoneEnabled:boolean = false;
  constructor(
    private router: Router,
    private db: AngularFireDatabase,
    public common: CommonService
  ) { }

  ngOnInit(): void {
    var that = this;
    let deviceId: any = localStorage.getItem('deviceId');
    this.userinfo = JSON.parse(localStorage.getItem('Bottom_up_user') || '{}');
    this.db.list('Users').set(this.userinfo.id, { UserId: this.userinfo.id, DeviceId: deviceId }).then((result: any) => {
      localStorage.removeItem('deviceId');
    });
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then(function (stream) {
        navigator.mediaDevices.enumerateDevices()
          .then(function (devices) {
            that.isCameraEnabled = true;
            that.isMicrophoneEnabled = true;
            devices.forEach(function (device) {
              var deviceId = device.deviceId;
            });

          })
          .catch(function (err) {
            console.log(err.name + ": " + err.message);
          });
      })
      .catch(function (err) {
        console.log('No mic for you!')
      });
  }

  gotohome() {
    this.router.navigate(['/home']);
  }

  askPermissionForCamera(event:any){
    console.log(event);
    var that = this;
    if(event.target.checked == true){
    navigator.mediaDevices.getUserMedia({ video: true})
    .then(function (stream) {
      console.log('here');
      that.isCameraEnabled = true;
    },err=>{
      // that.isCameraEnabled = false;
    })
  }
  }
  askPermissionForMicrophone(event:any){
    var that = this;
    console.log(event);
    if(event.target.checked == true){
      navigator.mediaDevices.getUserMedia({ audio: true})
      .then(function (stream) {
        console.log('here audio');
        that.isMicrophoneEnabled = true;
      },err=>{
        // that.isMicrophoneEnabled = false;
      })
    }

  }
}
