<app-header></app-header>
<section class="add-friend-content">
    <div class="container">
      <div class="row">
        <!--suggested-->
        <div  *ngIf="mutualData && mutualData.length>0"  class="col-md-12">
          <div class="py-4 px-3 common-card-box suggestion-sec w-100 mb-4">
            <h5 class="common-hd light-grey-cl mb-4 text-uppercase">People you may know</h5>
            <div  class="suggested-list d-flex">
              <div class="sugg-box text-center p-4 position-relative mr-3 " *ngFor ="let data of mutualData" >
                <!-- <img class="mb-3" src="assets/images/suggested-profile.svg" alt="image" /> -->
                <img *ngIf="data.profile_image != ''" class="mb-3" src="{{imageUrl}}{{data.profile_image}}" alt="icon" />
                <img  *ngIf="data.profile_image === ''" class="mb-3" src="assets/images/dummy_user.png" alt="icon" />
                <!-- <img class="mb-3" src="assets/images/suggested-profile.svg" alt="image"> -->
                <a (click)="removeFromList(data)"><img class="close-cross position-absolute" src="assets/images/close-cross.svg" alt="icon"></a>
                <p class="mb-0 dark-grey-cl">{{data.name}}</p>
                <p class="f-12 light-grey-cl">{{mutualCount}} Mutual Friends</p>
                <a *ngIf="!data.status"(click)="addFriend(data)" class="small-btn fds primary-blue-bg p-2 text-white f-14">Add To Friends</a>
                <a *ngIf="data.status=='1'"  class="small-btn fds primary-blue-bg p-2 text-white f-14">Request send</a>
              </div>
              <!-- <div class="sugg-box text-center p-4 position-relative">
                <img class="mb-3" src="assets/images/suggested-profile.svg" alt="image">
                <a href=""><img class="close-cross position-absolute" src="assets/images/close-cross.svg" alt="icon"></a>
                <p class="mb-0 dark-grey-cl">Paul walker</p>
                <p class="f-12 light-grey-cl">2 Mutual Friends</p>
                <a href="" class="small-btn fds primary-blue-bg p-2 text-white f-14">Add To Friends</a>
              </div>
              <div class="sugg-box text-center p-4 position-relative">
                <img class="mb-3" src="assets/images/suggested-profile.svg" alt="image">
                <a href=""><img class="close-cross position-absolute" src="assets/images/close-cross.svg" alt="icon"></a>
                <p class="mb-0 dark-grey-cl">Paul walker</p>
                <p class="f-12 light-grey-cl">2 Mutual Friends</p>
                <a href="" class="small-btn fds primary-blue-bg p-2 text-white f-14">Add To Friends</a>
              </div>
              <div class="sugg-box text-center p-4 position-relative">
                <img class="mb-3" src="assets/images/suggested-profile.svg" alt="image">
                <a href=""><img class="close-cross position-absolute" src="assets/images/close-cross.svg" alt="icon"></a>
                <p class="mb-0 dark-grey-cl">Paul walker</p>
                <p class="f-12 light-grey-cl">2 Mutual Friends</p>
                <a href="" class="small-btn fds primary-blue-bg p-2 text-white f-14">Add To Friends</a>
              </div>
              <div class="sugg-box text-center p-4 position-relative">
                <img class="mb-3" src="assets/images/suggested-profile.svg" alt="image">
                <a href=""><img class="close-cross position-absolute" src="assets/images/close-cross.svg" alt="icon"></a>
                <p class="mb-0 dark-grey-cl">Paul walker</p>
                <p class="f-12 light-grey-cl">2 Mutual Friends</p>
                <a href="" class="small-btn fds primary-blue-bg p-2 text-white f-14">Add To Friends</a>
              </div>
              <div class="sugg-box text-center p-4 position-relative">
                <img class="mb-3" src="assets/images/suggested-profile.svg" alt="image">
                <a href=""><img class="close-cross position-absolute" src="assets/images/close-cross.svg" alt="icon"></a>
                <p class="mb-0 dark-grey-cl">Paul walker</p>
                <p class="f-12 light-grey-cl">2 Mutual Friends</p>
                <a href="" class="small-btn fds primary-blue-bg p-2 text-white f-14">Add To Friends</a>
              </div> -->
            </div>
          </div>
        </div>
        <!--suggested-->
        <!---invite-friend-sec--->
        <div class="col-md-6">
          <div class="invite-friend-sec common-card-box">
            <div class="friend-list my-2">
              <!--Common-Blue-Heading-->
              <div *ngIf="FriendList" class="common-blue-hd mb-3">
                <h5 class="primary-blue-cl mb-0 p-3">My Friends<span >({{FriendList.length}})</span></h5>
                <hr class="m-0">
              </div>
              <!--Common-search-->
              <div class="common-search position-relative mx-3">
                <form>
                  <div class="form-group mb-0">
                    <input type="email" class="search-input form-control" [(ngModel)]="search"  autocomplete="off" name="search" placeholder="Search...">
                 
                  </div>
                </form>
                <img class="position-absolute" (click)="filter(search)" src="assets/images/search-icon.svg" alt="icon" />
                    <!-- <input type="email" class="search-input form-control" [(ngModel)]="search" (keydown)="filter(search)" autocomplete="off" name="search" placeholder="Search...">
                  </div>
                </form>
                <img class="position-absolute" src="assets/images/search-icon.svg" alt="icon" /> -->
              </div>
              <!--List Start-->
              <div *ngIf="FriendList && FriendList.length>0" class="friend-check-list p-3">
                <div *ngFor="let friends of FriendList"  class="d-flex justify-content-between mb-3">
                  <div  (click)="gotoUserProfile(friends)" class="friend-info d-flex justify-content-between">
                      <span class="mr-3">
                        <img  *ngIf="friends.profile_image ==''" src="assets/images/dummy_user.png" alt="image" />
                        <img *ngIf="friends.profile_image !==''" src="{{imageUrl}}{{friends.profile_image}}" alt="image" />
                      </span>
                      <div class="friend-name align-self-center">                      
                        <h6 class="f-18 mb-1">{{friends.name}}</h6>
                        <!-- <p  class="f-14 mb-0">{{friends.phone}}</p> -->

                        <p *ngIf = " friends?.phone_privacy_status == 1 || friends?.phone_privacy_status == 2" class="f-14 mb-0">{{friends.phone}}</p>
                      </div>
                  </div>
                  <div class="align-self-center">
                      <button (click)="unfriendFriend(friends)" class="small-btn red-bg text-white f-14">Unfriend</button>
                  </div>
                </div>
              </div>
              <div *ngIf="FriendList && FriendList.length === 0"  class="friend-check-list p-3">No Data</div>
              <!--List End-->
            </div>
          </div>
        </div>
        <!---invite-friend-sec--->
        </div>
    </div>
  </section>