import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgxAgoraService, AgoraClient, Stream, ClientEvent, StreamEvent } from 'ngx-agora';
import { CommonService } from 'src/app/services/common/common.service';
import { ApiService } from 'src/app/services/api-service/api.service';
import { SubjectService } from 'src/app/services/subject.service';
import { SocketService } from 'src/app/services/socketService/socket.service';
import * as moment from 'moment';
declare var $: any;
@Component({
  selector: 'app-golive',
  templateUrl: './golive.component.html',
  styleUrls: ['./golive.component.scss']
})
export class GoliveComponent implements OnInit {
  @Output() endlive = new EventEmitter<string>();
  uniqueId: number;
  localCallId = 'agora_local';
  private client: AgoraClient;
  private localStream: Stream;
  public remoteStream: any[] = [];
  rtcToken: any;
  isAudioEnabled: boolean = true;
  isVideoEnabled: boolean = true;
  isPublic: boolean = false;
  userinfo: any;
  livedata: any;
  comment: any = '';
  commentList: any = [];
  otherUserConnectedCount:string ='0'
  saveSession: boolean = false;
  dateTime: any;


  constructor(
    private common: CommonService,
    private service: ApiService,
    public subject: SubjectService,
    public socket: SocketService,
    private agoraService: NgxAgoraService,
  ) {
    this.subject.liveComment.subscribe((param) => {
      this.commentList = param;
    })
    this.subject.addComment.subscribe((param) => {
      this.getNewMessage();
    })
    this.socket.getLiveUsersCount.subscribe((param:string) => {
      if(param!='undefined'){
        this.otherUserConnectedCount = param;

      }
    })
  }

  ngOnInit(): void {
    this.userinfo = JSON.parse(localStorage.getItem('Bottom_up_user') || '{}');
    this.initCall();
    this.getNewMessage();
  }

  getNewMessage() {
    let data = {
      'liveUserID': this.userinfo.id,
      'type': 'commentList',
      'serviceType': 'Commenting'
    }
    this.socket.sendMessage(data)
  }

  async initCall() {
    let that = this;
    this.client = this.agoraService.createClient({
      mode: "live",
      codec: "vp8",
    });
    await this.client.setClientRole("host");
    that.assignClientHandlers();
    that.getToken();
  }

  getToken() {
    this.uniqueId = Math.floor(Math.random() * 100);
    // Join a channel
    var fd = new FormData();
    fd.append('channelID', this.userinfo.ChatRoomName);
    this.service.getRtcToken(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.rtcToken = res.token;
        // Added in this step to initialize the local A/V stream
        this.localStream = this.agoraService.createStream({ streamID: this.uniqueId, audio: true, video: true, screen: false });
        // The user has granted access to the camera and mic.
        this.localStream.on(StreamEvent.MediaAccessAllowed, () => {
        });
        // The user has denied access to the camera and mic.
        this.localStream.on(StreamEvent.MediaAccessDenied, () => {
        });
        this.initLocalStream(() => this.join(uid => this.publish(), error => console.error(error)));
      }
    })
  }

  private assignClientHandlers(): void {
    this.client.on(ClientEvent.LocalStreamPublished, evt => {
      this.setLiveStatus();
      this.common.hideSpinner();
    });

    this.client.on(ClientEvent.Error, error => {
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        this.client.renewChannelKey(
          '',
          () => {},
          renewError => {}
        );
      }
    });

    this.client.on(ClientEvent.RemoteStreamAdded, evt => {
      const stream = evt.stream as Stream;
      this.client.subscribe(stream, { audio: true, video: true }, err => {
      });
      this.common.hideSpinner();
      this.common.isVideocallInprogress = true;
    });

    this.client.on(ClientEvent.RemoteStreamSubscribed, evt => {
      const stream = evt.stream as Stream;
      // this.remoteStream = stream;
      this.common.hideSpinner();
    });

    this.client.on(ClientEvent.RemoteStreamRemoved, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        // this.remoteCalls = [];
        this.remoteStream = [];
        if (this.remoteStream.length < 1) {
          this.leave();
        }
      }
    });

    this.client.on(ClientEvent.PeerLeave, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
      }
    });
  }

  private initLocalStream(onSuccess?: () => any): void {
    this.localStream.init(() => {
      // The user has granted access to the camera and mic.
      this.localStream.play('agora_local');
      if (onSuccess) {
        onSuccess();
      }
    }, err =>{}
    );
  }

  /**
 * Attempts to connect to an online chat room where users can host and receive A/V streams.
 */
  join(onSuccess?: (uid: number | string) => void, onFailure?: (error: Error) => void): void {
    this.client.join(this.rtcToken, this.userinfo.ChatRoomName, this.uniqueId, onSuccess, onFailure);
  }

  /**
 * Attempts to upload the created local A/V stream to a joined chat room.
 */
  publish(): void {
    this.client.publish(this.localStream, err => {});
  }

  async leave() {
    this.localStream.stop(); // stop the stream
    this.localStream.close();
    // this.remoteCalls = [];
    this.remoteStream = [];
    // this.endLiveStatus();
    this.common.isVideocallInprogress = false;
    $('#call-end').modal('show');
  }

  muteUnmuteAudio() {
    this.isAudioEnabled = !this.isAudioEnabled;
    if (this.localStream.isAudioOn()) {
      this.localStream.muteAudio();
    } else {
      this.localStream.unmuteAudio();
    }
  }

  enableDisableCamera() {
    this.isVideoEnabled = !this.isVideoEnabled;
    if (this.localStream.isVideoOn()) {
      this.localStream.disableVideo();
    } else {
      this.localStream.enableVideo();
    }
  }

  showRange: boolean = false;
  showVolumeRange() {
    this.showRange = !this.showRange;
  }

  rangeSelect(event: any) {
    this.localStream.adjustAudioMixingVolume(event.target.value);
  }

  setLiveStatus() {
    let current = new Date();
    let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
    let cTime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
     this.dateTime = cDate + ' ' + cTime;
    var fd = new FormData();
    fd.append('Status', '1');
    fd.append('AgoraToken', this.rtcToken);
    fd.append('DateTimeString', this.dateTime);
    fd.append('channel', this.userinfo.ChatRoomName);
    this.service.setStatus(fd).subscribe((res: any) => {
      this.common.hideSpinner();
      if (res['success'] == 1) {
        this.livedata = res;
      } else {
      }
    }, err => {
    })
  }

  endLiveStatus() {
    let current = new Date();
    let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
    let cTime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
    let dateTime = cDate + ' ' + cTime;
    var fd = new FormData();
    fd.append('channel', this.userinfo.ChatRoomName);
    fd.append('resourceId', this.livedata.resourceId);
    fd.append('sid', this.livedata.sid);
    fd.append('uid', this.livedata.uid);
    fd.append('people', '1');
    fd.append('AgoraToken', this.rtcToken);
    fd.append('DateTimeString', dateTime);
    this.service.endstatus(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        $('#call-end').modal('hide');
        this.endlive.emit('endlive');
      } else {
        this.common.hideSpinner();
      }
    }, err => {
    })

  }

  addComments() {
    if (this.comment.length) {
      let data = {
        serviceType: 'Commenting',
        type: 'add_comment',
        user_id: this.userinfo.id,
        liveUserID: this.userinfo.id,
        comment: this.comment
      }
      this.socket.sendMessage(data);
      this.comment = '';
      this.getNewMessage();
    }
  }

  convertTime(createdDate: any) {
    let datetime = new Date(createdDate + ' UTC');
    return datetime.toString();
  }

  showEmojiPicker: boolean = false;
  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event: any) {
    if (this.comment !== undefined || this.comment !== '') {
      this.comment = `${this.comment}${event.emoji.native}`;
    } else {
      this.comment = event.emoji.native;
    }
    this.showEmojiPicker = false;
  }
  onKeyDown(event: any) {
    if (event.keyCode === 222 && !event.shiftKey) {
      // this.comment = this.comment + "\'";
    }
    if (event.keyCode === 222 && event.shiftKey) {
      this.comment = this.comment + '\"';
    }
  }

  checkSinceLive() {
   var startTimessss = moment(new Date(this.dateTime));
    var now = new Date().getTime();
    var duration = startTimessss.milliseconds() + 1000 * (startTimessss.seconds() + 60 * (startTimessss.minutes() + 60 * startTimessss.hours()));
    if (isNaN(duration)) {
      return "";
    }
    if (duration < now) {
      var milisec_diff = now - duration;
    } else {
      var milisec_diff = duration - now;
    }
    var date_diff = new Date(milisec_diff);
    return (date_diff.getHours() >= 1 ? date_diff.getHours() + " Hours " : '') + (date_diff.getMinutes() >= 1 ? date_diff.getMinutes() + " Minutes " : '') + (date_diff.getSeconds() + " Seconds");
  }
 
}
