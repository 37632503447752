import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { NgForm, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common/common.service';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss']
})
export class AddCardComponent implements OnInit {
  @Input() title: any;
  @Output() bankinfo = new EventEmitter<string>();
  cardList: any;
  addcardForm: any;
  months: any = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  years: any = ['2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
  constructor(
    private apiService: ApiService,
    public common: CommonService,
    public fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.addcardForm = this.fb.group({
      card_number: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
      name: ['', [Validators.required,this.noWhitespaceValidatorName.bind(this)]],
      month: ['04', [Validators.required]],
      year: ['2022', [Validators.required]]
    })
    this.getCards();
  }
  noWhitespaceValidator(control: FormControl) {
    const isSpace = (control.value || '').match(/\s/g);
    return isSpace ? { 'whitespace': true } : null;
  }
  noWhitespaceValidatorName(control: FormControl) {
    const isSpace = (control.value[0] || '').match(/\s/g);
    return isSpace ? { 'whitespaceName': true } : null;
  }
  getCards() {
    this.apiService.getCardList().subscribe((res: any) => {
      if (res['success'] == 1) {
        this.cardList = res.data;
        this.common.hideSpinner();
      }
    })
  }

  openModal() {
    $('#add-card').modal('show');
  }

  addCard() {
    // if(!this.addcardForm.value.card_number || this.addcardForm.value.card_number.trim() == ''){
    //   return this.common.error('Please enter valid card number');
    // }
    // if(!this.addcardForm.value.name || this.addcardForm.value.name == ''){
    //   return this.common.error('Please enter valid card holder name');
    // }
    if(this.addcardForm.value.month == '' || this.addcardForm.value.years == ''){
      return this.common.error('Please enter expiry month and year');
    }
    if (this.addcardForm.valid) {
    this.common.showSpinner();
    var fd = new FormData();
    fd.append('name', this.addcardForm.value.name);
    fd.append('card', this.addcardForm.value.card_number);
    fd.append('expirydate', this.addcardForm.value.month + '/' + this.addcardForm.value.year);
    this.apiService.addNewCard(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        $('#add-card').modal('hide');
        this.common.success(res.message);
        this.common.hideSpinner();
        this.getCards()
      } else {
        this.common.hideSpinner();
        this.common.error(res.message);

      }
    })
  }
  }

  selectBank(value: string) {
    this.bankinfo.emit(value);
  }

  deleteCard(cardId:any){
    Swal.fire({
      text: "Are you sure you want to delete this card info?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28A0B5',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        this.common.showSpinner();
        var fd = new FormData();
        fd.append('card', cardId);
        this.apiService.deleteCardInfo(fd).subscribe((res: any) => {
          if (res['success'] == 1) {
            this.common.success(res.message);
            this.common.hideSpinner();
            this.getCards()
          } else {
            this.common.hideSpinner();
            this.common.error(res.message);
    
          }
        })
      }
    })
  }

}