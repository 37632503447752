<app-header>
</app-header>
<section class="setting-home">
  <div class="container-fluid">
    <div class="setting-box m-auto">
      <img class="mb-4" src="assets/images/start-mice.svg" alt="image" />
      <h4 class="dark-grey-cl mb-0">Camera and Mice Permissions</h4>
      <p class="f-18">Just a few things before you can stream</p>
      <div class="set-toggle d-flex justify-content-between mt-4">
        <div class="text-left">
          <h6 class="primary-blue-cl mb-0">Camera</h6>
          <p class="mb-0">Enable your camera setting</p>
        </div>
        <div class="toggle-btn">
          <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitches" [(ngModel)]="isCameraEnabled" (change)="askPermissionForCamera($event)">
              <label class="custom-control-label" for="customSwitches"></label>
          </div>
        </div>
      </div>
      <div class="set-toggle d-flex justify-content-between mt-3 ">
        <div class="text-left">
          <h6 class="primary-blue-cl mb-0">Microphone</h6>
          <p class="mb-0">Enable your mice setting</p>
        </div>
        <div class="toggle-btn">
          <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitchesnew" [(ngModel)]="isMicrophoneEnabled" (change)="askPermissionForMicrophone($event)">
              <label class="custom-control-label" for="customSwitchesnew"></label>
          </div>
        </div>
      </div>
      <div class="set-btns d-flex justify-content-between mt-4">
        <a class="big-btn light-grey-bg dark-grey-cl" routerLink="/home">Skip</a>
        <a class="big-btn primary-blue-bg text-white" (click)="gotohome()">Set & Continue</a>
      </div>
    </div>
  </div>
</section>