import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { GroupCallComponent } from '../Pages/group-call/group-call.component';
import { SocketService } from '../services/socketService/socket.service';

@Injectable()
export class DisableBackGuard implements CanDeactivate<GroupCallComponent> {
   constructor(private socket: SocketService) { }
   canDeactivate(component: GroupCallComponent): boolean {
      if ((!component.canDeactivate() && !this.socket.isCallEnded.value) || component.isPageRefresh()) {
         if (confirm("Are you sure you want to leave this call ?")) {
            return true;
         } else {
            return false;
         }
      }
      this.socket.isCallEnded.next(true);
      return true;
   }
}
