<app-header></app-header>
<section class="other-profile-sec">
    <div class="container py-5">
        <div class="row">
            <!--profile-info-left--->
            <div class="col-md-7">
                <!--profile-info-->
                <div class="profile-info common-card-box p-4 mb-4">
                    <div class="row">
                        <div class="col-md-12 col-lg-4">
                            <div class="profile-img-box position-relative text-center">
                                <img *ngIf="profilData?.profile_image !=''"
                                    src="{{imageUrl}}{{profilData?.profile_image}}" alt="image" />
                                <img *ngIf="profilData?.profile_image ==''" src="assets/images/dummy_user.png"
                                    alt="image">

                                <!-- <div class="live-badge position-absolute text-white f-12">Live</div> -->
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-8">
                            <div class="profile-info-box mb-4 d-flex justify-content-between py-5">
                                <div class="profile-info">
                                    <h5>{{profilData?.name}}</h5>
                                    <p class="pofile-dob mb-0"><img class="mr-2 mb-2" src="assets/images/dob.svg"
                                            alt="icon" />{{profilData?.dob |date}}</p>
                                </div>
                                <div class="profile-btns d-flex">
                                    <a href="#" class="square-btn-sm btn light-grey-bg ml-2 text-center setting-btn"
                                        data-toggle="modal" data-target="#private"><img
                                            src="assets/images/setting-icon.svg" alt="icon" /></a>
                                    <a routerLink="/donate_trans"
                                        class="square-btn-sm btn primary-blue-bg ml-2 text-center donation-btn"><img
                                            src="assets/images/donation-icon.svg" alt="icon" /></a>
                                    <a routerLink="/wallet"
                                        class="square-btn-sm btn yellow-bg ml-2 text-center wallet-btn"><img
                                            src="assets/images/wallet-icon.svg" alt="icon" /></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="profile-des mt-4">
                                <p class="mb-0">{{profilData?.bio }}</p>
                                <hr>
                                <div class="donation-toggle d-flex justify-content-between">
                                    <div class="des">
                                        <h5>Notifications</h5>
                                        <p class="mb-0">By enabling the notification ON you will get to know about
                                            <br> this user activities
                                        </p>
                                    </div>
                                    <div class="toggle-btn">
                                        <div class="custom-control custom-switch">
                                            <!-- Default switch -->
                                            <input type="checkbox" class="custom-control-input" id="customSwitches"
                                                [(ngModel)]="notification_status" (change)="notificationToggle($event)">
                                            <label class="custom-control-label" for="customSwitches"></label>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="withdrawal_coins d-flex justify-content-between">
                                    <div class="des">
                                        <h5>Withdraw</h5>
                                        <p class="mb-0">You can withdraw coins donated to you by other users.</p>
                                    </div>
                                    <a routerLink="/withdraw"
                                        class="small-btn primary-blue-bg text-white ml-auto align-self-center py-2 px-3">Withdraw</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--profile-info-->
                <!--videos-->
                <div *ngIf="videoData" class="profile-videos common-card-box p-4">
                    <div class="common-blue-hd mb-3 d-flex justify-content-between">
                        <h5 class="primary-blue-cl mb-0">Videos</h5>
                        <p class="mb-0">{{videoData.length}}</p>
                    </div>
                    <hr class="m-0 mb-4">
                    <div *ngIf="videoData && videoData.length>0">
                       
                        <div *ngFor="let video of videoData; let i=index;" class="video-box mb-2 position-relative">
                            <video  id='hls-example{{i}}' class="video-js  w-100 vjs-default-skin"
                                width="640px" height="267px" controls
                                data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'>

                                <source src="{{url}}{{video.videoLink}}" type="application/x-mpegURL" />
                                <!-- <source src="https://vjs.zencdn.net/v/oceans.webm" type="video/webm" /> -->
                            </video>
                            <div class="video-info d-flex justify-content-between mt-lg-2 ">
                                <h6>Dated:{{video.date_created | date: 'dd-MM-yyyy'}}</h6>
                                <br>
                                <span class="delete-block mb-3">
                                    <p class="mb-0 green-cl">{{video.connectedPersons}} People Connected</p>
                                    <img src="assets/images/delete-icon.svg" alt="image" (click)="deleteVideo(video)">
                                </span>
                            </div>
                        </div>
                    </div>


                    <div *ngIf="videoData && videoData.length==0" class="video-box mb-4">
                        No Data
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="basic-info common-card-box p-3 mb-4">
                    <div class="common-blue-hd mb-3 d-flex justify-content-between">
                        <h5 class="primary-blue-cl mb-0">Basic Info</h5>
                        <a class="mb-0 green-cl" data-toggle="modal" data-keyboard="false" data-backdrop="static"
                            data-dismiss="modal" data-target="#editProfile">Edit</a>
                    </div>
                    <hr>
                    <div class="basic-info-detail mb-3">
                        <p class="mb-0 dark-grey-cl">Email</p>
                        <p class="mb-0 light-grey-cl">{{profilData?.email}}</p>
                    </div>
                    <div class="basic-info-detail mb-3">
                        <p class="mb-0 dark-grey-cl">Mobile Number</p>
                        <p class="mb-0 light-grey-cl">{{profilData?.phone}}</p>
                    </div>
                    <div class="basic-info-detail mb-3">
                        <p class="mb-0 dark-grey-cl">City</p>
                        <p class="mb-0 light-grey-cl">{{profilData?.city}}</p>
                    </div>
                    <div class="basic-info-detail mb-3">
                        <p class="mb-0 dark-grey-cl">State</p>
                        <p class="mb-0 light-grey-cl">{{profilData?.state}}</p>
                    </div>
                    <div class="basic-info-detail mb-3">
                        <p class="mb-0 dark-grey-cl">Address</p>
                        <p class="mb-0 light-grey-cl">{{profilData?.address}}</p>
                    </div>
                </div>
                <div *ngIf="FriendList" class="myfriends-list common-card-box p-4">
                    <div class="common-blue-hd mb-3 d-flex justify-content-between">
                        <h5 class="primary-blue-cl mb-0">Friends({{FriendList.length}})</h5>
                        <a *ngIf="FriendList && FriendList.length>0" routerLink="/myFriendList"
                            class="mb-0 green-cl">View All</a>
                    </div>
                    <hr>
                    <div class="friend-box">
                        <a *ngFor="let friends of FriendList " class="d-flex mb-3" (click)="gotoUserProfile(friends)">
                            <div class="sugg-user-img position-relative mr-3 align-self-center">
                                <img *ngIf="friends.profile_image==''" src="assets/images/sugg-img.svg" alt="image">
                                <img *ngIf="friends.profile_image !==''" src="{{imageUrl}}{{friends.profile_image}}"
                                    alt="image">

                                <div class="online-state position-absolute green-bg"></div>
                            </div>
                            <div class="sugg-info align-self-center">
                                <h5 class="mb-0 dark-grey-cl">{{friends.name}}</h5>
                                <p class="mb-0">{{friends.city}} | <span>{{friends.state}}</span></p>
                            </div>
                        </a>
                        <a *ngIf="FriendList && FriendList.length == 0">No Data</a>
                    </div>
                </div>
            </div>
            <!--profile-info-right-->
        </div>
    </div>
</section>
<!---Main section--->

<!--setting modal-->
<div class="modal fade" id="private" aria-modal="true">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title light-grey-cl">Settings</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body my-3 text-center">
                <div class="modal-profile-img m-auto">
                    <img *ngIf="profilData?.profile_image !=''" src="{{imageUrl}}{{profilData?.profile_image}}"
                        alt="image">
                    <img *ngIf="profilData?.profile_image ==''" src="assets/images/dummy_user.png" alt="image">

                </div>

                <div *ngIf="privacyData" class="privacy-set mt-5">
                    <div class="privacy-option d-flex justify-content-between text-left mb-3">
                        <p class="mb-0 align-self-center w-75">Date Of Birth<br><span class="f-14">{{profilData?.dob
                                |date}}</span></p>
                        <div class="status-drop dropdown align-self-center">
                            <select class="form-control" name="dob" id="type" value="" [(ngModel)]="privacyData.dob"
                                (change)="onchangePrivacy($event,'dob')">
                                <option value="1">Public</option>
                                <option value=2>Friends</option>
                                <option value=3>Only me</option>
                            </select>
                        </div>
                    </div>
                    <div class="privacy-option d-flex justify-content-between text-left mb-3">
                        <p class="mb-0 align-self-center w-75">Email<br><span class="f-14">{{profilData?.email}}</span>
                        </p>
                        <div class="status-drop dropdown align-self-center">
                            <select class="form-control" name="email" id="type" value="" [(ngModel)]="privacyData.email"
                                (change)="onchangePrivacy($event,'email')">
                                <option value="1">Public</option>
                                <option value=2>Friends</option>
                                <option value=3>Only me</option>

                            </select>
                        </div>
                    </div>
                    <div class="privacy-option d-flex justify-content-between text-left mb-3">
                        <p class="mb-0 align-self-center w-75">Mobile No.<br><span
                                class="f-14">{{profilData?.phone}}</span>
                        </p>
                        <div class="status-drop dropdown align-self-center">
                            <select class="form-control" name="phone" id="type" value="" [(ngModel)]="privacyData.phone"
                                (change)="onchangePrivacy($event,'phone')">
                                <option value="1">Public</option>
                                <option value=2>Friends</option>
                                <option value=3>Only me</option>

                            </select>
                        </div>
                    </div>
                    <div class="privacy-option d-flex justify-content-between text-left mb-3">
                        <p class="mb-0 align-self-center w-75">State<br><span class="f-14">{{profilData?.state}}</span>
                        </p>
                        <div class="status-drop dropdown align-self-center">
                            <select class="form-control" name="state" id="type" value="" [(ngModel)]="privacyData.state"
                                (change)="onchangePrivacy($event,'state')">
                                <option value="1">Public</option>
                                <option value=2>Friends</option>
                                <option value=3>Only me</option>

                            </select>
                        </div>
                    </div>
                    <div class="privacy-option d-flex justify-content-between text-left mb-3">
                        <p class="mb-0 align-self-center w-75">City <br><span class="f-14">{{profilData?.city}}</span>
                        </p>
                        <div class="status-drop dropdown align-self-center">
                            <select class="form-control" name="city" id="type" value="" [(ngModel)]="privacyData.city"
                                (change)="onchangePrivacy($event,'city')">
                                <option value="1">Public</option>
                                <option value=2>Friends</option>
                                <option value=3>Only me</option>

                            </select>
                        </div>
                    </div>
                    <div class="privacy-option d-flex justify-content-between text-left mb-3">
                        <p class="mb-0 align-self-center w-75">Address<br><span
                                class="f-14">{{profilData?.address}}</span>
                        </p>
                        <div class="status-drop dropdown align-self-center">
                            <select class="form-control" name="address" id="type" value=""
                                [(ngModel)]="privacyData.address" (change)="onchangePrivacy($event,'address')">
                                <option value="1">Public</option>
                                <option value=2>Friends</option>
                                <option value=3>Only me</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--edit profile modal-->
<div class="modal fade" id="editProfile" aria-modal="true">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title light-grey-cl">Edit Profile</h5>
                <button type="button" class="close" (click)="onCancelModal()" data-dismiss="modal" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body my-3">
                <div class="edit-profile-img m-auto position-relative">
                    <img *ngIf="profilData?.profile_image !='' && src " src="{{src}}" alt="image">
                    <img *ngIf="profilData?.profile_image ==''" class="mx-2" src="assets/images/dummy_user.png"
                        alt="image">


                    <div class="camera-icon">
                        <img src="assets/images/camera-icon.svg" alt="camera_icon">
                        <form>
                            <div class="form-group">
                                <input type="file" class="form-control-file" (change)="profilePic($event)"
                                    id="exampleFormControlFile1" accept=".png, .jpg, .jpeg">
                            </div>
                        </form>
                    </div>
                </div>
                <form *ngIf="profilData" #edit="ngForm" (ngSubmit)="EditProfile(edit)"
                    class="edit-profile-details mt-5">
                    <div class="form-group floating mb-0">
                        <input type="text" class="form-control floating" name="name" [(ngModel)]="username" required>
                        <label for="usr">Name</label>
                    </div>
                    <div class="form-group floating mb-0">
                        <input type="text" readonly [maxDate]="minDte" placeholder="DOB" class="form-control floating"
                            name="dob" [(ngModel)]="userdob" required #dob="ngModel" bsDatepicker
                            (bsValueChange)="getTimeSlots($event)" [bsConfig]="{ isAnimated: true }">
                        <label for="usr">Date of Birth</label>
                    </div>
                    <div class="form-group floating mb-0">
                        <input type="text" class="form-control floating white-bg" [disabled]="Emailtype" name="email"
                            id="type" value="" [(ngModel)]="profilData.email" required>
                        <label class="email-lebel" for="usr">Email</label>
                    </div>
                    <div class="form-group floating mb-0">
                        <input type="text" class="form-control floating" name="address" id="type" value=""
                            [(ngModel)]="useraddress" required>
                        <label for="usr">Address</label>
                    </div>
                    <div class="form-group floating mb-0">
                        <input type="text" class="form-control floating" id="" name="state" id="type" value=""
                            [(ngModel)]="userState" required>
                        <label for="usr">State</label>
                    </div>
                    <div class="form-group floating mb-0">
                        <input type="text" class="form-control floating" name="city" id="type" value=""
                            [(ngModel)]="usercity" required>
                        <label for="usr">City</label>
                    </div>

                    <div class="form-group floating mb-0 position-relative mobile-field">

                        <select name="countryCode" class="position-absolute" [(ngModel)]="phoneComponents.IDDCC"
                            #countryCode="ngModel" ngModel disabled="phonetype">
                            <option value="">Select Country Code</option>
                            <option *ngFor="let item of countryCodeList; let i = index" value="{{item.dial_code}}">
                                {{item.dial_code}}
                            </option>
                        </select>
                        <input type="text" class="form-control floating" id="" name="phone" #phone="ngModel"
                            disabled="phonetype" [(ngModel)]="phoneComponents.NN" appNumberOnly ngModel required>
                    </div>
                    <div class="form-group floating mb-0 reg-bio">
                        <textarea class="form-control floating" id="" rows="3" name="bio" id="type"
                            [(ngModel)]="userbio"></textarea>
                        <label for="usr">Bio</label>
                    </div>
                    <div class="text-center">
                        <button type="submit" class="big-btn primary-blue-bg text-white mt-2">Save Details</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>