<app-header *ngIf="!isChatShown"></app-header>

<section class="chat-panel">
  <div class="container-fluid no-gutters p-0">
    <div class="messaging">
      <div class="inbox_msg">
        <div class="inbox_people">
          <div class="headind_srch d-flex justify-content-between pb-0 m-height">
            <div class="recent_heading">
              <h4 class="dark-grey-cl">Message</h4>
            </div>
            <ul *ngIf="selectedTab == 'home'" class="list-inline icons mb-0">
              <li class="list-inline-item">
                <input class="form-control" id="myCheck1" type="checkbox" (click)="selectall($event)" />
              </li>
              <li class="list-inline-item">
                <img class="mb-3" src="assets/images/new-msg-icon.svg" data-dismiss="modal" alt="icon"
                  data-toggle="modal" data-target="#add-new-chat" />
              </li>
            </ul>
          </div>
          <ul class="nav nav-tabs chat-tabs chat-block mb-4 position-relative" id="myTab" role="tablist">
            <li class="nav-item" role="presentation" style="cursor: pointer;">
              <a class="nav-link" [ngClass]="selectedTab == 'home' ? 'active' : ''" (click)="tabClick('home')"
                id="home-tab" data-toggle="tab" role="tab">Messages</a>
            </li>
            <li class="nav-item" role="presentation" style="cursor: pointer;">
              <a class="nav-link" id="profile-tab" (click)="tabClick('profile')" data-toggle="tab" role="tab"
                [ngClass]="selectedTab == 'profile' ? 'active' : ''">Group</a>
            </li>
            <li class="nav-item position-relative" (click)="tabClick('contact')" role="presentation"
              style="cursor: pointer;">
              <span *ngIf="FriendRequest && FriendRequest.length > 0"
                class="notify-count dotted-block position-absolute"></span>
              <a class="nav-link" id="contact-tab" data-toggle="tab" role="tab" [attr.aria-controls]="selectedTab"
                [ngClass]="selectedTab == 'contact' ? 'active' : ''" [attr.aria-target]="selectedTab == 'contact'"
                [attr.aria-selected]="selectedTab == 'contact'">Friend
                Request</a>
            </li>
          </ul>
          <div class="tab-content chat-tabs-content" id="myTabContent">
            <!--Recent chat list-->
            <div class="tab-pane active" [ngClass]="selectedTab == 'home' ? 'show' : 'fade'" id="sd"
              *ngIf="selectedTab == 'home'">
              <!--common-search-->
              <div class="common-search position-relative mx-3 mb-3">
                <form>
                  <div class="form-group mb-0">
                    <input type="email" name="searchText" [(ngModel)]="searchText"
                      [ngModelOptions]="{ standalone: true }" class="search-input form-control" [(ngModel)]="searchText"
                      placeholder="Search..." />
                  </div>
                </form> <img class="position-absolute" src="assets/images/search-icon.svg" alt="icon" />
              </div>
              <!--common-search-->
              <div class="chat-listing-users">
                <!--when-no-msg-->
                <div class="no-msg text-center py-4 h-100 d-none"> <img src="assets/images/no-msg-vector.svg"
                    alt="vector" />
                  <h5 class="text-center">No Messages</h5>
                  <p class="text-center"> You have no messages yet.Use the pencil icon to start new conversation with
                    you friends. </p>
                  <!-- <a href="#" class="btn primary-blue-bg text-white big-btn">Add Friend</a> -->
                </div>
                <!--when-no-msg-->
                <!--when-there-is-list-->
                <div class="inbox_chat">
                  <div *ngFor="let chat of chatlist | filter: searchText" class="chat_list active_chat">
                    <div *ngIf="chat.sender_id != userinfo.id" class="chat_people d-flex">
                      <div class="chat_img position-relative"> <img *ngIf="chat.sender_profile_picture != ''"
                          src="{{ imageUrl }}{{ chat.sender_profile_picture }}" alt="image" /> <img
                          *ngIf="chat.sender_profile_picture === ''" src="assets/images/dummy_user.png" alt="icon" />
                        <div *ngIf="status" class="status position-absolute"></div>
                      </div>
                      <div class="chat_ib">
                        <div class="d-flex justify-content-between mb-2">
                          <h5 class="mb-0 dark-grey-cl" (click)="gotouserChat(chat.sender_id, chat.hash, chat)">
                            {{ chat.senderName }}
                          </h5>
                          <div class="more-option-chat">
                            <div class="dropdown">
                              <a class="btn position-relative chat_date" href="#" role="button" id="more"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <img
                                  class="position-absolute" data-dismiss="modal" src="assets/images/more-dots.svg"
                                  alt="icons" /> </a>
                              <div class="more-drop dropdown-menu" aria-labelledby="more" x-placement="bottom-start"
                                style="
                                    position: absolute;
                                    will-change: transform;
                                    top: 0px;
                                    left: 0px;
                                    transform: translate3d(0px, 20px, 0px);
                                  "> <a class="dropdown-item" (click)="deletchat(chat.sender_id)">Delete</a> </div>
                            </div>
                          </div>
                        </div>
                        <p *ngIf="chat.MessageType == 'text'" class="dark-grey-cl"> {{ chat.message }} </p>
                        <p *ngIf="chat.MessageType == 'image'"> <img src="{{ ChatUrl }}{{ chat.message }}" /> </p>
                        <small class="f-12 light-grey-cl">{{
                          convertTime(chat.created_on) | date: "hh:mm a"
                          }}</small>
                      </div>
                    </div>
                    <div *ngIf="chat.receiver_id != userinfo.id" class="chat_people d-flex">
                      <div class="chat_img position-relative"> <img *ngIf="chat.receiver_profile_picture != ''" src="{{ imageUrl }}{{
                              chat.receiver_profile_picture
                            }}" alt="image" /> <img *ngIf="chat.receiver_profile_picture === ''"
                          src="assets/images/dummy_user.png" alt="icon" />
                        <!-- <img class="w-100" src="https://ptetutorials.com/images/user-profile.png" alt="sunil">  -->
                        <div *ngIf="status" class="status position-absolute"></div>
                      </div>
                      <div class="chat_ib">
                        <div class="d-flex justify-content-between mb-2">
                          <h5 class="mb-0 dark-grey-cl" (click)="gotouserChat(chat.receiver_id, chat.hash, chat)">
                            {{ chat.receiverName }}
                          </h5>
                          <div class="more-option-chat">
                            <div class="dropdown">
                              <a class="btn position-relative chat_date" href="#" role="button" id="more"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <img
                                  class="position-absolute" src="assets/images/more-dots.svg" alt="icons" /> </a>
                              <div class="more-drop dropdown-menu" aria-labelledby="more" x-placement="bottom-start"
                                style="
                                    position: absolute;
                                    will-change: transform;
                                    top: 0px;
                                    left: 0px;
                                    transform: translate3d(0px, 20px, 0px);
                                  "> <a class="dropdown-item" (click)="deletchat(chat.receiver_id)">Delete</a> </div>
                            </div>
                          </div>
                        </div>
                        <p *ngIf="chat.MessageType == 'text'" class="dark-grey-cl mb-0"> {{ chat.message }} </p>
                        <p *ngIf="chat.MessageType == 'image'" class="dark-grey-cl"> <img
                            src="{{ ChatUrl }}{{ chat.message }}" /> </p> <small class="f-12 light-grey-cl">{{
                          convertTime(chat.created_on) | date: "hh:mm a"
                          }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 text-center" *ngIf="!chatlist.length">
                    <h5>No Messages</h5>
                    <p> You have no messages yet.Use the pencil icon to start new conversation with you friends. </p>
                    <img src="assets/images/no-msg-vector.svg" alt="vector" />
                  </div>
                </div>
                <!--when-there-is-list-->
              </div>
            </div>
            <!--msg-->
            <!--group-->
            <div class="tab-pane active" id="sds" [ngClass]="selectedTab == 'profile' ? 'show' : ''"
              *ngIf="selectedTab == 'profile'">
              <!--common-search-->
              <div class="common-search position-relative mx-3 mb-3">
                <form>
                  <div class="form-group mb-0">
                    <input type="email" id="searchText" name="searchText" [(ngModel)]="searchText"
                      class="search-input form-control" placeholder="Search..." />
                  </div>
                </form> <img class="position-absolute" src="assets/images/search-icon.svg" alt="icon" />
              </div>
              <!--common-search-->
              <div class="chat-listing-users">
                <!--when-no-msg-->
                <div class="no-msg text-center py-4 h-100 d-none"> <img src="assets/images/no-msg-vector.svg"
                    alt="vector" />
                  <h5 class="text-center">No Messages</h5>
                  <p class="text-center"> You have no messages yet.Use the pencil icon to start new conversation with
                    you friends. </p>
                  <!-- <a href="#" class="btn primary-blue-bg text-white big-btn">Add Friend</a> -->
                </div>
                <!--when-no-msg-->
                <!--when-there-is-list-->
                <div class="inbox_chat">
                  <div class="chat_list" *ngFor="let data of groupList | filter: searchText">
                    <div class="chat_people d-flex">
                      <div (click)="gtotogroupChat(data)">
                        <div class="chat_img">
                          <!-- <img class="w-100" src="assets/images/group-img.svg" alt="sunil"> --><img
                            *ngIf="data.group_pic != ''" src="{{ imageUrl }}{{ data.group_pic }}" alt="image" /> <img
                            *ngIf="data.group_pic === ''" src="assets/images/dummy_user.png" alt="icon" />
                        </div>
                        <div class="d-flex justify-content-between mb-2 flex-column">
                          <h5 class="mb-0 dark-grey-cl">
                            {{
                            data?.group_name != ""
                            ? data?.group_name
                            : data?.group_id
                            }}
                          </h5>
                          <!-- <span class="f-12 green-bg text-white px-2 py-1">On A Call</span> -->
                          <p class="dark-grey-cl mb-0">{{ data.msg }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!groupList.length">
                    <h5 class="text-center">No Group found</h5>
                    <p class="text-center"> You have not chat with any group yet! </p>
                    <img src="assets/images/no-group-chat-vector.svg" alt="vector" />
                  </div>
                </div>
                <!--when-there-is-list-->
              </div>
            </div>
            <!--group end-->
            <!------ Friend request section start ------>
            <div class="tab-pane active" [ngClass]="selectedTab == 'contact' ? 'show' : ''" id="sdl"
              *ngIf="selectedTab == 'contact'" role="tabpanel" aria-labelledby="contact-tab">
              <div *ngFor="let friend of FriendRequest" class="requests-sec p-3 inbox_chat">
                <div class="user-online d-flex justify-content-between mb-3">
                  <div class="friend-info d-flex justify-content-between">
                    <div class="mr-0 chat_img position-relative"> <img *ngIf="!friend.profile_image"
                        class="frient-request-img" src="assets/images/dummy_user.png" alt="image" /> <img
                        *ngIf="friend.profile_image != ''" src="{{ imageUrl }}{{ friend.profile_image }}" alt="image" />
                    </div>
                    <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">
                      {{ friend.name }}
                    </h5>
                  </div>
                  <div class="online-btns align-self-center">
                    <button (click)="acceptRequest(friend)" class="small-btn primary-blue-bg text-white f-14 mr-3">
                      Accept </button>
                    <button (click)="rejectRequest(friend)" class="small-btn light-grey-cl dark-grey-cl f-14"> Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="FriendRequest && FriendRequest.length == 0">
                <h5 class="text-center">No Friend Request</h5>
                <p class="text-center"> You have not recieved any friend request yet. </p>
              </div>
            </div>
            <!------- Friend Request section end-->
          </div>
        </div>

        <div class="mesgs">
          <div class="msg-occur">
            <div *ngIf="selectedTab == 'home' && !isSingleChatSelected && !newUserdata"
              class="w-100 d-flex align-items-center justify-content-center pt-4">
              <div class="pt-5">
                <img src="./assets/images/friend-request-vector.svg" alt="vector" />
              </div>
            </div>
            <div *ngIf="selectedTab == 'profile' && !groupmsg"
              class="w-100 d-flex align-items-center justify-content-center pt-4">
              <div class="pt-5">
                <img src="./assets/images/friend-request-vector.svg" alt="vector" />
              </div>
            </div>
            <!-- Single Chat------------------------------------------------------->
            <div *ngIf="selectedTab == 'home' && isSingleChatSelected">

              <div #scrollMe class="msg-info px-3 pt-2 text-white d-flex justify-content-between">
                <div class="user-info d-flex">
                  <span class="position-relative">
                    <img *ngIf="selectUserData()?.profile_image != ''" src="{{ imageUrl }}{{
                      selectUserData()?.profile_image
                    }}" alt="image" />
                    <img *ngIf="selectUserData()?.profile_image === ''" src="assets/images/dummy_user.png" alt="icon" />
                    <div class="status position-absolute"></div>
                  </span>
                  <div class="user-name pt-2 ml-3">
                    <h4 class="mb-0">
                      <a (click)="gotoUser(selectUserData()?.id)" class="text-white">{{
                        selectUserData()?.name }}</a>
                    </h4>
                  </div>
                </div>
                <div class="user-info-controls py-3">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <a (click)="
                    goToCall(
                      selectUserData()?.id,selectUserData()?.group_id 
                        )
                      "><img src="assets/images/video-chat-icon.svg" alt="icon" /></a>
                    </li>
                    <li class="list-inline-item">
                      <div class="dropdown">
                        <a href="#" class="dropdown-toggle a-drop" data-toggle="dropdown" id="dropdownMenuButton"
                          aria-haspopup="true" aria-expanded="false"><img src="assets/images/more-dot-icon.svg"
                            alt="icon" /></a>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <!-- <a class="dropdown-item chat-drop-menu" (click)="
                            unfriendFriend(selectUserData()?.id, '1')
                          ">Unfriend</a>
                          <a class="dropdown-item chat-drop-menu" (click)="blockedUser(selectUserData()?.id)">Report or
                            Block</a> -->
                          <ng-container class="dropdown-item chat-drop-menu">
                            <a class="dropdown-item chat-drop-menu" (click)="
                            unfriendFriend(selectUserData()?.id, '1')
                          ">Unfriend</a>

                            <a class="dropdown-item chat-drop-menu" *ngIf="
                                  chatData.friendStatus.RecieverIsBlock == '1'
                                " (click)="unblock(selectUserData()?.id)">
                              UnBlock</a>
                            <a class="dropdown-item chat-drop-menu" *ngIf="
                                  chatData.friendStatus.RecieverIsBlock != '1'
                                " (click)="blockedUser(selectUserData()?.id)">Report or Block</a>
                          </ng-container>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div *ngIf="chatHistory.length" class="msg_history" #scrollSingleChat>
                <div *ngFor="let chat of chatHistory">
                  <div *ngIf="chat.sender_id != userinfo.id" class="incoming_msg">
                    <!-- <div class="incoming_msg_img">
                      <img class=""
                        [src]="chat.sender_profile_picture ? imageUrl + chat.sender_profile_picture : 'assets/images/dummy_user.png'" alt="image" />
                    </div> -->
                    <div class="received_msg">
                      <div class="received_withd_msg" [ngClass]="{ 'img-msg': chat.MessageType == 'image' }">
                        <p *ngIf="chat.MessageType == 'text'" class="dark-grey-cl">
                          {{ chat.message }}
                        </p>
                        <p *ngIf="chat.MessageType == 'image'">
                          <img src="{{ ChatUrl }}{{ chat.message }}" />
                        </p>
                        <span class="time_date">{{
                          convertTime(chat.created_on) | date: "hh:mm a"
                          }}</span>
                      </div>
                      
                    </div>
                  </div>
                  <div class="outgoing_msg" *ngIf="chat.sender_id === userinfo.id">
                    <div class="sent_msg" [ngClass]="{ 'img-msg': chat.MessageType == 'image' }">
                      <p *ngIf="chat.MessageType == 'text'">
                        {{ chat.message }}
                      </p>
                      <p *ngIf="chat.MessageType == 'image'">
                        <img src="{{ ChatUrl }}{{ chat.message }}" />
                      </p>
                      <span class="time_date">{{
                        convertTime(chat.created_on) | date: 'medium'
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!chatHistory.length && newUserdata">
                <div class="new_chat_box w-100 m-auto text-center pt-5">
                  <img src="assets/images/new-chat.png" alt="icon" />
                  <p>Say "Hello" to start chat with your Friend</p>
                  <a (click)="sendHello(newUserdata)" type="button"
                    class="small-btn primary-blue-bg text-white btn f-14">Hello</a>
                </div>
              </div>
              <!-- chat footer -->
              <div *ngIf=" chatData?.friendStatus.SenderIsBlock === 0 && chatHistory && chatHistory.length != 0"
                class="type-msg position-relative mx-3">
                <!-- <img class="emoji-icon position-absolute" src="assets/images/emoj` -->
                <!-- <button class="send-btn position-absolute"><img src="assets/images/send-icon.png" alt="icon" /></button> -->
                <form #data="ngForm">
                  <a (click)="toggleEmojiPicker()"><img class="emoji-icon position-absolute"
                      src="assets/images/smile-icon.svg" alt="icon" /></a>
                  <textarea type="text" name="comment" [(ngModel)]="comment" autocomplete="off"
                    class="search-input form-control" placeholder="Type Comment..." (blur)="onBlur()"
                    (focus)="onFocus()" (keydown)="onKeyDown($event)"
                    (keyup.enter)="sendMsg(data, chatHistory)"></textarea>
                  <!-- </div> -->
                </form>
                <button class="send-btn position-absolute mr-3 sendbtn-two" (click)="sendimgMsg(data, chatHistory)">
                  <img class="sent-btn position-absolute" src="assets/images/big-beer-mug.svg" alt="icon" />
                </button>
                <button class="send-btn position-absolute" (click)="sendMsg(data, chatHistory)">
                  <img class="sent-btn position-absolute" src="assets/images/send-btn-icon.svg" alt="icon" />
                </button>
                <emoji-mart class="emoji-mart" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)"
                  title="Pick your emoji…"></emoji-mart>
              </div>
              <!-- chat footer end -->
            </div>
            <!-- Group------------------------------------------------------------->
            <div *ngIf="selectedTab == 'profile' && groupmsg" class="show-group-hd">
              <div class="msg-info px-3 pt-2 text-white d-flex justify-content-between align-items-center">
                <div data-toggle="modal" data-target="#group-edit-info" class="user-info d-flex">
                  <span data-toggle="modal" data-target="#group-edit-info" class="">
                    <img class="w-100" src="assets/images/dummy_user.png" alt="icon" />
                  </span>
                  <div class="user-name pt-2 ml-3">
                    <h5 *ngIf="currentGroupData?.group_name == ''" class="mb-0">
                      <a>{{ currentGroupData?.group_id }}</a>
                    </h5>
                    <h5 *ngIf="currentGroupData?.group_name != ''" class="mb-0">
                      <a>{{ currentGroupData?.group_name }}</a>
                    </h5>
                    <p class="f-12 text-white mb-0" *ngIf="currentGroupData?.members?.length>0">
                      {{ currentGroupData?.members?.length + 1 }} Members
                    </p>
                  </div>
                </div>
                <div class="user-info-controls py-3">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <a role="button" (click)="onGroupVideoCall()">
                        <img src="assets/images/video-chat-icon.svg" alt="icon" /></a>
                    </li>
                    <li class="list-inline-item">
                      <div class="dropdown">
                        <a href="#" class="dropdown-toggle a-drop" data-toggle="dropdown" id="dropdownMenuButton"
                          aria-haspopup="true" aria-expanded="false"><img src="assets/images/more-dot-icon.svg"
                            alt="icon" /></a>
                        <div class="dropdown-menu report-drop-down" aria-labelledby="dropdownMenuButton">
                          <a class="dropdown-item d-flex d-inline-block chat-drop-menu"
                            (click)="report(currentGroupData?.group_id)">Report or Block
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="msg_history" #scrollMe>
                <ng-container *ngIf="groupData.length">
                  <div *ngFor="let chat of groupData">
                    <div *ngIf="chat.UserID != userinfo.id" class="incoming_msg">
                      <div class="incoming_msg_img">
                      </div>
                      <div class="received_msg">
                        <div class="received_withd_msg" [ngClass]="{ 'img-msg': chat.msgType == 'image' }">
                          <p *ngIf="chat.msgType == 'text'" class="dark-grey-cl">
                            {{ chat.msg }}
                          </p>
                          <p *ngIf="chat.msgType == 'image'">
                            <img src="{{ ChatUrl }}{{ chat.msg }}" />
                          </p>
                          <span class="time_date">

                            {{ convertTime( chat.createdDate) | date: 'medium' }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="outgoing_msg">
                      <div *ngIf="chat.UserID === userinfo.id" class="sent_msg"
                        [ngClass]="{ 'img-msg': chat.msgType == 'image' }">
                        <p *ngIf="chat.msgType == 'text'">
                          {{ chat.msg }}
                        </p>
                        <p *ngIf="chat.msgType == 'image'">
                          <img src="{{ ChatUrl }}{{ chat.msg }}" />
                        </p>
                        <span class="time_date">
                          {{ convertTime( chat.createdDate) | date: 'medium' }}</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="type-msg position-relative mx-3">
                <form #data="ngForm">
                  <a (click)="toggleEmojiPicker()"><img class="emoji-icon position-absolute"
                      src="assets/images/smile-icon.svg" alt="icon" /></a>
                  <textarea type="text" name="comment" (keydown)="onKeyDown($event)" [(ngModel)]="comment"
                    autocomplete="off" class="search-input form-control" placeholder="Type Comment..." (blur)="onBlur()"
                    (keyup.enter)="sendgroupmsg(data, currentGroupData, 'text')" (focus)="onFocus()"></textarea>
                </form>
                <button class="send-btn position-absolute mr-3 sendbtn-two"
                  (click)="sendgroupmsg(data, currentGroupData, 'image')">
                  <img class="sent-btn position-absolute" src="assets/images/big-beer-mug.svg" alt="icon" />
                </button>
                <button class="send-btn position-absolute" (click)="sendgroupmsg(data, currentGroupData, 'text')">
                  <img class="sent-btn position-absolute" src="assets/images/send-btn-icon.svg" alt="icon" />
                </button>
                <emoji-mart class="emoji-mart" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)"
                  title="Pick your emoji…"></emoji-mart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="modal fade" id="group-edit-info" aria-modal="true">
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title light-grey-cl">Group Members</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body edit-group-sec text-center my-3">
        <div class="group-img-big mb-3">
          <img class="mb-2" src="assets/images/dummy_user.png" alt="image" />
        </div>
        <div>
          <div class="g-name position-relative mb-1">
            <input type="text" class="form-control" [readonly]="currentGroupData?.admin?.id != userinfo?.id"
              [(ngModel)]="groupname" name="groupname"
              (keyup.enter)="updateGroup(currentGroupData?.group_privacy, currentGroupData?.group_id)"
              placeholder="Enter group name" />
            <div class="">
              <a *ngIf="currentGroupData?.admin?.id == userinfo?.id"
                (click)="updateGroup(currentGroupData?.group_privacy, currentGroupData?.group_id)"><img
                  class="position-absolute" src="assets/images/edit-icon.svg" alt="icons" /></a>
            </div>
            <div class="g-info d-flex justify-content-between mb-3">
              <small class="light-grey-cl f-12">Created on:{{ currentGroupData?.group_date | date }}</small>
              <small class="primary-blue-cl f-12">Private</small>
            </div>
          </div>
          <div class="g-count d-flex justify-content-between p-2 mb-3">
            <h5 class="dark-grey-cl mb-0">Group Members</h5>
            <p class="yellow-cl mb-0">{{ currentGroupData?.members?.length }}</p>
          </div>
          <div *ngFor="let data of currentGroupData?.members" class="members-sec p-2">
            <div class="g-members d-flex justify-content-between mb-2">
              <div class="name-sec d-flex">
                <img class="img-fluid" *ngIf="data?.profile_image === ''" src="assets/images/dummy_user.png"
                  alt="image" />
                <img class="img-fluid" *ngIf="data?.profile_image != ''" src="{{ imageUrl }}{{ data?.profile_image }}"
                  alt="image" />
                <!-- <img src="assets/images/live-user-img.svg" alt="image"> -->
                <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">
                  {{ data?.name }}
                </h5>
              </div>
              <p *ngIf="currentGroupData?.admin?.id == data.member_id"
                class="light-grey-cl f-14 align-self-center mb-0">
                Admin
              </p>
              <p *ngIf="currentGroupData?.admin?.id != data.member_id"
                class="light-grey-cl f-14 align-self-center mb-0">
                Member
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--group-video-call-->
<div class="modal fade" id="make-group-call" aria-modal="true">
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title light-grey-cl">Add Member in this Video Call</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body edit-group-sec text-center my-3">
        <div class="group-img-big mb-3">
          <img class="mb-2" src="assets/images/dummy_user.png" alt="image" />
          <!-- <p class="f-18">{{currentGroupData}}</p> -->
        </div>
        <div class="g-count d-flex justify-content-between p-2 mb-3">
          <h5 class="dark-grey-cl mb-0">Group Members</h5>
          <p class="yellow-cl mb-0">{{ currentGroupData?.members?.length }}</p>
        </div>
        <div class="members-sec p-2">
          <div *ngFor="let data of currentGroupData?.members; let i = index"
            class="g-members d-flex justify-content-between mb-2">
            <div class="d-flex">
              <div class="img-wrap">
                <img class="img-fluid" src="assets/images/dummy_user.png" alt="image" />
              </div>
              <div class="name-sec d-flex">
                <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">
                  {{ data?.name }}
                </h5>
              </div>
            </div>
            <div class="round friend-select align-self-center">
              <input (click)="chooseUsers($event, data, i)" type="checkbox" [id]="i" />
              <label [for]="i"><img src="assets/images/circle-check.svg" alt="icon" /></label>
            </div>
          </div>
          <!-- <div class="g-members d-flex justify-content-between mb-2">
						<div class="name-sec d-flex">
							<img src="assets/images/live-user-img.svg" alt="image">
							<h5 class="mb-0 align-self-center ml-3 dark-grey-cl">Rossey Mitheni</h5>
						</div>
						<div class="round friend-select align-self-center">
							<input type="checkbox" id="checkbox2">
							<label for="checkbox2"><img src="assets/images/circle-check.svg" alt="icon"></label>
						</div>
					</div> -->
          <!-- <div class="g-members d-flex justify-content-between mb-2">
						<div class="name-sec d-flex">
							<img src="assets/images/live-user-img.svg" alt="image">
							<h5 class="mb-0 align-self-center ml-3 dark-grey-cl">Rossey Mitheni</h5>
						</div>
						<div class="round friend-select align-self-center">
							<input type="checkbox" id="checkbox3">
							<label for="checkbox3"><img src="assets/images/circle-check.svg" alt="icon"></label>
						</div>
					</div>
					<div class="g-members d-flex justify-content-between mb-2">
						<div class="name-sec d-flex">
							<img src="assets/images/live-user-img.svg" alt="image">
							<h5 class="mb-0 align-self-center ml-3 dark-grey-cl">Rossey Mitheni</h5>
						</div>
						<div class="round friend-select align-self-center">
							<input type="checkbox" id="checkbox4">
							<label for="checkbox4"><img src="assets/images/circle-check.svg" alt="icon"></label>
						</div>
					</div> -->
          <div class="pt-2">
            <button (click)="sendNotification()" class="primary-blue-bg text-white big-btn">
              Start Video Call
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--add-new-msg-call-->
<div class="modal fade" id="add-new-chat" aria-modal="true">
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title light-grey-cl">Add New Message</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body edit-group-sec text-center">
        <!--common-search-->
        <div class="common-search position-relative mx-3 mb-3">
          <form>
            <div class="form-group mb-0">
              <input type="email" class="search-input form-control" [(ngModel)]="searchall" autocomplete="off"
                name="searchall" placeholder="Search..." />
            </div>
          </form>
          <img class="position-absolute" (click)="searchFriendList(searchall)" src="assets/images/search-icon.svg"
            alt="icon" />
        </div>
        <div *ngIf="Friendlist && Friendlist.length > 0" class="members-sec p-2">
          <div *ngFor="let friend of Friendlist | filter: searchall; let i = index"
            class="g-members d-flex justify-content-between mb-2">
            <div class="name-sec d-flex">
              <!-- <img src="assets/images/live-user-img.svg" alt="image"> -->
              <img *ngIf="friend?.profile_image === ''" src="assets/images/dummy_user.png" alt="image" />
              <img *ngIf="friend?.profile_image != ''" src="{{ imageUrl }}{{ friend?.profile_image }}" alt="image" />
              <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">
                {{ friend?.name }}
              </h5>
            </div>
            <p class="common-radio">
              <!-- <input (click)="startChat(friend)" type="radio" class="checkbox1" name="abc" /> -->
              <!-- <label for="checkbox1"><img src="assets/images/circle-check.svg" alt="icon" /></label> -->
              <input type="radio" id="{{ friend?.name }}" (click)="startChat(friend)" name="radio-group" />
              <label for="{{ friend.name }}"></label>
            </p>
          </div>
          <div class="pt-2">
            <a (click)="goToNewChat()" class="primary-blue-bg text-white btn big-btn">Start Chat</a>
          </div>
          <div *ngIf="Friendlist && Friendlist.length == 0">
            <p>No Friend</p>
          </div>
        </div>
        <div *ngIf="Friendlist && Friendlist.length == 0">No User Found</div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="delete">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body invite-sent-msg text-center m-3">
        <p>Are you sure want to Delete all chat History</p>
        <div class="btn-sec">
          <button type="button" class="small-btn light-grey-bg dark-grey-cl mr-2" (click)="deleteAll(chatlist)">
            Yes
          </button>
          <button type="button" class="small-btn primary-blue-bg text-white" (click)="cancel()" aria-label="Close">
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</div>