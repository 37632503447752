import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  params: any;

  constructor(private service: ApiService, private common: CommonService, private route: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((res: any) => {
      this.params = res;
    });
  }
  report(data: NgForm) {
    console.log(data, "dat")
    var fd = new FormData
    fd.append("name", data.form.value.name);
    fd.append("subject", data.form.value.subject);
    fd.append("message", data.form.value.message);
    fd.append('reportUserId', this.params),
      fd.append('groupId', '')

    // Object.keys(data.form.controls).forEach(key => {

    //   console.log(key,"key")
    //   data.form.setControl.t;
    // });   

    this.service.report(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.hideSpinner();
        // data.reset();
        // data.form.markAllAsTouched();
        this.route.navigate(['/home'])
        this.common.success(res.message)


      }
      else {
        this.common.hideSpinner()
        this.common.error(res.message);

      }
    })
  }

}
