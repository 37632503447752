import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api-service/api.service';
import { CommonService } from './common/common.service';

@Injectable({
  providedIn: 'root'
})
export class GroupCallStatusService {
  private isGroupCallPageCalled: BehaviorSubject<boolean>;
  constructor(
    private service: ApiService,
    private common: CommonService,
  ) { 
    this.isGroupCallPageCalled = new BehaviorSubject<boolean>(false);
  }

  setGroupCallStatus(status: boolean): void {
    this.isGroupCallPageCalled.next(status);
  }

  getGroupCallStatus(): Observable<boolean> {
    return this.isGroupCallPageCalled.asObservable();
  }
  
  async joniLeaveGroupCall(groupId:any, isCompleted:any = 0){
    let data = {
      "changestate": 1, 
      "groupId": groupId,
      "iscallCompleted": isCompleted
    }
  await this.service.joinGroupCall(data).subscribe((res: any) => {
      this.common.hideSpinner();
      if(res['success'] == 1){
        
      }
    })
  }
}
