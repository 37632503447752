import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShowApproximatelyDialog } from 'src/app/_helper/dialogs/dialog-references';
declare var $: any
@Component({
  selector: 'app-set-level',
  templateUrl: './set-level.component.html',
  styleUrls: ['./set-level.component.scss']
})
export class SetLevelComponent implements OnInit {
  @ViewChild('setLevel') setLevel: ElementRef;
  genderList: { name: string; value: string; isActive: boolean; image: string; }[];
  drink: any;
  gender: any;
  time: string;
  calculation: any;

  constructor(
    private common: CommonService,
    private service: ApiService,
    private router: Router,
    private atp: AmazingTimePickerService,
    private modalService: NgbModal,
  ) {
    this.modalService.open(this.setLevel, { 
      size: 'medium', 
      ariaLabelledBy: 'modal-basic-title', 
      // backdrop: 'static' 
    });

  }
  DrinkList: any = [];
  ngOnInit(): void {
    this.DrinkList = [
      { name: 'Beer', value: 'Beer', isActive: false, image: 'assets/images/wine-vector.svg' },
      { name: 'Wine', value: 'Wine', isActive: false, image: 'assets/images/wine-vector.svg' },
      { name: 'Highball Cocktails', value: 'Highball Cocktails', isActive: false, image: 'assets/images/cocktail-vector.svg' },
      { name: 'Shots', value: 'Shots', isActive: false, image: 'assets/images/shots-vector.svg' }
    ];
    this.genderList = [
      { name: 'Male', value: 'Male', isActive: false, image: 'assets/images/male-icon.svg' },
      { name: 'Female', value: 'Female', isActive: false, image: 'assets/images/female-icon.svg' },

    ]
  }
  // getAlldrinks(){
  //   this.service.getFriendList('').subscribe((res: any) => {
  //     if (res['success'] == 1) {
  //       this.drinkList= res.data
  //       console.log(this.drinkList)
  //     }
  //   })
  // }


  setAlchollevel(level: NgForm) {
    if (this.gender == '' || this.gender == undefined) {
      return this.common.error('Select gender')
    }
    if (this.drink == '' || this.drink == undefined) {
      return this.common.error('Select Drink')
    }
    if(level.form.value.weight == ''){
      return this.common.error('Please enter weight')
    }
    if (this.time == '' || this.time == undefined) {
      return this.common.error('Please enter time')
    }
    var fd = new FormData()
    fd.append("gender", this.gender);
    fd.append("drink", this.drink);
    fd.append("weight", level.form.value.weight);
    fd.append("time", this.time);

    this.service.setAlcoholLevel(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.success(res.message);
        this.common.hideSpinner();
        this.modalService.dismissAll();
        level.form.reset();
        this.showApproximately(res);
      } else {
        this.common.error(res.message);
      }
    })
  }

  showApproximately(res:any){
    this.gender = undefined;
    this.time = '';
    const modalRef = this.modalService.open(ShowApproximatelyDialog, {
      ariaLabelledBy: 'modal-basic-title'
    });
    modalRef.componentInstance.data = res.calculation;
  }

  ChangeTogglelevel(event: any) {
    const input: any = document.getElementById("d2");
  }

  sortdrink(drink: any, e: any) {
    e.target.classList.add('drink-icon-active');
    for (let i = 0; i <= 4; i++) {
      const currentId = e.target.id;
      this.drink = drink;
      const ids = 'cat' + i;
      if (currentId !== ids) {
        $('#' + ids).removeClass('drink-icon-active');
      }
    }
    // this.category = category;
  }
  sortgender(gender: any, e: any) {
    e.target.classList.add('drink-icon-active');
    for (let i = 0; i <= 2; i++) {
      const currentId = e.target.id;
      this.gender = gender;
      const ids = 'get' + i;
      if (currentId !== ids) {
        $('#' + ids).removeClass('drink-icon-active');
      }
    };
  }

  open(event: any) {
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe(time => {
      this.time = time;
    });

  }
}
