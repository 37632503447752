<section class="login-block">
    <!-- login start -->
    <!-- left side start -->
    <div class="left-side-bg">
        <img src="assets/images/bottoms-up-text.svg">
    </div>
    <!-- right side start -->
    <div class="right-side-block otp">
        <div class="welcome-block">
            <h1>Verify Your {{type}} </h1>
            <p>Enter Your Confirmation Code.</p>
            <div class="info-box p-3">
                <p class="mb-0">Enter the 4 digit OTP sent to your registered {{type}}.</p>
            </div>
            <!-- form start -->
            <form class="register-block text-center">
                <div class="form-group mb-3">
                    <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:4}"></ng-otp-input>
                    <button (click)="submit()" class="btn account-but my-3" href="javascript:;"
                        role="button">Submit</button>
                </div>
            </form>
            <!-- form close-->
            <p class="yellow-cl mb-0" *ngIf="count > 0">(00:{{count}})</p>
            <p class="mb-0" *ngIf="count == 0">Did not receive the code? <span><a class="primary-blue-cl" (click)="resendOtp()">
                        Resend</a></span></p>
            <!-- <p>Don't have an account? <span><a class="primary-blue-cl" routerLink="/login">Sign In</a></span></p> -->
        </div>
    </div><!-- right side close -->
</section>