import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  countInterval: any;
  count: number
  otp: any;
  type: any
  flags = {
    isOtpVerify: false,
    isResend: false
  }
  deviceId: string;
  constructor(
    private api: ApiService,
    private common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((res: any) => {
      this.type = res['type'];
    })
    this.countdown();
  }
  onOtpChange(evnt: any) {
    this.otp = evnt
  }
  countdown() {
    this.count = 60;
    this.countInterval = setInterval(() => {
      if (this.count == 0) {
        return clearInterval(this.countInterval);
      }
      this.count--;
    }, 1000);
  }

  resendOtp() {
    if (this.type == 'Email') {
      this.api.ResendOtpByEmail('').subscribe((res: any) => {
        if (res['success'] == 1) {
          this.common.hideSpinner();
          this.common.success(res.message)
          this.countdown();
        }
        else {
          this.common.hideSpinner();
          this.common.error(res.message);
        }
      });
    } else {
      this.api.ResendOtpByPhone('').subscribe((res: any) => {
        if (res['success'] == 1) {
          this.common.hideSpinner();
          this.common.success(res.message)
          this.countdown();
        }
        else {
          this.common.hideSpinner();
          this.common.error(res.message);
        }
      });
    }
  }

  submit() {
    if (!this.otp)
      return this.common.error('Please Enter your Otp');
        var fd = new FormData();
        fd.append("otp", this.otp);
        if (this.type === 'Email') {
          this.api.OtpByEmail(fd).subscribe((res: any) => {
            if (res['success'] == 1) {
              this.common.hideSpinner();
              this.common.success(res.message);
              // this.getProfile();
              if (localStorage.getItem('forgotPassword') === '1') {
                this.router.navigate(['/reset_password'])
              }
              if (localStorage.getItem('ProfileStatus') && localStorage.getItem('forgotPassword') != '1') {
                this.router.navigate(["/start_up_home"]);
              }
              if (localStorage.getItem('ProfileStatus') != '1' && localStorage.getItem('forgotPassword') != '1') {
                this.router.navigate(['/profile'])
              }
            }
            else {
              this.common.hideSpinner();
              this.common.error(res.message);
            }
          })
        }
        if (this.type === 'Phone') {
          this.api.OtpByPhone(fd).subscribe((res: any) => {
            if (res['success'] == 1) {
              this.common.hideSpinner();
              this.getProfile();
            } else {
              this.common.hideSpinner();
              this.common.error(res.message);
            }
          })
        }
  }

  getProfile() {
    this.api.getProfile('').subscribe((res: any) => {
      if (res['success'] == 1) {
        localStorage.setItem("Bottom_up_user", JSON.stringify(res["data"]));
        this.common.hideSpinner();
        if (localStorage.getItem('ProfileStatus') === '1') {
          this.router.navigate(["/start_up_home"]);
        }
      } else {
        // this.common.hideSpinner();
        // this.common.error(res.message);
      }
    })
  }
}
