import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SetLevelComponent } from 'src/app/Pages/set-level/set-level.component';
@Component({
  selector: 'set-alcohol-level',
  templateUrl:'./set-alcohol-level.html',
  styleUrls:['./dialog-references.scss']
})
export class SetAlcoholLevelDialog {
  constructor(
    private modalService: NgbModal,
  ) {
  }

  onNoClick(): void {
    this.modalService.dismissAll();
  }

  setlevel(){
    this.modalService.dismissAll();
    this.modalService.open(SetLevelComponent, {
      ariaLabelledBy: 'modal-basic-title', 
      // backdrop: 'static' 
    });

  }
}


@Component({
  selector: 'show-approximately',
  templateUrl:'./show-approximate-level.html',
  styleUrls:['./dialog-references.scss']
})
export class ShowApproximatelyDialog implements OnInit {
  @Input() public data:any;
  constructor(
    private modalService: NgbModal,
  ) {}

  ngOnInit(){
  }

  onNoClick(): void {
    this.modalService.dismissAll();
  }
}

