import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import Swal from 'sweetalert2';
import { SocketService } from '../../services/socketService/socket.service';
declare const videojs: any;
@Component({
  selector: 'app-other-user-profile',
  templateUrl: './other-user-profile.component.html',
  styleUrls: ['./other-user-profile.component.scss']
})
export class OtherUserProfileComponent implements OnInit {
  userId: any;
  userData: any;
  privacyInfo: any
  imageUrl: string;
  unfriend: boolean = true;
  addfriend: boolean = false;
  request: any;
  url: any = 'https://bu-bucket-for-testing.s3.ap-south-1.amazonaws.com/'
  userinfo: any;
  videoData: any;
 
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private service: ApiService,
    private common: CommonService,
    private socket: SocketService,
    private renderer:Renderer2,
    @Inject(DOCUMENT) private document: any) {

    this.activatedRoute.params.subscribe((res: any) => {
      
      this.userId = res.id;
      console.log(this.userId, "parasm");
    });
  }

  ngOnInit(): void {
    this.getAllsaveVideo();
    if (localStorage.getItem('Bottom_up_user')) {
      this.userinfo = JSON.parse(localStorage.getItem('Bottom_up_user') || '{}');
    }
    this.imageUrl = this.service.imageUrl
    this.getuserdetail()
    this.getAllVideo()
  }

  getuserdetail() {
    var fd = new FormData()
    fd.append("userId", this.userId)
    this.service.getUserInfo(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.userData = res.data;
        this.request = res.request;
        this.privacyInfo = res.privacyInfo;
        this.common.hideSpinner();
      }
    })
  }
  getAllVideo() {

    let script = this.renderer.createElement('script');
     script.src = "https://vjs.zencdn.net/7.2.3/video.js";

     
     this.renderer.appendChild(document.body,script);

    setTimeout(() => {
      for (var i = 0; i < this.videoData.length; i++) {
        var player = videojs('hls-example' + i);
        console.log(player)
        // player.play();
      }
     }, 2000);
  }
  unfriendUser(Status: any) {
    var fd = new FormData();
    fd.append("friend", this.userId)
    fd.append("status", Status)
    this.service.unfriend(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.success(res.message);
        this.common.hideSpinner();
        this.unfriend = false;
        this.addfriend = true;
        this.unfriedUser(this.userId);
      }
      else {
        this.common.error(res.message);
        this.common.hideSpinner()
      }
    })
  }

  unfriedUser(id: any) {
    let data = {
      'userID': this.userinfo.id,
      'serviceType': 'NotifyUserWhenUnfriend',
      'recieverID': id
    }
    this.socket.sendMessage(data)
  }

  reportUser(data: any) {
    this.router.navigate(['/report', data.id])
  }

  blockuser() {
    Swal.fire({
      text: `Are you sure you want to block ${this.userData.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28A0B5',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result: any) => {
      if (result.isConfirmed) {
        var fd = new FormData();
        fd.append("friendId", this.userId);
        this.service.blockUser(fd).subscribe((res: any) => {
          if (res['success'] == 1) {
            this.common.success(res.message);
            let data = {
              serviceType: 'NotifyUserWhenBlock', 
              userID:this.userinfo.id, 
              recieverID:this.userId
            }
            console.log(data);
            this.socket.sendMessage(data);
            this.common.hideSpinner();
          }
          else {
            this.common.error(res.message);
            this.common.hideSpinner()
          }
        })
      }

    })

  }

  getAllsaveVideo() {
    var fd = new FormData()
    fd.append("userId", this.userId)
    this.service.getallfriendsvideo(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.videoData = res.data
        // this.common.success(res.message);
        this.common.hideSpinner();
        // this.router.navigate([''])
      }
      else {
        this.common.error(res.message);
        this.common.hideSpinner()
      }
    })
  }
}
