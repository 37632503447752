import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import Swal from 'sweetalert2';
import { MessagingService } from 'src/app/services/messaging.service';
import { LocalStorageService } from 'angular-web-storage';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  initialConfig: any = {
    initialCountry: 'us',
    preferredCountries: ['us', 'in'],
    separateDialCode: false,
  }
  profileImage: any;
  File: any;
  email: any;
  countryCode: any = '';
  phoneStatus: boolean = false
  emailStatus: boolean = false
  type: string;
  phoneNumber: any;
  phone: any;
  config: {};
  bsInlineValue = new Date();
  minDate = new Date();
  userProfile: any = [];
  code: any = '91';
  isValid: any;

  constructor(
    private router: Router,
    private common: CommonService,
    private service: ApiService,
    private messagingService: MessagingService,
    private localStorage: LocalStorageService,
  ) {
  }



  ngOnInit(): void {
    this.service.getProfile('').subscribe((res: any) => {
      if (res["success"] == 1) {
        this.userProfile = res.data;
        this.phoneNumber = this.userProfile.phone;
        if (this.userProfile.email_verification_status === '1') {
          this.emailStatus = true;
        } else {
          this.phoneStatus = true;
        }
        if (this.userProfile.email_verification_status === '1' && this.userProfile.phone_verification_status === '1') {
          this.emailStatus = true;
          this.phoneStatus = true;
        }
        this.common.hideSpinner();
      } else {
        this.userProfile = [];
        this.phoneNumber = '';
        this.common.hideSpinner();
      }
    })

    this.router.events.subscribe((val: any) => {
      setTimeout((e: any) => {
        window.history.forward()
      }, 0)
      window.onunload = function () { null };
      if (val.url == '/otp') {
        Swal.fire('Oops...', 'Exiting the page will cancel the process', 'error');
        return;
      }
    })
  }

  selectionChanged(e: any) { }
  gotoHome() {
    this.router.navigate(['/start_up_home'])
  }

  Profile(form: NgForm) {
    var fd = new FormData();
    if (this.emailStatus === true) {
      this.type = 'Phone';
      fd.append("phone", '+'+this.code +' '+form.form.value.phoneNumber);
      fd.append("email", this.userProfile.email);
    } else {
      this.type = 'Email';
      fd.append("email", form.form.value.email);
      fd.append("phone",  '+'+this.code +' '+form.form.value.phoneNumber);
    }

    fd.append("name", form.form.value.name);
    fd.append("city", form.form.value.City);
    fd.append("devicetype", 'web');
    fd.append('devicetoken', this.messagingService.deviceToken);
    var date = new Date(form.form.value.dob),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    fd.append("dob", [date.getFullYear(), mnth, day].join("-"));
    fd.append("state", form.form.value.state);
    fd.append("address", form.form.value.address);
    fd.append("bio", form.form.value.bio);
    fd.append("profile_image", this.File ? this.File : '');
    let deviceId:any = localStorage.getItem('deviceId');
    fd.append("devId", deviceId);
    fd.append("token", this.localStorage.get('token'));
    this.service.completeProfile(fd).subscribe((res: any) => {
      if (res["success"] == 1) {
        this.common.hideSpinner();
        if ((this.emailStatus === true && this.phoneStatus === true) || this.userProfile.phone_verification_status == '1') {
          this.common.success(res["message"]);
          this.router.navigate(["/start_up_home"]);
        } else {
          localStorage.setItem("ProfileStatus", '1');
          this.router.navigate(['/otp', {type:this.type}]);
        }
      } else {
        this.common.hideSpinner();
        this.common.error(res["message"]);
      }
    })

  }


  telInputObject(obj: any) {
    obj.setCountry('in');
  }
  getNumber(event: any) {
    console.log(event);
  }

  onCountryChange(event: any) {
    this.code = event.dialCode;
  }

  getTimeSlots(time: any) {
    console.log(time, "time")
  }
  async profilePic(event: any) {
    this.File = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        this.profileImage = event.target.result;
      };
    }
  }

  hasError(event: any) {
    this.isValid = event;
  }

  validateNumber() {
    if (this.phoneNumber != '' && this.phoneNumber != undefined) {
      if (!!this.phoneNumber && this.phoneNumber.charAt(0) == 0) {
        // return this.common.presentToast('warning','top-end', 'Number must not start with 0');
      }
      var numbers = /^[0-9]+$/;
      if (this.phoneNumber.match(numbers)) {
      } else {
        // this.commonService.presentToastWithoutBtn('Invalid phone number', 2000, 'dark');
      }
    }
  }
}
