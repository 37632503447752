<!----Setting-Section---->
<!-- <app-header></app-header> -->
<section class="set-level-sec">
  <div class="container-fluid">
    <div class="setting-box m-auto">
      <h4 class="dark-grey-cl mb-3 pb-3">Set Following to get your
        <br>Alcoholic level
      </h4>
      <div class="set-level-form text-left">
        <form #setlevel="ngForm" (ngSubmit)="setAlchollevel(setlevel)">
          <div class="level-field mb-3">
            <label class="primary-blue-cl">Select Gender</label>
            <div class="full-radio-btn">
              <!-- <div class="full-radio-btn"> -->
              <button id="get{{i}}" [ngClass]="{'drink-icon-active':list.isActive}"
                *ngFor="let list of genderList; let i = index" type="button"
                (click)="sortgender(list.value,$event)"><img class="border-0" src="{{list.image}}" alt="icon" />{{list.name}}
              </button>
              <!-- <input type="radio" id="g1" name="radioFruit" value="apple" checked>
                      <label for="g1"><img class="mr-1" src="assets/images/male-icon.svg" alt="icon"/> Male</label>
                      <input type="radio" id="g2" name="radioFruit" value="banana">
                      <label for="g2"><img class="mr-1" src="assets/images/female-icon.svg" alt="icon"/> Female</label> -->
            </div>
          </div>
          <div class="level-field mb-3">
            <label class="primary-blue-cl">Select Drink</label>
            <div class="full-radio-btn">
              <button id="cat{{i}}" [ngClass]="{'drink-icon-active':drink.isActive}"
                *ngFor="let drink of DrinkList; let i = index" type="button"
                (click)="sortdrink(drink.value,$event)"><img src="{{drink.image}}" alt="icon" />{{drink.name}}
              </button>
              <!-- <input type="radio" id="d1" name="radioFruit" value="apple" (change)="ChangeTogglelevel($event)"  checked>
                      <label for="d1"><img class="mr-1" src="assets/images/beer-vector.svg" alt="icon"/> Beer <small class="f-12">/ 12 OZ</small></label>
                      <input type="radio" id="d2" name="radioFruit" (change)="ChangeTogglelevel($event)" value="banana">
                      <label for="d2"><img class="mr-1" src="assets/images/wine-vector.svg" alt="icon"/>Wine  <small class="f-12">/ 5 OZ</small></label>
                      <input type="radio" id="d3" name="radioFruit" value="banana">
                      <label for="d3"><img class="mr-1" src="assets/images/cocktail-vector.svg" alt="icon"/>Highball Cocktails</label>
                      <input type="radio" id="d4" name="radioFruit" value="banana">
                      <label for="d4"><img class="mr-1" src="assets/images/shots-vector.svg" alt="icon"/>Shots <small class="f-12">/ 40%</small></label> -->
            </div>
          </div>
          <div class="level-field mb-3">
            <label class="primary-blue-cl">Body Weight in Pounds</label>
            <input class="form-control" type="text" appDecimalnumber placeholder="Enter Weight" maxlength="3"
              autocomplete="off" #weight="ngModel" name="weight" ngModel required />
          </div>
          <!-- <div *ngIf="setlevel.submitted && weight.invalid ">
                <div class="alert alert-danger" *ngIf="weight.errors?.required">
                  weight is required
                </div>
              </div> -->
          <div class="level-field mb-3">
            <label class="primary-blue-cl">Time Since First Drink</label>
            <div class="row">
              <div class="col">
                <input type="text" value="" name="time" (click)=open($event) class="form-control" id="usr"
                  autocomplete="off" [(ngModel)]="time" placeholder="00 : 00" required />
                <!-- <owl-date-time #dt></owl-date-time> -->

                <!-- <small id="emailHelp" class="form-text text-muted">Hours</small> -->
              </div>
            </div>
          </div>
          <div class="set-btns d-flex justify-content-between mt-5">
            <button class="big-btn primary-blue-bg text-white" type="submit">Submit</button>
            <!-- <a class="big-btn primary-blue-bg text-white" href="#" data-target="#alchoholic-level" data-toggle="modal">Submit</a> -->
          </div>
        </form>
      </div>

    </div>
  </div>
</section>
<!----Setting-Section---->