<app-headerforcalling></app-headerforcalling>
<section class="main-video-screen position-relative">
  <div class="video-sec">
    <div class="person-box">
      <div class="conatiner-fluid">
        <div class="row no-gutters">
          <div class="col-md-6">
            <div class="video-capt position-relative d-flex">
              <div *ngFor="let callId of remoteStream" [id]="callId.id" class="remote"
                (click)="showRemoteVideoControls(callId.stream)"></div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="video-capt">
              <div [id]="localCallId" class="local"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!------ Remote control icons ------>
    <div class="position-absolute video-capt-controls" *ngIf="showRemoteControlButtons">
      <div class="control-icons d-inline-block">
        <a class="p-3 d-block" (click)="showVolumeRange()">
          <img class="volume-img" src="./assets/images/volume-pop.svg" alt="icon" />
        </a>
        <div class="volume d-inline-block" *ngIf="showRange">
          <div class="form-group mb-0">
            <input type="range" class="form-control-range" id="formControlRange" (change)="rangeSelect($event)">
          </div>
        </div>
        <a class="p-3 d-block" (click)="muteUnmuteRemote()">
          <img class="mic-img" *ngIf="isRemoteAudioEnabled" src="./assets/images/mic.svg" alt="icon" />
          <img class="mic-img" *ngIf="!isRemoteAudioEnabled" src="./assets/images/off-mike.png" alt="icon" />
        </a>
        <a class="p-3 d-block"><img class="level-img" src="./assets/images/level-icon.svg" alt="icon" /></a>
      </div>
    </div>
    
    <!------ Remote control icons end ------>
    <div class="camera-controls position-absolute text-center">
      <div class="video-call-options mt-2 d-flex primary-blue-bg justify-content-between">
        <a (click)="enableDisableCamera()">
          <img *ngIf="isVideoEnabled" src="./assets/images/video-camera.png" alt="icon" height="30" width="30" />
          <img *ngIf="!isVideoEnabled" src="./assets/images/no-video-camera.png" alt="icon" height="30" width="30" />
        </a>
        <!-- <a><img src="assets/images/volume.svg" alt="icon" /></a> -->
        <a (click)="muteUnmuteAudio()">
          <img *ngIf="isAudioEnabled" src="./assets/images/mic.png" alt="icon" />
          <img *ngIf="!isAudioEnabled" src="./assets/images/off-mike.png" alt="icon" />
        </a>
        <a class="red-bg" (click)="leave()"><img src="./assets/images/drop-phone-icon.svg" alt="icon" /></a>
        <a (click)="lockstatus()">
          <img *ngIf="!isPublic" src="./assets/images/unlocked.png" alt="icon" />
          <img *ngIf="isPublic" src="./assets/images/lock.png" alt="icon" />
        </a>
        <a (click)="setAlcoholLevel()">
          <img src="./assets/images/beer-icon.png" width="40" alt="icon" />
        </a>
      </div>
    </div>
  </div>
</section>