import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { CommonService } from "src/app/services/common/common.service";
import { ApiService } from "src/app/services/api-service/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { SubjectService } from "src/app/services/subject.service";
import { SocketService } from "src/app/services/socketService/socket.service";
import { NgxAgoraService, AgoraClient, Stream, ClientEvent, StreamEvent, AgoraConfig } from 'ngx-agora';
import { SetAlcoholLevelDialog, ShowApproximatelyDialog } from "src/app/_helper/dialogs/dialog-references";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IMediaTrack } from 'ngx-agora-sdk-ng';
import { GroupCallStatusService } from "src/app/services/group-call-status.service";
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LocationStrategy } from '@angular/common';
import { LocalStorageService } from 'angular-web-storage';
@Component({
  selector: "app-group-call",
  templateUrl: "./group-call.component.html",
  styleUrls: ["./group-call.component.scss"]
})
export class GroupCallComponent implements OnInit, OnDestroy {
  private client: AgoraClient;
  private localStream: Stream;
  public remoteStream = new BehaviorSubject<any[]>([]);
  profileForm: any;
  showRemoteControlButtons: boolean = false;
  isRemoteAudioEnabled: boolean = true;
  remoteControlStream: any;
  showRange: boolean = false;
  asd: StreamEvent;
  isJoined = false;
  selectedRemoteUser = '';
  isChatShown: boolean = false;
  recieverIds: any;
  uniqueId: number;
  volumeData: any = 100;
  localCallId = "agora_local";
  rtcToken: any;
  isAudioEnabled: boolean = true;
  isVideoEnabled: boolean = true;
  isPublic: boolean = false;
  userinfo: any;
  appToWeb = false;
  timoutTimer: number = 20;
  groupId: any;
  interval: any;
  data: any;
  mediaTrack: IMediaTrack;
  isGroupCall = false;
  constructor(
    private common: CommonService,
    private service: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public subject: SubjectService,
    public socket: SocketService,
    private agoraService: NgxAgoraService,
    private modalService: NgbModal,
    private groupCallStatusService: GroupCallStatusService
  ) {
    this.userinfo = JSON.parse(localStorage.getItem("Bottom_up_user") || "{}");
    this.activatedRoute.queryParams.subscribe((data: any) => {
      let params = this.common.decryptData(data['id']);
      this.appToWeb = (params['isAppToWeb'] ? parseInt(params['isAppToWeb']) : 0) ? true : false;
      this.socket.isCallEnded.next(false);
      this.isGroupCall = params['g_id'] ? true : false;
      this.groupId = params['g_id'] ? params['g_id'] : this.userinfo.ChatRoomName;
      this.recieverIds = params['id'];
      this.getToken();
    });
    this.remoteStream.pipe(debounceTime(1000)).subscribe(() => {
      if (this.isJoined && !this.remoteStream.value.length) {
        this.endCall();
      }
    });
  }
  isPageRefresh(): boolean {
    return (!this.router.navigated);
  }
  canDeactivate(): boolean {
    return false;
  }
  ngOnInit(): void {
    this.groupCallStatusService.setGroupCallStatus(true);
    this.interval = setInterval(() => {
      if (this.timoutTimer > 0) {
        this.timoutTimer = this.timoutTimer - 1;
      } else {
        clearInterval(this.interval);
        if (this.remoteStream.value.length == 0) {
          this.common.error('No friend joined the group');
          this.endCall();
        }
      }
    }, 1000);
  }
  async sendGroupNotification() {
    var fd = new FormData();
    if (this.recieverIds instanceof Array) {
      fd.append("user_id", this.recieverIds.join(","));
    } else {
      fd.append("user_id", this.recieverIds);
    }
    this.service.sendcallNotifi(fd).subscribe((res: any) => {
      if (res["success"] == 1) {
        this.groupId = res["groupId"];
        this.getToken();
      }
    });
  }
  getToken() {
    this.uniqueId = Math.floor(Math.random() * 100);
    var fd = new FormData();
    fd.append("channelID", this.groupId);
    this.service.getRtcToken(fd).subscribe((res: any) => {
      this.common.hideSpinner();
      if (res["success"] == 1) {
        this.rtcToken = res.token;
        this.client = this.agoraService.createClient({ mode: 'rtc', codec: 'h264' });
        this.assignClientHandlers();
        this.joinformsubmit();
      }
    });
  }
  toggleRemote(id: string) {
    if (this.remoteStream.value.length) {
      this.remoteStream.value.forEach((element: any) => {
        if (element.id == id) {
          element.toggle = !element.toggle
        }
      });
    }
  }
  toggleVolume(id: string) {
    if (this.remoteStream.value.length) {
      this.remoteStream.value.forEach((element: any) => {
        if (element.id == id) {
          element.toggleVolume = !element.toggleVolume
        }
      });
    }
  }
  join(onSuccess?: (uid: number | string) => void, onFailure?: (error: Error) => void): void {
    this.client.join(this.rtcToken, (this.groupId), this.uniqueId, onSuccess, onFailure);
  }
  joinformsubmit() {
    try {
      this.common.hideSpinner();
      this.localStream = this.agoraService.createStream({ streamID: this.userinfo.id, audio: true, video: true, screen: false });
      this.assignLocalStreamHandlers();
      this.initLocalStream(() => this.join(uid => this.publish(), error => console.error(error)));
    } catch (error) {
      console.error(error);
    }
  }
  publish(): void {
    this.client.publish(this.localStream, err => console.log('Publish local stream error: ' + err));
  }
  private assignClientHandlers(): void {
    this.client.on(ClientEvent.LocalStreamPublished, evt => {
      console.log('Publish local stream successfully');
    });

    this.agoraService.client.on(ClientEvent.Error, error => {
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        this.client.renewChannelKey(
          '',
          () => console.log('Renewed the channel key successfully.'),
          renewError => console.error('Renew channel key failed: ', renewError)
        );
      }
    });

    this.agoraService.client.on(ClientEvent.RemoteStreamAdded, evt => {
      const stream = evt.stream as Stream;
      this.client.subscribe(stream, { audio: true, video: true }, err => {
        console.log('Subscribe stream failed', err);
      });
    });

    this.agoraService.client.on(ClientEvent.RemoteStreamSubscribed, evt => {
      const stream = evt.stream as Stream;
      const id = this.getRemoteId(stream);
      this.isJoined = true;
      if (!this.remoteStream.value.find(x => x.id === id)) {
        this.remoteStream.value.push({ id, toggle: false, toggleVolume: false, mute: false, stream });
        setTimeout(() => stream.play(id), 1000);
      }
      this.socket.setRunningConferences(this.groupId);
    });

    this.agoraService.client.on(ClientEvent.RemoteStreamRemoved, evt => {
      const stream = evt.stream as Stream;
      // added 13 sept
      if (stream) {
        let streams = this.remoteStream.getValue();
        const index: number = streams.findIndex(x => x.id == this.getRemoteId(stream));
        if (index !== -1) {
          stream.stop();
          streams.splice(index, 1);
        }
        this.remoteStream.next(streams);
      }
    });


    this.agoraService.client.on(ClientEvent.RemoteAudioMuted, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.muteAudio();
      }
    });

    this.agoraService.client.on(ClientEvent.RemoteAudioUnmuted, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.unmuteAudio();
      }
    });
    this.agoraService.client.on(ClientEvent.RemoveVideoMuted, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.muteVideo();
      }
    });
    this.agoraService.client.on(ClientEvent.RemoteVideoUnmuted, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.unmuteVideo();
      }
    });
    this.agoraService.client.on(ClientEvent.PeerLeave, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        let streams = this.remoteStream.getValue();
        const index: number = streams.findIndex(x => x.id == this.getRemoteId(stream));
        if (index !== -1) {
          streams.splice(index, 1);
        }
        this.remoteStream.next(streams);
      }
    });
  }
  private getRemoteId(stream: Stream): string {
    return `agora_remote-${stream.getId()}`;
  }
  private assignLocalStreamHandlers(): void {
    this.localStream.on(StreamEvent.MediaAccessAllowed, () => {
    });
    this.localStream.on(StreamEvent.MediaAccessDenied, () => {
    });
  }
  private initLocalStream(onSuccess?: () => any): void {
    const constraints = {
      bandwidthProfile: {
        video: {
          dominantSpeakerPriority: 'high',
          mode: 'collaboration',
          renderDimensions: {
            high: { height: 720, width: 1280 },
            standard: { height: 90, width: 160 }
          }
        }
      },
      dominantSpeaker: true,
      logLevel: 'debug',
      maxAudioBitrate: 16000,
      video: { height: 720, frameRate: 24, width: 1280 },
      audio: true
    };
    const supports = navigator.mediaDevices.getSupportedConstraints();
    if (!supports.width || !supports.height || !supports.frameRate || !supports.facingMode) {
      this.common.error("Please make sure your microphone and camera are connected!");
    }
    else {
      if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia(constraints)
          .then((stream: any) => {
            this.localStream.init(
              () => {
                // The user has granted access to the camera and mic.
                this.localStream.play(this.localCallId);
                if (onSuccess) {
                  onSuccess();
                }
              },
              err => console.error('getUserMedia failed', err)
            );
          })
          .catch((err: any) => {
            if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
              this.common.error("required track is missing!");
            } else if (err.name === 'DOMException' || err.name === 'DOMException') {
              this.common.error("required track is missing!");
            } else if (err.name === 'NotReadableError' || err.name === 'TrackStartError') {
              this.common.error("webcam or mic are already in use !");
            } else if (err.name === 'OverconstrainedError' || err.name === 'ConstraintNotSatisfiedError') {
              this.common.error("constraints can not be satisfied by this devices!");
            } else {
              this.common.error("Please make sure your microphone and camera are connected!");
            }
          });
      }
    }
  }

  async joniLeaveGroupCall(groupId: any) {
    let data = {
      changestate: 1,
      groupId: groupId,
      iscallCompleted: 0,
    };
    await this.service.joinGroupCall(data);
  }

  async endCall() {
    this.common.showSpinner();
    clearInterval(this.interval);
    if (this.isGroupCall) {
      this.endGroupStream();
      this.endGroupCall();
      this.joniLeaveGroupCall(this.groupId);
    } else {
      this.endOneToOne();
    }
    this.socket.isInviteUser.next(false);
    this.socket.isUserPrivate.next(true);
    this.socket.isPublicCalljoins.next(true);
    this.socket.isCallEnded.next(true);
    this.common.isVideocallInprogress = false;
    this.socket.removeRunningConference();
    this.socket.removeRunningGroupCalls();
    sessionStorage.removeItem("receiverId");
  }
  async endGroupCall() {
    let data = {
      "groupId": this.groupId,
      "iscallCompleted": 1
    }
    await this.service.endgroupCall(data);
  }
  endGroupStream() {
    var fd = new FormData();
    fd.append("calltype", "group");
    fd.append("receiverId", this.recieverIds);
    fd.append("group_id", this.groupId);
    this.service.endCall(fd).subscribe(async (res: any) => {
      this.client.leave();
      this.localStream.stop();
      this.localStream.close();
      setTimeout(() => {
        this.common.hideSpinner();
        this.router.navigate(["/home"]);
      }, 1000);
    }, err =>
      this.common.hideSpinner()
    );
  }
  endOneToOne() {
    debugger
    this.client.leave();
    this.localStream.stop();
    this.localStream.close();
    var fd = new FormData();
    fd.append("calltype", "single");
    fd.append("receiverId", this.recieverIds);
    fd.append("group_id", '');
    // if (this.remoteStream.value.length == 1) {
      this.service.endCall(fd).subscribe((res: any) => {
       
        setTimeout(() => {
          this.common.hideSpinner();
          this.router.navigate(["/home"]);
        }, 1000);
      }, err =>
        this.common.hideSpinner()
      );
    // }
    this.socket.removeRunningConference();
  }
  lockstatus() {
    var fd = new FormData();
    this.isPublic = !this.isPublic;
    fd.append("status", this.isPublic ? "1" : "0");
    this.service.lockStatus(fd).subscribe((res: any) => {
      if (res["success"] == 1) {
        this.socket.isUserPrivate.next(this.isPublic);
        this.common.hideSpinner();
        localStorage.setItem("Bottom_up_user", JSON.stringify(res.data));
        this.common.success(res.message);
      } else {
        this.common.hideSpinner();
        this.common.error(res.message);
      }
    });
  }
  muteUnmuteRemoteUser(remoteUser: any) {
    if (remoteUser.stream.isAudioOn()) {
      remoteUser.stream.muteAudio();
      this.remoteStream.value.forEach(element => {
        if (element.id == remoteUser.id) {
          element.mute = true;
        }
      })
    } else {
      remoteUser.stream.unmuteAudio();
      this.remoteStream.value.forEach(element => {
        if (element.id == remoteUser.id) {
          element.mute = false;
        }
      })
    }
  }
  muteUnmuteAudio() {
    this.isAudioEnabled = !this.isAudioEnabled;
    if (this.localStream.isAudioOn()) {
      this.localStream.muteAudio();
    } else {
      this.localStream.unmuteAudio();
    }
  }
  enableDisableCamera() {

    this.isVideoEnabled = !this.isVideoEnabled;
    if (this.localStream.isVideoOn()) {
      this.localStream.disableVideo();
    } else {
      this.localStream.enableVideo();
    }
  }
  onLocalMic(value: boolean): void {
    !value ? this.mediaTrack?.microphoneUnMute() : this.mediaTrack?.microphoneMute();
  }
  onLocalCamera(value: boolean): void {
    !value ? this.mediaTrack?.cameraOn() : this.mediaTrack?.cameraOff();
  }
  muteUnmuteRemote() {
    this.isRemoteAudioEnabled = !this.isRemoteAudioEnabled;
    if (this.remoteControlStream.isAudioOn()) {
      this.remoteControlStream.muteAudio();
    } else {
      this.remoteControlStream.unmuteAudio();
    }
  }
  setAlcoholLevel() {
    this.modalService.open(SetAlcoholLevelDialog, {
      size: "sm",
      ariaLabelledBy: "modal-basic-title"
    });
  }
  rangeSelect(event: any) {
    this.volumeData = event.target.value;
  }
  showAlcoholLevelPopup(userId: string) {
    if (userId) {
      let data = new FormData();
      data.append('userId', userId.split('-')[1])
      this.service.getUserAlchole(data).subscribe((res: any) => {
        this.common.hideSpinner();
        if (res["success"] === 1) {
          const modalRef = this.modalService.open(ShowApproximatelyDialog, {
            size: 'sm',
            ariaLabelledBy: 'modal-basic-title',
          });
          modalRef.componentInstance.data = res['level'];
        }
      });
    }
  }
  rejectCall() {
    let data = new FormData();
    data.append("type", "call_time_out");
    data.append("groupId", this.groupId);
    let adminId = sessionStorage.getItem("adminId");
    if (adminId == this.userinfo.id) {
      data.append('userId', this.recieverIds)
      data.append('restrict', "0")
    }
    else {
      data.append("userId", this.userinfo.id)
      data.append("restrict", '1')
    }
    this.service.rejectcall(data);
  }
  ngOnDestroy(): void {
    this.endCall();
    this.groupCallStatusService.setGroupCallStatus(false);
  }
}
