
<ng-container [ngSwitch]="isLive">
    <ng-container *ngSwitchCase="false">
            <app-header></app-header>
            <section class="main-screen-sec position-relative">
                <div class="camera-sec">
                    <a class="live-users d-flex justify-content-center p-2 position-absolute">
                        <div class="users-outer listing-user-block" (click)="checkLiveUser()">
                            <div>
                                <h6 class="mb-0 pb-0 pl-3"> {{liveUserCount}} Friend is live Now</h6>
                            </div>
                        </div>
                        <div id="videoContainer">
                            <video autoplay="true" id="videoElement"></video>
                        </div>
                    </a>
                    <div class="camera-controls position-absolute text-center">
                        <div class="live-btn m-auto mb-3">
                            <a class="btn btn-success" (click)="gotolive()" type="button">Go <br>Live</a>
                        </div>
                        <div class="camera-options mt-2 d-flex primary-blue-bg align-items-center">
                            <a (click)="lockstatus()">
                                <img *ngIf="!isPrivate" src="./assets/images/unlocked.png" alt="icon" />
                                <img *ngIf="isPrivate" src="./assets/images/lock.png" alt="icon" />
                            </a>
                            <a (click)="muteUnmuteAudio()">
                                    <img *ngIf="!isAudioEnabled" src="./assets/images/mic.png" alt="icon" />
                                    <img *ngIf="isAudioEnabled" src="./assets/images/off-mike.png" alt="icon" />
                                  </a>
                            <a (click)="setAlcoholLevel()">
                                <img src="./assets/images/beer-icon.png" width="40" alt="icon" />
                              </a>
                        </div>
                    </div>
                </div>
            </section>  
    </ng-container>
    <app-golive *ngSwitchCase="true" (endlive)="endLiveCall()"></app-golive>
</ng-container>
