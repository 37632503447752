import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-add-friend',
  templateUrl: './add-friend.component.html',
  styleUrls: ['./add-friend.component.scss']
})
export class AddFriendComponent implements OnInit {
 
  constructor(){

  }

  ngOnInit(){
    
  }
}
