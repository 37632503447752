import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SocketService } from 'src/app/services/socketService/socket.service';
import { SubjectService } from 'src/app/services/subject.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChatComponent } from '../../chat/chat.component';
enum ModalConfigLarge {
  size = "xl",
  ariaLabelledBy = "modal-basic-title",
  // backdrop = 'static'
}
declare var $: any;
@Component({
  selector: 'app-find-friend',
  templateUrl: './find-friend.component.html',
  styleUrls: ['./find-friend.component.scss']
})
export class FindFriendComponent implements OnInit, AfterViewInit {

  OnlineFriendList: any = [];
  imageUrl: string;
  search: any;
  connected = false;
  groupmsg: boolean = false;
  searchall: any
  getallUsers: any;
  friendList: any = [];
  mutualFriend: any;
  GroupList: any;
  mutualData: any = [];
  mutualCount: any;
  sessionId: string;
  participantId: string;
  wsUrl: string;
  showOpenvidu = false;
  sessionClosed = false;
  uid: number;
  remoteCalls: string[] = [];
  published = false;
  grouplistmember: any;
  groupListData: any = [];
  groupname: any;
  channelId: any;
  recentActivites: any = [];
  listData: any = []
  chatList: any;
  chatData: any;
  currentGroupData: any
  groupList: any = [];
  islistShow: boolean = false;
  isShow: boolean = true;
  chatHistory: any;
  room: any
  status: any;
  otherUserId: any;
  pendingGroups: any = [];
  groupMembers: any = [];
  groups: any = []
  list: any = []
  groupId: any;
  members: any = [];
  showjoin: boolean = false
  isEdit: boolean = false;
  IssendNotification: boolean = false;
  userInfo: any;
  constructor(
    private service: ApiService,
    private router: Router,
    public common: CommonService,
    public socket: SocketService,
    public activeModal: NgbActiveModal,
    public subject: SubjectService,
    private modalService: NgbModal,
  ) {

    console.log(JSON.parse(localStorage.getItem("socketdata") || '{}'));
    this.subject.userOnline.subscribe(result => {
      console.log(result);
      this.getData();
    })

    if (localStorage.getItem('Bottom_up_user')) {
      this.userInfo = JSON.parse(localStorage.getItem('Bottom_up_user') || '{}')
      console.log(this.userInfo.name, "details")
    }
    let data = {
      'token': this.userInfo.token,
      'userID': this.userInfo.id,
    }
    this.socket.openWebSocket(data);
    setTimeout(() => {
      this.getNewMessage();
    }, 2000)
    this.getRecentActivites();
    this.getData();
  }
  ngAfterViewInit(): void {
    this.groupname = this.common.allGroupList[0].groupDetails.group_name;
  }
  checkIfAdmin(admin: any) {
    if (this.userInfo.id == admin.group_admin_id) {
      return true;
    }
    return false;
  }
  ngOnInit(): void {
    this.imageUrl = this.service.imageUrl;
    this.getFriendList();
    this.getMutualFriend();
    this.getGroup();
    this.getlistgroupInvi()
  }

  getData() {
    if (localStorage.getItem('Bottom_up_user')) {
      let msgdata = JSON.parse(localStorage.getItem("socketdata") || '{}');
      console.log(msgdata, "search group")
      if (msgdata.data != [] && msgdata.data != null) {
        this.groupMembers = msgdata.data;
      }
      if (msgdata.groups != [] || msgdata.groups != null) {
        this.groups = msgdata.groups;
        if ((this.groups && this.groups.length > 0) || this.groups != undefined) {
          this.groups.forEach((element: any) => {
            console.log(element, "data")
            if (this.userInfo.id !== element.admin.id) {
              element.members.forEach((grp: any) => {
                if (grp.id !== this.userInfo.id) {
                  this.pendingGroups.push(element);
                } else {
                  this.showjoin = true
                  this.isEdit = false
                }
              });
            }
          });
        }
      }
      if (msgdata.type === "join_now") {
        if (msgdata.data.userCanJoinCallStatus === '0') {
          Swal.fire('Oops...', `You can't make this call now because user is already on call with someone.`, 'error')
          return;
        }
        else {
          this.sendNotification(msgdata.data.group_id)
        }
      }
    }
  }

  getNewMessage() {
    let data = {
      'user_id': this.userInfo.id,
      'serviceType': 'search_invitation'
    }
    this.socket.sendMessage(data)
  }

  getFriendList() {
    this.OnlineFriendList = []
    this.service.getLiveUser('').subscribe((res: any) => {
      
      if (res['success'] === 1) {
        this.common.hideSpinner();
        this.OnlineFriendList = res['LiveFriends'];
        //     res['data'].forEach((element: any) => {
        //       if (element.status === '1') {
        //         this.OnlineFriendList.push(element);
        //       }
        //     });
      }
    })
  }

  getlistgroupInvi() {
    this.service.getgroupListInvi('').subscribe((res: any) => {
      if (res['success'] === 1) {
        // this.grouplistmember = res['data']
        //   (this.grouplistmember || []).map((e: any) => {
        //     if (e.data) {
        //       this.groupListData.push(e.data)
        //       console.log(this.groupListData, "groupData")
        //     }
        //   })
        // console.log(this.grouplistmember,"============================")
      }
    })
  }

  joincall(data: any) {
    console.log(data)
    let data1 = {
      'serviceType': 'join_now',
      'user_id': this.userInfo.id,
      "joinUserID": data.id
    }
    console.log(data1);
    this.socket.sendMessage(data1);
    // this.router.navigate(['/video_call',{recieverId:data.id,channelId:data.ChatRoomName}]);
  }

  joinGroupcall(data: any) {
    let data1 = {
      'serviceType': 'join_now',
      'user_id': this.userInfo.id,
      "joinUserID": data.id
    }
    this.socket.sendMessage(data1);

  }

  sendNotification(data: any) {
    this.groupId = data;
    var fd = new FormData
    fd.append('channelID', this.groupId)
    this.service.getRtcToken(fd).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.common.hideSpinner();
        localStorage.setItem("rtcToken", res.token);
        this.router.navigate(['/group_call', this.groupId, res.token]);
      }
      else {
        this.common.hideSpinner();
      }
    })
  }

  getMutualFriend() {
    this.service.getMutualFriendList('').subscribe((res: any) => {
      if (res['success'] === 1) {
        this.mutualData = []
        this.mutualFriend = res['data'];
        this.mutualFriend.forEach((element: any) => {
          this.mutualCount = element.friend;
          this.mutualData.push(element.users)
          this.common.hideSpinner()
        });
      }
      else {
        this.common.hideSpinner()
      }
    })
  }

  addFriend(data: any) {
    var fd = new FormData;
    fd.append('friendId', data.id);
    fd.append('request', '1')
    this.service.addRemoveFriend(fd).subscribe((res: any) => {
      if (res["success"]) {
        this.common.success(res.message);
        this.common.hideSpinner();
        this.searchall = '';
        this.getMutualFriend();
      }
      else {
        this.common.hideSpinner();
      }
    })
  }

  getGroup() {
    this.service.getGroupList('').subscribe((res: any) => {
      if (res['success'] === 1) {
        this.common.hideSpinner();
        this.GroupList = res['data'];
      }
    })
  }

  Addtofriend(list: any) {
    var fd = new FormData;
    fd.append('friendId', list.id);
    fd.append('request', '1')
    this.service.addRemoveFriend(fd).subscribe((res: any) => {
      if (res["success"]) {
        this.common.success(res.message);
        this.searchall = '';
        this.common.hideSpinner();
        $('#find-friend').modal('hide');
      } else {
        this.common.error(res.message);
        this.common.hideSpinner()
      }
    })
  }

  searchFriendList(text: any) {
    var fd = new FormData;
    var OnlineFriendserachList = [];
    this.OnlineFriendList = []
    if (this.search == '' || this.search === undefined) {
      this.getFriendList()
    }
    fd.append('term', this.search.trim().toLowerCase())
    this.service.SearchFriendfromList(fd).subscribe((res: any) => {
      if (res['success'] === 1) {
        this.common.hideSpinner()
        if (res.data.length == 0) {
          this.common.error('No data Found');
        }
        res['data'].forEach((element: any) => {
          this.common.hideSpinner()
          if (element.status === '1') {
            this.OnlineFriendList.push(element);
          }
        });
      } else {
        this.common.hideSpinner();
      }
    })
  }

  searchuser(data: any) {
    var fd = new FormData;
    fd.append('term', this.searchall.trim().toLowerCase())
    this.service.completeuserSearch(fd).subscribe((res: any) => {
      if (res['success'] === 1) {
        this.common.hideSpinner();
        this.getallUsers = res['data'];
        if (res.data.length == 0) {
          this.common.success('No user Found');
        }
        $('#find-friend').modal('show');
        this.common.hideSpinner();
      } else {
        this.common.hideSpinner();
      }
    })
  }


  gotouserChat(data: any) {
    var fd = new FormData;
    fd.append('reciever_id', data);
    this.service.getchatofParticularUser(fd).subscribe((res: any) => {
      if (res['success'] === 1) {
        this.router.navigate(['/chat', data]);
        this.islistShow = true;
        this.groupmsg = false;
        this.chatData = res;
        this.chatHistory = res['data']
        this.chatHistory.reverse();
        $('#add-new-chat').modal('hide')
        // this.chatHistory.forEach((element: any) => {
        //   console.log(element.sender_id, element.receiver_id, element);
        // if (element.sender_id < element.receiver_id) {
        //   this.room = element.sender_id + "-" + element.receiver_id
        // }
        //  else {
        //   this.room = element.receiver_id + "-" + element.sender_id
        //   console.log(element.receiver_id - element.sender_id);
        // }
        // });
        this.common.hideSpinner()
      } else {
        this.common.hideSpinner();
      }
    })
  }

  join(data: any, id: any) {
    console.log(data);
    let data1 = {
      "user_id": id,
      "type": 'single'
    }
    this.channelId = JSON.parse(localStorage.getItem('Bottom_up_user') || '{}')
    this.service.sendnotificationReq(data1).subscribe((res: any) => {
      if (res["success"]) {
        this.common.success(res.message);
        $('#find-friend').modal('hide')
        var fd = new FormData();
        fd.append('channelID', this.channelId.ChatRoomName);
        this.service.getRtcToken(fd).subscribe((res: any) => {
          if (res['success'] == 1) {
            localStorage.setItem("rtcToken", res.token);
            this.common.hideSpinner();
            this.router.navigate(['/video_call', this.channelId.ChatRoomName, res.token, id]);
          } else {
            this.common.hideSpinner();
            this.common.error(res.message)
          }
        })
      } else {
        this.common.hideSpinner();
        this.common.error(res.message)
      }
    })
  }

  leave(): void {
    // if (this.connected) {
    //   this.client.leave(() => {
    //     this.connected = false;
    //     this.published = false;
    //     this.remoteCalls = [];
    //   }, err => {
    //     console.log('Leave channel failed');
    //   }
    //   );
    // } else {
    //   // this.agoraService.AgoraRTC.Logger.warning('Local client is not connected to channel.');
    // }
  }
  remove(data: any) {
    var fd = new FormData()
    fd.append('mutualId', data.id)
    this.service.removeMutualFriend(fd).subscribe((res: any) => {
      if (res['success'] === 1) {
        this.common.hideSpinner();
        this.getMutualFriend()
      }
      else { this.common.hideSpinner() }
    })
  }

  editgroup(data: any) {
    
    this.groupname = data
    this.isEdit = true;
  }

  requestCancel(data: any) {
    
    var fd = new FormData
    fd.append('friendId', data.id)
    this.service.CancelRequest(fd).subscribe((res: any) => {
      if (res['success'] === 1) {
        this.common.success(res.message)
        this.searchall = ''
        this.common.hideSpinner();
        this.getMutualFriend();
        $('#find-friend').modal('hide')
      }
      else { this.common.hideSpinner() }
    })
  }

  updateGroup(data: any, id: any) {
    var fd = new FormData
    fd.append('name', this.groupname)
    fd.append('group_img', '')
    fd.append('privacy', data)
    fd.append('group', id)
    this.service.updateGroup(fd).subscribe((res: any) => {
      if (res['success'] === 1) {
        this.common.success(res.message)
        this.common.hideSpinner();
        this.groupname = ''
        this.getNewMessage();
        this.isEdit = false;
      }
      else { this.common.hideSpinner() }
    })
  }
  onGroupClick(admin: any) {
    if ((this.checkIfAdmin(admin) && !admin.group_name)) {
      return false;
    } else {
      this.modalService.dismissAll();
      let modalRef = this.modalService.open(
        ChatComponent, ModalConfigLarge
      );
      let data = {
        tab: 2,
        id: admin.group_id
      }
      modalRef.componentInstance['anyDataForm'] = data
    }
  }
  getRecentActivites() {
    this.service.recentActivites('').subscribe((res: any) => {
      if (res['success'] === 1) {
        this.recentActivites = res['data']
        this.common.hideSpinner();
        this.getMutualFriend()
      }
      else { this.common.hideSpinner() }
    })
  }

  cancel() {
    this.searchall = '';
    this.modalService.dismissAll();
  }
}


