<!-- level set modal -->
<div id="alchoholic-level">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title light-grey-cl">Alcohol Level</h5>
          <button type="button" class="close" (click)="onNoClick()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
          <div class="logo-beer d-inline-block mb-3 mt-0">
            <img class="w-100" src="assets/images/big-beer-mug.svg" alt="icons">
          </div>
          <p>Your BAC is Approximately:</p>
          <h4>{{data || '0'+'%'}}</h4>
          <!-- <p>It will take 25 hours to get to 0% BAC.</p> -->
        </div>
      </div>
    </div>
  </div>