<section class="login-block">
  <!-- login start -->
  <!-- left side start -->
  <div class="left-side-bg">
    <img src="assets/images/bottoms-up-text.svg">
  </div>
  <!-- right side start -->
  <div class="right-side-block my-3">
    <div class="welcome-block">
      <h1>Reset your Password</h1>
      <p>You recently requested to reset password for your account. Click the button below to reset it.</p>
      <!-- form start -->
      <form class="register-block" #forgotPassword="ngForm" (ngSubmit)="Forgot(forgotPassword)">
        <div class="form-group floating mb-0">
          <input type="password" class="form-control floating" id="usr" name="password" ngModel #password="ngModel"
            required />
          <label for="usr">New Password</label>
        </div>
        <div *ngIf="forgotPassword.submitted && password.invalid ">
          <div class="alert alert-danger" *ngIf="password.errors?.required">
            Password is required
          </div>
        </div>
        <div class="form-group floating mb-0">
          <input type="password" class="form-control floating" id="usr" name="confirmpassword" ngModel
            pattern="{{ password.value }}" #confirmpassword="ngModel" required>
          <label for="usr">Confirm New Password</label>
        </div>
        <div *ngIf=" confirmpassword.invalid && (forgotPassword.submitted || confirmpassword.touched)"
          class="alert alert-danger">
          <div *ngIf="confirmpassword.errors?.required"> Confirm password is required. </div>
          <div *ngIf="confirmpassword.errors?.pattern"> Password & Confirm Password does not match.</div>
        </div>
        <div class="col-lg-12 text-center"><button class="btn account-but my-3" type="submit"
            [disabled]="!forgotPassword.valid" role="button">Submit</button>
        </div>
      </form>
      <!-- form close-->
      <p>Don't have an account? <span><a class="primary-blue-cl" (click)="gotoSign()">Sign In</a></span></p>
    </div>
  </div><!-- right side close -->
</section>