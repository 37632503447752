<section class="login-block">
  <!-- login start -->
  <!-- left side start -->
  <div class="left-side-bg">
    <img src="assets/images/bottoms-up-text.svg">
  </div>
  <!-- right side start -->
  <div class="right-side-block new my-3">
    <div class="welcome-block new">
      <h1>Create Profile</h1>
      <p>Fill the following detail to join us.</p>
      <div class="profile-img mb-5">
        <div class="p-img"><img *ngIf="!profileImage" src="assets/images/avatar.svg" alt="profile_image" />
          <div *ngIf="profileImage"><img [src]="profileImage"></div>
        </div>
        <div class="camera-icon">
          <img src="assets/images/camera-icon.svg" alt="camera_icon" />
          <form>
            <div class="form-group">
              <input type="file" class="form-control-file" (change)="profilePic($event)" id="exampleFormControlFile1">\
            </div>
          </form>
        </div>
      </div>

      <!-- form start -->
      <form class="register-block" #profile="ngForm" (ngSubmit)="profile.valid && Profile(profile)">
        <div class="form-group floating mb-0">
          <input type="text" class="form-control floating" id="usr" [(ngModel)]="userProfile.name" name="name"
            #name="ngModel" ngModel required>
          <label for="usr">Name</label>
        </div>
        <div *ngIf="profile.submitted && name.invalid ">
          <div class="alert alert-danger" *ngIf="name.errors?.required">
            Name is required
          </div>
        </div>

        <div class="form-group floating mb-0">
          <input type="text" placeholder="DOB" class="form-control floating" name="dob" required #dob="ngModel"
            autocomplete="off" ngModel bsDatepicker (bsValueChange)="getTimeSlots($event)"
            [bsConfig]="{ isAnimated: true}">
          <label for="usr"></label>
        </div>
        <div class="form-group floating mb-0">
          <input type="text" class="white-bg form-control floating.white-bg" [disabled]="emailStatus"
            [(ngModel)]="userProfile.email" name="email" ngModel required #email="ngModel">
          <label for="usr" class="email-lebel">Email</label>
        </div>
        <div *ngIf="profile.submitted && email.invalid ">
          <div class="alert alert-danger" *ngIf="email.errors?.required">
            Email/Phone no. is required
          </div>
        </div>
        <div class="form-group floating mb-0">
          <input type="text" class="form-control floating" name="address" ngModel required #address="ngModel">
          <label for="usr">Address</label>
        </div>
        <div *ngIf="profile.submitted && address.invalid ">
          <div class="alert alert-danger" *ngIf="address.errors?.required">
            Address is required
          </div>
        </div>
        <div class="form-group floating mb-0">
          <input type="text" class="form-control floating" name="state" ngModel required #state="ngModel">
          <label for="usr">State</label>
        </div>
        <div *ngIf="profile.submitted && state.invalid ">
          <div class="alert alert-danger" *ngIf="state.errors?.required">
            State is required
          </div>
        </div>
        <div class="form-group floating mb-0">
          <input type="text" class="form-control floating" name="City" ngModel required #city="ngModel">
          <label for="usr">City</label>
        </div>
        <div *ngIf="profile.submitted && city.invalid ">
          <div class="alert alert-danger" *ngIf="city.errors?.required">
            City is required
          </div>
        </div>
        <div class="form-group floating mb-0">
        <input type="tel" ng2TelInput class="form-control" (hasError)="hasError($event)"
        [ng2TelInputOptions]="initialConfig" (countryChange)="onCountryChange($event)"
        (ngModelChange)="validateNumber()" name="phoneNumber" ngModel required #phoneNumber="ngModel" />
        </div>
        <div *ngIf="profile?.submitted && phone?.invalid ">
          <div class="alert alert-danger" *ngIf="phone.errors?.required">
            Phone no. is required
          </div>
        </div>
        <div class="form-group floating mb-0 reg-bio">
          <textarea class="form-control" id="" rows="3" placeholder="Bio" name="bio" ngModel required
            #bio="ngModel"></textarea>
          <label for="usr"></label>
        </div>
        <div *ngIf="profile.submitted && bio.invalid ">
          <div class="alert alert-danger" *ngIf="bio.errors?.required">
            Bio is required
          </div>
        </div>
        <button type="submit" class="btn account-but my-3" role="button">Submit & Continue</button>
      </form>
      <!-- form close-->
    </div>
  </div><!-- right side close -->
</section>