import { AbstractControl } from '@angular/forms';

export class ConfirmAccountValidator {
    static MatchAccount(control: any) {
        let account = control.get('account_number').value;
        let confirmAccount = control.get('confirm_account_number').value;
        if(account != confirmAccount) {
            control.get('confirm_account_number').setErrors({ confirmAccount: true });
        } else {
            return null;
        }
    }
}