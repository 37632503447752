<app-header></app-header>
<section class="live-users-sec">
    <div class="container">
        <div class="liveusers-hd d-flex justify-content-between secondary-blue-bg text-white p-3">
            <h5 class="mb-0">Live Videos</h5>
            <p *ngIf="LiveUserList" class="mb-0 text-white">{{LiveUserList.length}} People</p>
        </div>
        <div class="liveusers-box">
            <div class="list-sec mb-4">
                <div *ngFor="let live of LiveUserList" class="row">
                    <div class="col-md-12">
                        <div class="userdetail-box">
                            <div class="liveuser-details d-flex justify-content-between mb-3">
                                <div (click)="gotolive(live)" class="name-sec d-flex">
                                    <div class="position-relative">
                                        <img *ngIf="live?.profile_image !=''" src="{{imageUrl}}{{live?.profile_image}}" alt="image" />
                                        <img *ngIf="live?.profile_image ==''" src="assets/images/dummy_user.png" alt="image" />

                                        <div class="status-green align-self-center position-absolute"></div>
                                    </div>
                                    <h5 class="mb-0 align-self-center ml-3 dark-grey-cl">{{live.name}}</h5>
                                </div>
                                <div class="more-option">
                                    <div class="dropdown">
                                        <a class="btn position-relative" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img class="position-absolute" src="assets/images/more-dots.svg" alt="icons" />
                                        </a>
                                        <div class="more-drop dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <a class="dropdown-item" (click)="notIntereste(live)">Not Interested</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="mb-0">Live Since {{checkSinceLive(live.DateTimeString)}}</p>
                            <small class="mb-0">{{live.City}} | {{live.State}}</small>
                            <!-- <p class="mb-0 mt-2 f-18 connecteduser">234 Connected</p> -->
                        </div>
                    </div>
                </div>
                <div *ngIf="LiveUserList && LiveUserList.length==0" class="nodatafound">
                    No friends are live.
                </div>
            </div>
            <div class="list-sec mb-4"></div>
        </div>
    </div>
</section>