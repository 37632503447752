import { Component } from '@angular/core';
import { MessagingService } from './services/messaging.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { CommonService } from './services/common/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SubjectService } from './services/subject.service';
import { ApiService } from 'src/app/services/api-service/api.service';
import {Location} from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent { 
  title = 'Bottomup';
  message:any;
  location:Location
  constructor(
    private messagingService:MessagingService,
    private db: AngularFireDatabase,
    public common:CommonService,
    public router:Router,
    public subject:SubjectService,
    public apiservice:ApiService,
    public activatedRoute:ActivatedRoute,
    location:Location
    ){
      this.location = location

      this.subject.onlineStatus.subscribe((response)=>{
        this.initiateProcess();
      })
  }
  ngOnInit(){
    if(localStorage.getItem('Bottom_up_user')){
      if(window.location.href=='https://bottom.harishparas.com/'){
       return this.router.navigate(['/home']);

      }
      else{
        return true
      }
    
    }
    this.initiateProcess();
    this.profileUrl()

      
  }

 initiateProcess(){
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.db.list('Users').valueChanges().subscribe((response:any)=>{
        let isActive = false;
        if(localStorage.getItem('Bottom_up_user')){
          let userinfo = JSON.parse(localStorage.getItem('Bottom_up_user') || '');
          response.forEach((element:any) => {
            if(element.DeviceId == userinfo.deviceid){
              isActive = true;
            }
          });
          if(isActive == false){
            this.apiservice.logout().subscribe((result)=>{
              this.common.error('Your account is logged-in on another device. Please check and login again.');
              sessionStorage.clear();
              localStorage.clear();
              this.router.navigate(['/']);
            })
  
            this.common.hideSpinner();
          }
        }

    })
  }

  profileUrl(){
    console.log('hello');
    console.log(this.location.path())
    sessionStorage.setItem('redirectuserid',this.location.path())
      
    
  }
}
